import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import store from '../../../common_lib_front/utilities/store';
import style from './ownerCode.module.css';

const RESET_GATE_CODE = gql`
  mutation ResetGateCode {
    editOwnerGateCode {
      success
      error
      data {
        ownerGateCode
      }
    }
  }
`;

export default function OwnerCode(): ReactElement {
  const [code, setCode] = useState<string>(store.getState().user?.ownerGateCode || '');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const [doResetCode] = useMutation(RESET_GATE_CODE, {
    onError: () => {
      setAlert('Something went wrong. Please try again later');
      setAlertColor('red');
    },
    onCompleted: d => {
      setCode(d.editOwnerGateCode.data.ownerGateCode);
      store.dispatch({
        type: 'user/updateGateCode',
        payload: d.editOwnerGateCode.data,
      });
    },
  });

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.box__subHeader} mainColor `}>
          <div className={`${style.box__circle} white `} />
          <h1 className={`${style.box__title} textColorWhite `}>Owner Gate Code</h1>
        </div>
        <div className={`${style.box__flexBox} white `}>
          <div className={style.box__innerBox}>
            <GenericAlert title={alert} hidden={!alert} color={alertColor} />
            <p className={`${style.box__paragraph} textColorSuperDark `}>
              Your Owner Gate Code is a unique code that you can give to anyone that you
              authorize to enter the community. This Code, when entered in the Auto Gate
              Attendant tool along with a cell phone number, will give a user a one-time
              acccess pass. Your guests that are either community vendors or staying at
              your property as short-term renters must still register in either the guest
              or vendor portals for extended access to the community. If you reset this
              code, your previous code will no longer be valid.
            </p>
            <div className={style.box__codeBox}>
              <h3 className={`${style.box__codeTitle} textColorSuperDark `}>
                Owner Gate Code:
              </h3>
              <div className={style.box__code}>
                {Array.from(code).map((c: string, idx) => (
                  <div className={`${style.box__singleCode} textColorDark`} key={idx}>
                    {c}
                  </div>
                ))}
              </div>
            </div>
            <div className={style.btnbox}>
              <div className={style.btn}>
                <GenericButton
                  title="Reset Code"
                  type="button"
                  color="blue"
                  size="large"
                  clickHandler={() => {
                    if (
                      window.confirm(
                        'By resetting your owner code, your previous code will no longer be functional. Are you sure you would like to continue?',
                      )
                    ) {
                      doResetCode();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
