import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client';

export const GET_EMPLOYEE = gql`
  query GetFamilyMembers {
    getFamilyMembers {
      error
      success
      data {
        firstName
        familyMemberId
        lastName
        relationship
        phoneNumber
        email
        userId
      }
    }
  }
`;

export const useFamilyAndFriends = (options?: QueryHookOptions): QueryResult =>
  useQuery(GET_EMPLOYEE, options);
