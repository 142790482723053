import { ReactElement } from 'react';
import InviteGuestFormContainer from '../../../components/inviteGuestForm/inviteGuestFormContainer';
import { useInviteGuestReducer } from '../../../components/inviteGuestForm/useInviteGuestState';
import { inviteMultipleVendors } from './inviteVendorRequest';
import style from './vendorPass.module.css';

export default function VendorPass(): ReactElement {
  const [data, editData] = useInviteGuestReducer();

  return (
    <div className={style.wrapper}>
      <InviteGuestFormContainer
        data={data}
        editData={editData}
        fastPassSelected={false}
        hideNav
        hideUpload
        targetPortal="vendor"
        skipBackendValidation
        successLink="/resident/my-passes"
        formConfig={{
          firstName: { hidden: true },
          lastName: { hidden: true },
          companyName: { required: false },
          email: { required: false },
          phoneNumber: { required: false },
          address: {
            required: true,
            label: 'Destination (Provided)',
          },
          willPayForPass: { hidden: true },
          passType: { hidden: true },
        }}
        validators={[
          // {
          //   errorIf: ri => ri.phoneNumber.length < 11,
          //   message:
          //     'Please enter a valid phone number. A phone number is required to issue an invite.',
          // },
          {
            validIf: ri =>
              !!(
                ri.companyId ||
                (ri.companyName && ri.email && ri.phoneNumber.length >= 11)
              ),
            message:
              'Please either select a company from the drop down, or completely fill out the information for a new company.',
          },
        ]}
        formTextConfig={{
          recipientTag: 'Vendor',
        }}
        submitHandler={() => inviteMultipleVendors(data)}
      />
    </div>
  );
}
