import { LazyQueryHookOptions, gql, useLazyQuery } from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

export const SEARCH_COMPANIES = gql`
  query SearchCompaniesByName($searchKey: String!) {
    searchCompaniesByName(searchKey: $searchKey) {
      success
      error
      data {
        companyName
        companyInfoId
        city
        state
        userId
        address
        businessPhone
        email
        zipCode
      }
    }
  }
`;

export type searchCompaniesByNameReturnType = {
  companyName: string;
  companyInfoId: string;
  city: string;
  state: string;
  userId: string;
  address: string;
  businessPhone: string;
  email: string | null;
  zipCode: string;
};
export type SEARCH_COMPANIES_VARS = {
  searchKey: string;
};
export type SEARCH_COMPANIES_RES = {
  searchCompaniesByName: backendResponse<searchCompaniesByNameReturnType[]>;
};

type optionsType = LazyQueryHookOptions<SEARCH_COMPANIES_RES, SEARCH_COMPANIES_VARS>;
export const useLazySearchCompanies = (options?: optionsType) =>
  useLazyQuery<SEARCH_COMPANIES_RES, SEARCH_COMPANIES_VARS>(SEARCH_COMPANIES, options);
