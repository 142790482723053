import Tippy from '@tippyjs/react';
import React, { useRef, useState } from 'react';
import { ReactComponent as ActionImg } from '../../../assets/actionIcon.svg';
import { getDocUrl, useDocuments } from '../../../hooks/useDocuments';
import style from './popUpCell.module.css';

interface PopupCellRendererProps {
  data: {
    documentId: string;
    documentUrl: string;
    userDocumentName: string;
  };
}

/* eslint-disable react/prop-types */
const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({ data }) => {
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  console.log('GRID data: ', data, data.documentId);

  const { data: userDocument } = useDocuments({
    variables: {
      documentId: data.documentId,
    },
    skip: true,
  });

  const openLinkHandler = (option: string) => {
    hide();
    if (option === 'edit') {
      const url = userDocument?.getUserDocument?.data?.documentUrl;
      if (url) {
        window.open(userDocument?.getUserDocument?.data?.documentUrl, '_blank');
        return;
      }
      getDocUrl(data.documentId)
        .then(res => {
          const url2 = res.data.getUserDocument.data?.documentUrl;
          if (res.error || res.data.getUserDocument.error) {
            console.error(res);
          }
          window.open(url2, '_blank');
        })
        .catch(err => {
          console.error(err);
        });
    }
    if (option === 'delete') {
      window.location.href = `../edit-profile/delete-my-document/${data.documentId}`;
    }
  };

  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <div
        onClick={() => openLinkHandler('edit')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('edit');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>View/Download</span>
      </div>
      <div
        onClick={() => openLinkHandler('delete')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('delete');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Delete</span>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
