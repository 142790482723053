import { gql } from '@apollo/client';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';
export const EDIT_INVITED_GUEST = gql`
  mutation ($newGuestInfo: InvitedGuestInfoInput!, $registrationId: String!) {
    editInvitedGuest(newGuestInfo: $newGuestInfo, registrationId: $registrationId) {
      success
      error
    }
  }
`;
export type EDIT_INVITED_GUEST_VARS = {
  newGuestInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  registrationId: string;
};
export type EDIT_INVITED_GUEST_RES = {
  editInvitedGuest: backendResponse<undefined>;
};

export const EDIT_COMMUNITY_RENTAL = gql`
  mutation EditCommunityRental(
    $departureDate: DateTime!
    $arrivalDate: DateTime!
    $address: String
    $communityRentalId: String!
  ) {
    editCommunityRental(
      departureDate: $departureDate
      arrivalDate: $arrivalDate
      address: $address
      communityRentalId: $communityRentalId
    ) {
      success
      error
    }
  }
`;
export type EDIT_COMMUNITY_RENTAL_VARS = {
  departureDate: string;
  arrivalDate: string;
  address: string;
  communityRentalId: string;
};
export type EDIT_COMMUNITY_RENTAL_RES = {
  editCommunityRental: backendResponse<undefined>;
};
export const EDIT_PASS = gql`
  mutation EditPass(
    $departureDate: DateTime!
    $arrivalDate: DateTime!
    $passId: String!
  ) {
    editPass(startDate: $arrivalDate, endDate: $departureDate, passId: $passId) {
      success
      error
    }
  }
`;
export type EDIT_PASS_VARS = {
  departureDate: string;
  arrivalDate: string;
  passId: string;
};
export type EDIT_PASS_RES = {
  editPass: backendResponse<undefined>;
};
