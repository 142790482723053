import React, { useCallback, useEffect, useState } from 'react';
import useContainerBlur from '../../utilities/useContainerBlur';
import { SearchSelectorObjectProps } from './searchSelectorObject';

export default function useSearchSelectorObject<T>(props: SearchSelectorObjectProps<T>): {
  open: boolean;
  setOpen: (val: boolean) => void;
  optionsList: Array<T>;
  parentId: string;
  showWarning: boolean;
  checkWarning: () => void;
  optionSelectHandler: (val: T) => void;
  innerChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const {
    htmlFor,
    value,
    changeHandler,
    fetch,
    selectHandler,
    addressValues,
    required,
    repr,
  } = props;

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState<Array<T>>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  // setting validity to prevent form submission
  useEffect(() => {
    const elem = document.getElementById(htmlFor);
    if (elem instanceof HTMLInputElement) {
      const validity =
        !required || isValidated ? '' : 'Select exact match from dropdown options.';
      elem.setCustomValidity(validity);
      // elem.reportValidity();
    }
  }, [isValidated, required, htmlFor]);
  const {
    open,
    setOpen,
    containerId: parentId,
  } = useContainerBlur(`container-parent-${htmlFor}`);

  const optionSelectHandler = (val: T) => {
    changeHandler(repr(val));
    setOpen(false);
    setIsValidated(true);
    setShowWarning(false);
    if (selectHandler) {
      console.log('selected with handler');
      selectHandler(val);
    }
  };

  const innerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(e.target.value);
    setIsValidated(false);
  };

  const checkWarning = () => {
    let localIsVal = isValidated;
    if (optionsList.some(elem => repr(elem) === value)) {
      setIsValidated(true);
      localIsVal = true;
    }
    if (localIsVal) {
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const doFetch = useCallback(
    (val: string) => {
      const res = fetch(val);
      if (res === undefined) return;
      res
        .then(d => {
          if (d instanceof Array) {
            // only strings
            setOptionsList(d);
          } else {
            setOptionsList([]);
          }
        })
        .catch(() => {
          setOptionsList([]);
        });
    },
    [fetch, setOptionsList],
  );

  useEffect(() => {
    doFetch(value);
  }, [value, addressValues, doFetch]);

  return {
    open,
    setOpen,
    optionsList,
    parentId,
    showWarning,
    checkWarning,
    optionSelectHandler,
    innerChangeHandler,
  };
}
