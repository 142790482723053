import { gql, useQuery } from '@apollo/client';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/backArrow.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import style from './residentPassNavHeader.module.css';
import RegistrationNavTile from './residentPassNavTile';

const GET_REGISTRATION = gql`
  query GetRegistration($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        complete
        stepNumber
      }
    }
  }
`;

type Params = {
  registrationId: string;
};

type ResidentPassNavHeaderProps = {
  title: string;
  subtitle: string;
  activeNum?: number;
};

export const ResidentPassHeader = (props: ResidentPassNavHeaderProps): ReactElement => {
  const { registrationId } = useParams<Params>();
  const { title, subtitle, activeNum } = props;
  const [completeNum, setCompleteNum] = useState<number>(0);
  const [completeAll, setCompleteAll] = useState<boolean>(false);
  const [backUrl, setBackUrl] = useState<string>('');
  const location = useLocation();
  const { name: communityName } = useContext(CommunityContext);

  useQuery(GET_REGISTRATION, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onError: () => {
      //
    },
    onCompleted: d => {
      if (d.getRegistration.success && d.getRegistration.data.length > 0) {
        setCompleteNum(d.getRegistration.data[0].stepNumber);
        setCompleteAll(d.getRegistration.data[0].complete);
      }
    },
  });

  useEffect(() => {
    (window as any).registrationLockStepNum = completeNum;
    return () => {
      (window as any).registrationLockStepNum = undefined;
    };
  }, [completeNum]);

  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname.includes('/pass-selection')) {
        setBackUrl('/resident/get-passes');
      } else if (location.pathname.includes('/vehicle')) {
        setBackUrl(`/resident/get-passes/resident-pass/${registrationId}/pass-selection`);
      } else if (location.pathname.includes('/community-orientation')) {
        setBackUrl(`/resident/get-passes/resident-pass/${registrationId}/vehicle`);
      } else if (location.pathname.includes('/secure-checkout')) {
        setBackUrl(
          `/resident/get-passes/resident-pass/${registrationId}/community-orientation`,
        );
      } else if (location.pathname.includes('/receipt')) {
        setBackUrl(
          `/resident/get-passes/resident-pass/${registrationId}/secure-checkout`,
        );
      }
    }
  }, [location, completeNum, registrationId]);

  const { t } = useTranslation();
  return (
    <div className={style.navHeaderBox}>
      <div className={style.textBox}>
        <h2 className={`${style.titleGrey} ${style.inlineTitle} textColorSuperDark`}>
          <Link to={`${backUrl}`} className={style.arrow_left}>
            <BackArrow />
          </Link>
          <div className={style.titleBox}>
            {t('Welcome to')}{' '}
            <p className={`${style.titleBlue} textColorMain `}>{communityName}</p>
          </div>
        </h2>
        <div className={style.registrationBox}>
          <p className={`${style.text} textColorSuperDark `}>
            {t(`Please follow the instruction below to access your Community Access Pass. You
          must register and review community rules before checking out. Registration takes
          approximately 5-10 minutes to complete. Please Note: you cannot enter the
          community or access ammenities without a Community Access Pass. `)}
          </p>
        </div>
      </div>
      {activeNum !== 0 ? (
        <div className={style.iconBox}>
          <RegistrationNavTile
            num={1}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="VehicleImg"
            title={t('Step 1')}
            text={t('Pass Information')}
            href={`/resident/get-passes/resident-pass/${registrationId}/vehicle`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={2}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="RulesImg"
            title={t('Step 2')}
            text={t('Community Orientation')}
            href={`/resident/get-passes/resident-pass/${registrationId}/community-orientation`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={3}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="CreditCardImg"
            title={t('Step 3')}
            text={t('Secure Checkout')}
            href={`/resident/get-passes/resident-pass/${registrationId}/secure-checkout`}
          />
        </div>
      ) : null}
      <h2 className={`${style.title} textColorSuperDark `}>{title}</h2>
      <p className={`${style.subTitle} textColorSuperDark `}>{subtitle}</p>
    </div>
  );
};
ResidentPassHeader.defaultProps = {
  activeNum: 0,
};

export default ResidentPassHeader;
