import { gql, useMutation, useQuery } from '@apollo/client';

const GET_REGISTRATION = gql`
  query GetRegiatration {
    getRegistrations(complete: false) {
      success
      error
      data {
        registrationId
        stepNumber
      }
    }
  }
`;

const CREATE_REGISTRATION = gql`
  mutation CreateRegistration {
    createRegistration {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

type RegistrationInitialization = {
  registrationId?: string;
  loading: boolean;
  error?: string;
};

export default function useRegistrationInitialization(): RegistrationInitialization {
  const [
    doCreateRegistration,
    { data: createdData, loading: createdLoading, error: createdError },
  ] = useMutation(CREATE_REGISTRATION);

  const {
    data: getData,
    loading: getLoading,
    error: getError,
  } = useQuery(GET_REGISTRATION, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      // console.log('reg', d.getRegistrations.data);
      if (d.getRegistrations.success) {
        if (d.getRegistrations.data.length < 1) {
          // console.log('creating registration');
          doCreateRegistration();
        }
      }
    },
  });

  return {
    registrationId: (() => {
      let res;
      try {
        // console.log('created data', createdData?.createRegistration);
        res = createdData.createRegistration.data[0].registrationId;
      } catch {
        try {
          // console.log('fetched data', getData?.getRegistrations);
          res = getData.getRegistrations.data[0].registrationId;
        } catch {
          res = undefined;
        }
      }
      return res;
    })(),
    loading: getLoading || createdLoading,
    error:
      createdError?.message ||
      createdData?.createRegistration?.error ||
      getError?.message ||
      getData?.getRegistrations?.error ||
      undefined,
  };
}
