import { useQuery } from '@apollo/client';
import { Alert, AlertColor, Box } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColumnState, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { ReactElement, useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GridColumnOrderMenu from '../../common_lib_front/components/gridColumnOrderMenu';
import { useUsers } from '../../common_lib_front/hooks/useUsers';
import {
  passStatusMapTitles,
  paymentStatusMapTitles,
} from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import { passCredOrNumber } from '../../common_lib_front/utilities/passCredOrNumber';
import { createPaymentSession } from '../../hooks/usePaymentSession';
import {
  GET_GUESTS,
  GET_GUESTS_RES,
  GET_GUESTS_VARS,
} from '../../pages/host/inviteGuest/inviteGuestRequests';
import ExportPopup from '../guestList/exportPopup';
import { hideInvites } from '../guestTable/bulkActions/hideInvites';
import {
  REINVITE_GUEST_VARS,
  reinviteAllGuests,
} from '../guestTable/bulkActions/reinviteGuest';
import guestTableStyle from '../guestTable/guestTable.module.css';
import dataGridStyle from './guestDataGrid.module.css';
import PopupCellRenderer from './popupCellRenderer';

type GuestDataGridProps = {
  title?: string;
  paginate?: boolean;
  fastPassesOnly?: boolean;
  onBtnExport?: () => void;
};

interface AlertProps {
  severity: AlertColor;
  message: string;
}

interface onAlertProps {
  color: string;
  message: string;
}

// type InvitePass = {
//   passId: string;
//   userId: string;
//   number: string;
//   startDate: string;
//   endDate: string;
//   status: string;
//   paid: boolean;
//   url?: string;
//   passType: string;
// };

export default function GuestDataGrid(props: GuestDataGridProps): ReactElement {
  const { paginate, fastPassesOnly, onBtnExport } = props;
  const [pageNum] = useState<number>(0);
  const { data } = useQuery<GET_GUESTS_RES, GET_GUESTS_VARS>(GET_GUESTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      pageSize: paginate ? 10 : 0,
      pageNumber: pageNum,
      hideDeleted: true,
      fastPassesOnly,
    },
  });
  const { featuresConfig } = useContext(CommunityContext);

  const gridRef = useRef<AgGridReact>(null); // for accessing Grid's API
  const guestsData = data?.getMyGuests.data || [];
  const [itemsPerPage, setItemsPerPage] = useState(25);
  // const filteredGuestsData = guestsData.map(elem => {
  //   if (fastPassesOnly) {
  //     return elem.passes && elem.passes.length > 0 ? elem : null;
  //   } else {
  //     if (elem.passes && elem.passes.length > 1) {
  //       // Filter out 'fast-pass' passes
  //       const filteredPasses = elem.passes.filter(pass => pass.passType !== 'fast-pass');
  //       return filteredPasses.length > 0 ? { ...elem, passes: filteredPasses } : null;
  //     } else if (elem.passes && elem.passes.length === 1) {
  //       const pass = elem.passes[0];
  //       return pass.passType !== 'fast-pass' ? { ...elem } : null;
  //     }
  //   }
  //   return null;
  // });
  // const filteredGuestsDataWithoutNull = filteredGuestsData.filter(elem => elem !== null);

  // const createRowData = (elem: InvitedGuestInfoType, pass: InvitePass | undefined) => ({
  //   firstName: elem.guestInfo ? elem.guestInfo.firstName : '',
  //   lastName: elem.guestInfo ? elem.guestInfo.lastName : '',
  //   emailAddress: elem.guestInfo ? elem.guestInfo.email : '',
  //   rentalAddress: elem.communityRental ? elem.communityRental.address : '',
  //   passNumber: pass ? pass.number : '',
  //   arrivalDate: elem.communityRental
  //     ? elem.communityRental.arrivalDate.slice(0, 10)
  //     : '',
  //   departureDate: elem.communityRental
  //     ? elem.communityRental.departureDate.slice(0, 10)
  //     : '',
  //   passStatus: pass?.status,
  //   paymentStatus: pass?.paid ? 'Paid' : 'Not Paid',
  //   actions: elem,
  // });

  const rowData = guestsData.flatMap(elem => {
    // I suspect this code might be smelly to the well-trained nose
    if (elem.passes && elem.passes.length > 1) {
      return elem.passes.map(pass => ({
        firstName: elem.guestInfo ? elem.guestInfo.firstName : '',
        lastName: elem.guestInfo ? elem.guestInfo.lastName : '',
        emailAddress: elem.guestInfo ? elem.guestInfo.email : '',
        rentalAddress: elem.communityRental ? elem.communityRental.address : '',
        passNumber: passCredOrNumber(pass),
        arrivalDate: elem.communityRental
          ? elem.communityRental.arrivalDate.slice(0, 10)
          : '',
        departureDate: elem.communityRental
          ? elem.communityRental.departureDate.slice(0, 10)
          : '',
        passStatus: pass.status,
        paymentStatus: paymentStatusMapTitles[pass.paid] || '',
        actions: elem,
      }));
    } else {
      return {
        firstName: elem.guestInfo ? elem.guestInfo.firstName : '',
        lastName: elem.guestInfo ? elem.guestInfo.lastName : '',
        emailAddress: elem.guestInfo ? elem.guestInfo.email : '',
        rentalAddress: elem.communityRental ? elem.communityRental.address : '',
        passNumber: elem.passes ? elem.passes[0]?.number : '',
        arrivalDate: elem.communityRental
          ? elem.communityRental.arrivalDate.slice(0, 10)
          : '',
        departureDate: elem.communityRental
          ? elem.communityRental.departureDate.slice(0, 10)
          : '',
        passStatus: elem.passes ? elem.passes[0]?.status : '',
        paymentStatus: elem.passes
          ? paymentStatusMapTitles[elem.passes[0]?.paid] || ''
          : '',
        actions: elem,
      };
    }
  });

  const [alert, setAlert] = useState<AlertProps>();
  const { getCurrentUserInformation } = useUsers();

  const statusStyles = (passStatus: string) => {
    if (passStatus === 'active') {
      return guestTableStyle.active;
    }
    if (passStatus === 'inactive') {
      return guestTableStyle.readyToScan;
    }
    if (passStatus === 'incomplete') {
      return guestTableStyle.incomplete;
    }
    if (passStatus === 'expired') {
      return guestTableStyle.expired;
    }
    if (passStatus === 'refunded') {
      return guestTableStyle.refunded;
    }
    if (passStatus === 'incomplete-rental-car') {
      return guestTableStyle.incompleteRentalCar;
    }
    if (passStatus === 'deleted') {
      return guestTableStyle.expired;
    }
    return '';
  };

  const PassStatus = ({ value }: { value: string }) => (
    <div className={`${dataGridStyle.gridStatusBox} ${statusStyles(value)}`}>
      {passStatusMapTitles[value || ''] || ''}
    </div>
  );

  const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

  const filterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dateAsString = cellValue;
      const dateParts = dateAsString.split('-');
      const cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
  };

  const [dropdownValue] = useState('');
  const history = useHistory();

  const handleOptionChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedRowData = gridRef.current?.api.getSelectedRows();
    if (selectedRowData?.length === 0) {
      window.alert('No guest(s) selected.');
      return;
    }
    switch (selectedValue) {
      case 'reinvite':
        //reinvites are sent to only for those payment status is paid
        const regn = Object.fromEntries(
          (selectedRowData as any[] | undefined)?.map(d => [
            d.actions?.registration?.registrationId,
            true,
          ]) as Iterable<readonly [PropertyKey, boolean]>,
        );
        const allowPaidWasNotGoodVariableName =
          featuresConfig?.host?.inviteGuest?.hostMustPay &&
          !selectedRowData?.every(
            d =>
              d.actions.passes?.every((p: { paid: any }) =>
                ['paid', 'ach-pending', 'no-charge'].includes(p.paid),
              ) && regn[d.actions.registration?.registrationId || ''],
          );
        if (allowPaidWasNotGoodVariableName) {
          window.alert('Select only paid guest(s) to be re-invited.');
          break;
        }
        const reinviteInfo: REINVITE_GUEST_VARS[] = (selectedRowData ?? [])
          .map(d => ({
            registrationId: d.actions.registration?.registrationId || '',
            email: d.actions.guestInfo?.email || '',
            phoneNumber: d.actions.guestInfo?.phoneNumber || '',
          }))
          .filter(gi => gi.registrationId);
        if (reinviteInfo?.length === 0) {
          window.alert('No guests selected');
          break;
        }
        if (window.confirm('Are you sure you would like to re-invite selected guests? '))
          reinviteAllGuests(reinviteInfo)
            .then(() => {
              window.alert('Re-invites are being sent to the selected guests now.');
            })
            .catch(() => {
              window.alert(
                'Something went wrong. Reinvites may not have been processed.',
              );
            })
            .finally(() => {
              const selectedNodes = gridRef.current?.api.getSelectedNodes();
              selectedNodes?.forEach(function (node) {
                node.setSelected(false);
              });
              gridRef.current?.api.refreshCells();
            });
        break;
      case 'pay':
        //This 'pay' option should allow only those payment status is not-paid
        const regns = Object.fromEntries(
          (selectedRowData || []).map(d => [
            d.actions.registration?.registrationId,
            true,
          ]) as Iterable<readonly [PropertyKey, boolean]>,
        );
        const allowPay = selectedRowData?.some(
          d =>
            d.actions.passes?.every(
              (p: { paid: any }) => !(p.paid === 'unpaid' || p.paid === 'ach-failed'),
            ) && regns[d.actions.registration?.registrationId || ''],
        );
        console.log(allowPay);
        if (allowPay) {
          window.alert('Some of the selected guest(s) made payment already!');
          break;
        }
        const paymentSessionId = (
          await createPaymentSession({
            newPaymentSession: {
              registrationIds: Object.keys(regns).filter(k => regns[k]),
            },
          })
        ).data?.createPaymentSession.data?.paymentSessionId;
        const qp = new URLSearchParams();
        qp.set('paymentSessionId', paymentSessionId || '');
        history.push(`/invite-guest/payment?${qp.toString()}`);
        break;
      case 'delete':
        const checks = Object.fromEntries(
          (selectedRowData || []).map(d => [
            d.actions?.registration?.registrationId,
            true,
          ]) as Iterable<readonly [PropertyKey, boolean]>,
        );
        if (window.confirm('Are you sure you would like to delete selected guest(s)? '))
          //setloading(true);
          hideInvites({
            registrationIds: Object.entries(checks)
              .filter(e => e[1])
              .map(e => e[0]),
          })
            .then(() => {
              gridRef.current?.api.applyTransaction({ remove: selectedRowData });
              window.alert('Deleted the selected guests.');
            })
            .catch(() => {
              window.alert('Something went wrong while deleting the selected guest(s).');
            })
            .finally(() => {
              gridRef.current?.api.refreshCells();
            });
        break;
      default:
        break;
    }
  };

  const BulkActionsCellRenderer = () => (
    <select
      className={dataGridStyle.bulkSelectBox}
      //disabled={!dropdownEnabled}
      value={dropdownValue}
      onChange={event => handleOptionChange(event)}
    >
      <option value="bulkactions" selected>
        Bulk Actions
      </option>
      <option value="reinvite">Reinvite</option>
      <option value="pay">Pay</option>
      {/* <option value="delete">Delete</option> */}
    </select>
  );

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
  };

  const [columnDefs] = useState([
    {
      headerName: '',
      field: 'bulkselect',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      editable: false,
      sortable: false,
      filter: false,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      minWidth: 100,
    },
    { headerName: 'Last Name', field: 'lastName', minWidth: 100 },
    { headerName: 'Email Address', field: 'emailAddress', hide: true, minWidth: 200 },
    {
      headerName: 'Rental Address',
      field: 'rentalAddress',
      flex: 2,
      wrapText: true,
      minWidth: 200,
    },
    { headerName: 'Pass Number', field: 'passNumber', minWidth: 100 },
    {
      headerName: 'Arrival Date',
      field: 'arrivalDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      valueFormatter: dateFormatter,
      minWidth: 100,
    },
    {
      headerName: 'Departure Date',
      field: 'departureDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      valueFormatter: dateFormatter,
      minWidth: 100,
    },
    {
      headerName: 'Pass Status',
      field: 'passStatus',
      cellRendererFramework: PassStatus,
      minWidth: 160,
    },
    { headerName: 'Payment Status', field: 'paymentStatus', minWidth: 100 },
    {
      headerName: '',
      pinned: true,
      field: 'actions',
      headerComponent: BulkActionsCellRenderer,
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
  ]);

  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  const [open, setOpen] = useState<boolean>(false);

  const onAlert = ({ color, message }: onAlertProps) => {
    let severity;
    if (color === 'green') severity = 'success' as unknown as AlertColor;
    if (color === 'red') severity = 'error' as unknown as AlertColor;
    if (severity) {
      setAlert({ severity, message });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const restoreState = useCallback(async () => {
    const userInfo = await getCurrentUserInformation();
    const gridSettings = userInfo?.gridSettings?.grids.filter(
      (grid: { gridName: string }) => grid.gridName === 'my-passes',
    );
    if (!gridSettings || !gridSettings?.[0]) {
      return;
    }

    if (gridSettings || gridSettings?.[0]) {
      const currentColState = gridSettings?.[0];
      gridRef.current!.columnApi.applyColumnState({
        state: currentColState.columns as unknown as ColumnState[],
        applyOrder: true,
      });
    }
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onFirstDataRendered = useCallback(() => {
    restoreState();
  }, [restoreState]);

  const onGridReady = (event: GridReadyEvent) => {
    restoreState();
  };

  return (
    <div>
      {open && <ExportPopup setOpen={setOpen} />}
      {alert && (
        <Box width={'100%'} sx={{ pb: 2 }}>
          <Alert severity={alert.severity}>{alert.message}</Alert>
        </Box>
      )}
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex', justifyContent: 'right', mr: 2 },
          }}
        >
          <GridColumnOrderMenu
            agGridRef={gridRef}
            referenceGrid="my-passes"
            onAlert={onAlert}
          />
        </Box>
        <Box sx={{ ml: 1 }}>
          <button
            className={dataGridStyle.btnExport}
            onClick={() => {
              if (onBtnExport) {
                onBtnExport();
              }
            }}
          >
            {fastPassesOnly ? 'Export Fast Pass List' : 'Export Guest List'}
          </button>
        </Box>
        {/*<button onClick={onBtnExport}>Download CSV </button>*/}
      </div>
      <div className={dataGridStyle.paginationBox}>
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={dataGridStyle.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>
      <div className={dataGridStyle.radiusBox}>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%', overflow: 'scroll' }}
        >
          <AgGridReact
            rowData={rowData}
            rowSelection={'multiple'}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            animateRows={true}
            pagination={true}
            paginationPageSize={itemsPerPage}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onGridReady}
            // paginationAutoPageSize
            ref={gridRef}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}
