import { ReactElement, useContext, useState, useEffect } from 'react';
import { ReactComponent as HostRegistrationPic } from '../../../assets/hostRegistration.svg';
import { ReactComponent as Invite } from '../../../assets/inviteGuest.svg';
import { ReactComponent as ProfilePic } from '../../../assets/profile.svg';
import { ReactComponent as RegistrationPic } from '../../../assets/registration.svg';
import { ReactComponent as SharePass } from '../../../assets/sharePass.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import Card from '../../../components/card/card';
import { useGetResidentApplication } from '../../../hooks/useGetApplication';
import style from './hostHome.module.css';

// const HOST_APPLICATION_STATUS_QRY = gql`
//   query GetHostApplicationStatus {
//     getHostApplicationStatus {
//       success
//       error
//       data {
//         createdAt
//       }
//     }
//   }
// `;

export default function HostHome(): ReactElement {
  const [hostTileData, setHostTileData] = useState({
    title: '',
    subtitle: '',
    linkURL: '',
  });
  const { communityId, featuresConfig } = useContext(CommunityContext);

  const { data, refetch } = useGetResidentApplication({
    variables: {
      type: 'host',
      userId: '',
    },
  });

  useEffect(() => {
    let newTileData = {};
    if (
      !data?.getApplication?.data ||
      data?.getApplication?.data.status == 'incomplete'
    ) {
      newTileData = {
        title: 'Host Registration',
        subtitle: 'Register as a Rental Host',
        linkURL: '/resident/host-registration',
      };
    } else {
      newTileData = {
        title: 'Rental Property Registration',
        subtitle: 'Register or add new Rental Properties to your “My Rentals” profile',
        linkURL: '/resident/rental-registration',
      };
    }
    setHostTileData(hostTileData => ({
      ...hostTileData,
      ...newTileData,
    }));
    refetch();
  }, [data, refetch]);

  return (
    <div className={style.container}>
      <div className={style.box}>
        {featuresConfig?.host?.enabled ? (
          <>
            <div className={`${style.box__innerBox}`}>
              <Card
                img={<ProfilePic />}
                alt="my profile img"
                title="My Profile"
                description="Access and maintain your profile information."
                buttonLabel="Edit Info"
                href="/resident/edit-profile/my-profile?isHost=true"
                hidden={!featuresConfig?.host?.enabled}
              />
            </div>
            <div className={`${style.box__innerBox}`}>
              <Card
                img={<RegistrationPic />}
                alt="host registration img"
                title={hostTileData.title}
                description={hostTileData.subtitle}
                buttonLabel="Register Now"
                href={hostTileData.linkURL}
                hidden={!featuresConfig?.host?.enabled}
              />
            </div>
            <div className={style.box__innerBox}>
              <Card
                img={<Invite />}
                alt="invite img"
                title="Invite a Guest and Guest List"
                description="Invite a Guest by email and/or text. Use the Guest List to manage your guest reservations."
                buttonLabel="Invite a Guest"
                href="/invite-guest"
              />
            </div>
            <div className={`${style.box__innerBox}`}>
              <Card
                img={<HostRegistrationPic />}
                alt="host registration img"
                title="My Rentals"
                description={
                  communityId === 'sipoa'
                    ? 'View and manage my rentals.'
                    : 'View and manage my short-term rentals.'
                }
                buttonLabel="View Rentals"
                href="/resident/my-rentals"
                hidden={!featuresConfig?.host?.enabled}
              />
            </div>
            {/* <div className={`${style.box__innerBox}`}>
              <Card
                img={<ProfilePic />}
                alt="my company profile img"
                title="My Company Profile"
                description="Access and edit your company profile information including company name, address, contact info, documents and employee info. Register new employees and upload new documents."
                buttonLabel="Edit Info"
                href="/resident/edit-profile/my-profile"
                hidden={!config.host}
              />
            </div> */}
          </>
        ) : null}
        {featuresConfig?.legacySingleUsePassEnabled ? (
          <>
            <div className={style.box__innerBox}>
              <Card
                img={<SharePass />}
                alt="share pass img"
                title={
                  communityId === 'grandcomplex'
                    ? 'Share a One-Time Vendor Pass'
                    : 'Share a Pass'
                }
                description="You can share a single-use pass here."
                buttonLabel="Share a Pass"
                href="/resident/share-pass"
              />
            </div>
          </>
        ) : null}
        {/* {data && data?.getResidentProfile && !data?.getResidentProfile?.data ? null : (
          <div className={style.box__innerBox}>
            <Card
              img={<Resident />}
              alt="resident img"
              title={communityId === 'sipoa' ? 'Resident Portal' : 'Owner Portal'}
              description={
                communityId === 'sipoa'
                  ? 'Back to resident portal.'
                  : 'Back to owner portal.'
              }
              buttonLabel="Back"
              href="/resident"
            />
          </div>
        )} */}
      </div>
    </div>
  );
}
