import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createPaymentSession } from '../../hooks/usePaymentSession';
import { RecipientInfo } from '../inviteGuestForm/inviteGuestForm';

export default function useSearchParams(data: Array<RecipientInfo>) {
  const location = useLocation();
  const history = useHistory();

  // set url params
  useEffect(() => {
    const setParams = async () => {
      // skip if params already set
      if (location.search.length > 1) return;
      // skip if no data
      if (!data.length) return;
      const paymentId = await createPaymentSession({
        newPaymentSession: {
          registrationIds: data.map(d => d.registrationId || '').filter(rid => !!rid),
        },
      })
        .then(res => res.data?.createPaymentSession.data?.paymentSessionId)
        .catch(err => {
          console.error(err);
          return undefined;
        });
      if (!paymentId) {
        console.error('Could not create paymentSessionId');
        return;
      }
      const params = new URLSearchParams();
      params.set('paymentSessionId', paymentId);
      // data.forEach(recipient => {
      //   if (recipient.registrationId) {
      //     params.append('registrationId', recipient.registrationId);
      //   }
      // });
      // location.search = params.toString();
      console.log('new params: ', params);
      history.replace(location.pathname + '?' + params.toString());
    };
    setParams();
  }, [location, data, history]);

  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    const paymentSessionId = params.get('paymentSessionId');
    return {
      paymentSessionId,
      flow: params.get('flow'),
    };
  }, [location.search]);
}
