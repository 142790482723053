import { gql, useMutation } from '@apollo/client';
import { backendResponse } from '../../common_lib_front/types/backendResponse';

const HIDE_INVITE = gql`
  mutation HideInvite($registrationId: String) {
    hideInvite(registrationId: $registrationId) {
      success
      error
    }
  }
`;
type HIDE_INVITE_VARS = {
  registrationId: string;
};
type HIDE_INVITE_RES = {
  hideInvite: backendResponse<undefined>;
};

const REINVITE_GUEST = gql`
  mutation ReinviteGuest($email: String, $phoneNumber: String, $registrationId: String!) {
    reinviteGuest(
      email: $email
      phoneNumber: $phoneNumber
      registrationId: $registrationId
    ) {
      success
      error
    }
  }
`;
type REINVITE_GUEST_VARS = {
  email: string;
  phoneNumber: string;
  registrationId: string;
};
type REINVITE_GUEST_RES = {
  reinviteGuest: backendResponse<undefined>;
};

type useGuestTableType = {
  hideInvite: (registrationId: string) => void;
  reinvite: (
    email: string,
    phoneNumber: string,
    registrationId: string,
  ) => Promise<unknown>;
};
export default function useGuestTable(): useGuestTableType {
  const [doHideInvite] = useMutation<HIDE_INVITE_RES, HIDE_INVITE_VARS>(HIDE_INVITE);
  const [doReinvite] = useMutation<REINVITE_GUEST_RES, REINVITE_GUEST_VARS>(
    REINVITE_GUEST,
  );

  return {
    hideInvite: (registrationId: string) =>
      doHideInvite({
        variables: { registrationId },
      }),
    reinvite: (email: string, phoneNumber: string, registrationId: string) =>
      doReinvite({
        variables: { email, phoneNumber, registrationId },
      }),
  };
}
