import React, { ReactElement, useContext } from 'react';
// import qrcodeImg from '../../assets/qrCode.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { rentalUnitInfo } from '../../common_lib_front/types/rentalUnitInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import style from './rentalPass.module.css';

type Props = {
  rental?: rentalUnitInfo;
};

export default function RentalPass(props: Props): ReactElement {
  const { rental } = props;
  const { passLogo, name: communityName, communityId } = useContext(CommunityContext);

  return (
    <div className={style.container}>
      <div className={`${style.pass} white `}>
        <div className={style.logoBox}>
          <img className={style.logo} src={passLogo} alt="logo" />
        </div>
        <h3 className={`${style.title} textColorSuperDark `}>STR Registration Receipt</h3>
        <p className={style.subtitle}>Authorized by {communityName}</p>
        <div className={style.boxTop}>
          {/* <img className={style.qrCode} src={qrcodeImg} alt="" /> */}
        </div>
        {rental?.address ? (
          <div className={style.rentalAddressBox}>
            Rental Address:
            <strong className={`${style.validFromItems} textColorSuperDark `}>
              {rental?.address}
            </strong>
          </div>
        ) : null}
        <div className={style.timeBox}>
          <div className={style.validFromBox}>
            <h5 className={style.validFrom}>Valid From:</h5>
            <strong className={`${style.validFromItems} textColorSuperDark `}>
              {formatDate(new Date(`01/01/${new Date().getFullYear()}`))}
            </strong>
          </div>
          <div className={style.validToBox}>
            <h5 className={style.validFrom}>Valid To:</h5>
            <strong className={`${style.validFromItems} textColorSuperDark `}>
              {formatDate(new Date(`12/31/${new Date().getFullYear()}`))}
            </strong>
          </div>
        </div>
        {communityId === 'palmettodunes' ? (
          <div className={style.infoBox}>
            <p className={style.justifyCenter}>
              Thank you for registering your short-term rental property. Our team will
              review your registration form, and you will receive an email regarding its
              status. If you have received a statement for the Annual Transportation Fee,
              you can pay it online by logging into the PDPOA Online Payment Portal.
            </p>
            <br />
            <div className={style.linkBox}>
              <a
                href="https://pdpoa.appfolio.com/connect/users/sign_in"
                className={style.link}
                target="_blank"
                rel="noreferrer"
              >
                https://pdpoa.appfolio.com/connect/users/sign_in
              </a>
            </div>
            <br />
            <p className={style.justifyCenter}>
              Please note that if your property is rented through a property management
              company, they have been charged the Transportation Fee.
            </p>
          </div>
        ) : (
          <div className={style.infoBox}>
            Thank you for registering your short-term rental property for 2022. <br />{' '}
            <br />
            Thank you,
            <br />
            {communityName}
          </div>
        )}
        {communityId !== 'palmettodunes' ? (
          <div className={`${style.footerTitle} textColorMedium `}>
            {communityName}
            reserves the right to exclude any person or vehicles from property at any time
          </div>
        ) : null}
      </div>
    </div>
  );
}
RentalPass.defaultProps = {
  rental: undefined,
};
