import React, { ReactElement, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import alertPic from '../../assets/alert.svg';
import styles from './searchSelector.module.css';
import useSearchSelector from './useSearchSelectorObject';

export type SearchSelectorObjectProps<T> = {
  htmlFor: string;
  title: string;
  subtitle?: string;
  value: string;
  changeHandler: (val: string) => void;
  fetch: (val: string) => Promise<Array<T>> | undefined;
  repr: (val: T) => string;
  selectHandler?: (val: T) => void;
  highLightOnFocus?: boolean;
  noMatchAlert?: string;
  hideNoMatchAlert?: boolean;
  addressValues?: Array<string>;
  disabled?: boolean;
  required?: boolean;
};

export default function SearchSelectorObject<T>(
  props: SearchSelectorObjectProps<T>,
): ReactElement {
  const {
    htmlFor,
    title,
    value,
    subtitle,
    highLightOnFocus,
    noMatchAlert,
    hideNoMatchAlert,
    disabled,
    required,
    repr,
  } = props;

  const {
    open,
    setOpen,
    optionsList,
    parentId,
    showWarning,
    checkWarning,
    optionSelectHandler,
    innerChangeHandler,
  } = useSearchSelector(props);

  // const { t } = useTranslation();
  const t = (s: string) => s;
  const [highLight, setHighLight] = useState(false);

  let searchSelector;
  if (disabled) {
    searchSelector = styles.searchSelectorDisabled;
  } else {
    searchSelector = styles.searchSelector;
  }

  return (
    <div id={parentId} className={styles.searchBox}>
      <label htmlFor={htmlFor}>
        <p className={highLight ? styles.highLight : ''}>{title}</p>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        <input
          id={htmlFor}
          className={searchSelector}
          type="text"
          value={value}
          onChange={innerChangeHandler}
          autoComplete="off"
          disabled={disabled}
          onFocus={() => {
            setOpen(true);
            if (highLightOnFocus) {
              setHighLight(true);
            }
          }}
          onBlur={() => {
            checkWarning();
            if (highLightOnFocus) {
              setHighLight(false);
            }
          }}
        />
      </label>
      {!hideNoMatchAlert && showWarning ? (
        <div className={styles.box__passwordAlert}>
          <ul id="password-reqs-list" className={styles.box__passwordInner}>
            <div className={styles.triangle} />
            <li className={`${styles.box__passwordText}`}>
              <img src={alertPic} alt="" className={styles.alert} />
              {t(noMatchAlert || required ? 'Exact match is required' : 'No match found')}
            </li>
          </ul>
        </div>
      ) : null}{' '}
      {open && optionsList.length > 0 ? (
        <div className={styles.searchList}>
          {optionsList.map((o, idx) => (
            <button key={`${repr(o)}_${idx}`} onClick={() => optionSelectHandler(o)}>
              {repr(o)}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}
SearchSelectorObject.defaultProps = {
  selectHandler: undefined,
  subtitle: undefined,
  highLightOnFocus: false,
  noMatchAlert: undefined,
  disabled: undefined,
};
