import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import PassShareInfo from '../../../common_lib_front/types/passShareInfo';
import { SharePassFormCollection } from '../../../components/sharePassForm/sharePassFormCollection';
import style from './SharePass.module.css';
import useSharePass from './useSharePass';

export default function MyPasses(): ReactElement {
  const { alert, alertColor, shareHistory, doShare, doReShare } = useSharePass();
  const { name: communityName } = useContext(CommunityContext);

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.box__subHeader} mainColor `}>
          <div className={`${style.box__circle} white `} />
          <h1 className={`${style.box__title} textColorWhite `}>
            {communityName === 'Grand Complex'
              ? 'Share a Single-Use Vendor Access Pass'
              : 'Share a Single-Use Pass'}
          </h1>
        </div>
        <div className={style.alertBox}>
          <GenericAlert title={alert} color={alertColor} hidden={!alert} />
        </div>
        <div className={`${style.box__flexBox} white `}>
          <div className={style.passForm}>
            <SharePassFormCollection max={5} min={1} submitHandler={doShare} />
          </div>
          <div className={style.passBox}>
            <div className={style.radiusBox}>
              <table width="100%" cellSpacing="0" className={style.table}>
                <thead>
                  <tr className={`${style.tableHeader} mainColor `}>
                    <th
                      className={`${style.tableHeader__item} textColorWhite  ${style.radiu}`}
                    >
                      First Name
                    </th>
                    <th className={`${style.tableHeader__item} textColorWhite `}>
                      Last Name
                    </th>
                    <th className={`${style.tableHeader__item} textColorWhite `}> </th>
                    <th className={`${style.tableHeader__item} textColorWhite `}> </th>
                  </tr>
                </thead>
                <tbody>
                  {shareHistory.map((elem: PassShareInfo) => (
                    <tr className={style.dataLine} key={elem.passId}>
                      <td>{elem.firstName}</td>
                      <td>{elem.lastName}</td>
                      <td>
                        <button
                          className={`${style.resendBtn} white `}
                          onClick={() => doReShare(elem)}
                        >
                          Resend
                        </button>
                      </td>
                      <td>
                        <Link
                          to={{
                            pathname: `/resident/share-pass/${elem.passId}`,
                          }}
                        >
                          <button
                            className={`${style.deleteBtn} darkGrey textColorWhite `}
                          >
                            Delete Pass
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
