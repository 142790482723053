import React from 'react';
import SearchSelectorObject, {
  SearchSelectorObjectProps,
} from '../../common_lib_front/components/searchSelectorObject/searchSelectorObject';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';
import {
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_RES,
  SEARCH_COMPANIES_VARS,
  searchCompaniesByNameReturnType,
} from '../../hooks/useSearchCompaniesByName';

type propsType = Omit<
  SearchSelectorObjectProps<searchCompaniesByNameReturnType>,
  'fetch' | 'repr'
>;

export default function CompanyNameInput(props: propsType): React.ReactElement {
  return (
    <SearchSelectorObject
      {...props}
      repr={val => val.companyName}
      fetch={searchKey =>
        backendClient
          .query<SEARCH_COMPANIES_RES, SEARCH_COMPANIES_VARS>({
            query: SEARCH_COMPANIES,
            variables: { searchKey },
          })
          .then(res => res.data.searchCompaniesByName.data || [])
      }
    />
  );
}
