import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import store from '../../../common_lib_front/utilities/store';
import GuestList from '../../../components/guestList/guestList';
import InviteGuestPayment from '../../../components/inviteGuestPayment/inviteGuestPayment';
import style from './inviteGuest.module.css';
import AddPassToInvite from './subPages/addPassToInvite/addPassToInvite';
import DeleteInvite from './subPages/deleteGuest/deleteInvite';
import EditGuest from './subPages/editGuest/editGuest';
import EditPass from './subPages/editPass/editPass';
import FastPass from './subPages/fastPass/fastPass';
import FastPasList from './subPages/fastPassList/fastPassList';
import GuestDetails from './subPages/guestDetails/guestDetails';
import GuestPass from './subPages/guestPass/guestPass';
import InviteGuestPage from './subPages/inviteGuestPage';
import MarkPayedPasses from './subPages/markPayedPasses/markPayedPasses';
import { SharePass } from './subPages/sharePass/sharePass';
import UploadGuestList from './subPages/uploadGuestList/uploadGuestList';
import useInviteGuest from './useInviteGuest';

export default function InviteGuest(): ReactElement {
  const { data, editData, passInfoData, passInfoError } = useInviteGuest();

  const { communityId } = useContext(CommunityContext);
  const [showFastPassFeatures, setShowFastPassFeatures] = useState<boolean>(
    !!store.getState().user?.fastPassUser,
  );
  useEffect(
    () =>
      store.subscribe(() => {
        setShowFastPassFeatures(!!store.getState().user?.fastPassUser);
      }),
    [setShowFastPassFeatures],
  );
  const [fastPassSelected, setFastPassSelected] = useState<boolean>(false);

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.box__subHeader} mainColor `}>
          <div className={`${style.box__circle} white `} />
          <h1 className={`${style.box__title} textColorWhite `}>
            {communityId === 'sipoa'
              ? 'Invite a Rental Guest'
              : 'Invite a Short-Term Rental Guest'}
          </h1>
        </div>
        <div className={`${style.box__flexBox} white `}>
          <Switch>
            <Route exact path="/invite-guest">
              <InviteGuestPage
                data={data}
                editData={editData}
                fastPassSelected={fastPassSelected}
                setFastPassSelected={setFastPassSelected}
                passInfoData={passInfoData}
                error={passInfoError}
              />
            </Route>
            {showFastPassFeatures && (
              <Route exact path="/invite-guest/fast-pass">
                <FastPass
                  data={data}
                  editData={editData}
                  fastPassSelected={fastPassSelected}
                  setFastPassSelected={setFastPassSelected}
                  passInfoData={passInfoData}
                />
              </Route>
            )}
            {showFastPassFeatures && (
              <Route path="/invite-guest/fast-pass-list">
                <FastPasList />
              </Route>
            )}
            <Route path="/invite-guest/payment">
              <InviteGuestPayment
                data={data}
                editData={editData}
                fastPassSelected={fastPassSelected}
                setFastPassSelected={setFastPassSelected}
              />
            </Route>
            <Route path="/invite-guest/mark-payed">
              <MarkPayedPasses />
            </Route>
            <Route path="/invite-guest/guest-list">
              <GuestList />
            </Route>
            <Route path="/invite-guest/details/:registrationId">
              <GuestDetails />
            </Route>
            <Route path="/invite-guest/add-pass-to-invite/:registrationId">
              <AddPassToInvite passInfoData={passInfoData} />
            </Route>
            <Route path="/invite-guest/refund/:registrationId">
              <GuestDetails refundMode />
            </Route>
            <Route path="/invite-guest/edit/:registrationId">
              <EditGuest />
            </Route>
            <Route path="/invite-guest/delete-guest/:registrationId">
              <DeleteInvite />
            </Route>
            <Route path="/invite-guest/view-pass/:registrationId/:passId">
              <GuestPass />
            </Route>
            <Route path="/invite-guest/edit-pass/:passId/:passInfoId/:number">
              <EditPass />
            </Route>
            <Route path="/invite-guest/share-pass">
              <SharePass />
            </Route>
            <Route path="/invite-guest/upload-guest-list/:flow">
              <UploadGuestList editData={editData} />
            </Route>
            <Route path="/invite-guest/upload-guest-list">
              <UploadGuestList editData={editData} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
