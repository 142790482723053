import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { ReactElement, useState, useRef, useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PassInfo, {
  passStatusMapTitles as ThisIsBadAndDumb,
} from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import ActionCellPopup from '../../pages/passes/actionPopUp/actionPopUp';
import dataGridStyle from './myPassGrid.module.css';

const passStatusTitlesMap: { [Property in keyof typeof ThisIsBadAndDumb]: string } = {
  active: 'Activated',
  inactive: 'Ready to Scan',
  incomplete: 'Incomplete',
  'incomplete-rental-car': 'Must Complete Info',
  expired: 'Expired',
  refunded: 'Refunded',
  'invite-deleted': 'Deleted',
  'temporary-issued': 'Temporary Pass',
};
const passStatusStyleMap: { [key: string]: string } = {
  active: dataGridStyle.active,
  inactive: dataGridStyle.readyToScan,
  incomplete: dataGridStyle.incomplete,
  expired: dataGridStyle.expired,
  'invite-deleted': dataGridStyle.expired,
  refunded: dataGridStyle.refunded,
  'incomplete-rental-car': dataGridStyle.incompleteRentalCar,
};

const paymentStatusMapTitles: { [key: string]: string } = {
  paid: 'Paid',
  unpaid: 'Unpaid',
  'no-charge': 'No Charge',
  refunded: 'Refunded',
};
const paymentStatusStyleMap: { [key: string]: string } = {
  paid: dataGridStyle.active,
  unpaid: dataGridStyle.incomplete,
  'no-charge': dataGridStyle.active,
  refunded: dataGridStyle.refunded,
};

type MyPassGridProps = {
  data: PassInfo[];
  activeTab: string;
};

export default function MyPassGrid(props: MyPassGridProps): ReactElement {
  const { data, activeTab } = props;
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const gridRef = useRef<AgGridReact>(null); // for accessing Grid's API
  const rowData = data.flatMap(elem => ({
    passType: elem.passInfo ? elem.passInfo.name : '',
    passId: elem.passId,
    passInfoId: elem.passInfoId,
    passNumber: elem.number ? elem.number : '',
    paid: elem.paid ? elem.paid : '',
    addons: elem.addons ? elem.addons : '',
    // destination: elem?.communityRental?.address || '',
    startDate: elem.startDate ? elem.startDate.slice(0, 10) : '',
    validThru: elem.endDate || '',
    passShared: elem.sharedWith.length > 0 ? 'Yes' : 'No',
    passStatus: elem.status || '',
    passPrice: elem.priceInfo || '',
    actions: elem,
    make: elem.vehicle.make || '',
    type: elem.vehicle.type || '',
    year: elem.vehicle.year || '',
    color: elem.vehicle.color || '',
    platenumber: elem.vehicle.plateNumber || '',
    primarydrivername: elem.vehicle.primaryDriverName || '',
    driverslicense: elem.vehicle.licensePrimaryDriver || '',
    registrationId: elem.registrationId || '',
    activeTab: activeTab,
    name: elem.vehicle.primaryDriverName || '',
  }));

  const PassStatus = ({ value }: { value: string }) => (
    <div className={`${dataGridStyle.gridStatusBox} ${passStatusStyleMap[value] || ''}`}>
      {passStatusTitlesMap[value] || value}
    </div>
  );

  const PaymentStatus = ({ value }: { value: string }) => (
    <div
      className={`${dataGridStyle.gridStatusBox} ${paymentStatusStyleMap[value] || ''}`}
    >
      {paymentStatusMapTitles[value] || value}
    </div>
  );

  const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

  const filterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dateAsString = cellValue;
      const dateParts = dateAsString.split('-');
      const cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
    refreshStrategy: 'everything',
  };

  const onGridReady = (event: GridReadyEvent) => {
    event.columnApi.applyColumnState({
      state: [
        {
          colId: 'validThru',
          sort: 'desc',
        },
      ],
    });
    gridRef.current?.api.showLoadingOverlay();
  };
  

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: '',
        pinned: true,
        field: 'actions',
        cellRenderer: ActionCellPopup,
        cellRendererParams: {
          // setCurrentPass,
          // setShowInfoDialog,
          // setShareDialog,
          // passInfoMode,
          // setPassInfoMode,
          // setPassDeleteMode,
        },
        editable: false,
        sortable: false,
        filter: false,
        width: 160,
      },
      {
        headerName: 'Pass Number',
        field: 'passNumber',
        minWidth: 100,
      },
      {
        headerName: 'Name',
        field: 'primarydrivername',
        minWidth: 100,
        hide: activeTab !== 'amenity',
      },
      {
        headerName: 'Make',
        field: 'make',
        hide: activeTab === 'amenity',
        minWidth: 100,
      },
      {
        headerName: 'Type',
        field: 'type',
        hide: activeTab === 'amenity',
        minWidth: 100,
      },
      {
        headerName: 'Color',
        field: 'color',
        hide: activeTab === 'amenity',
        minWidth: 100,
      },
      {
        headerName: 'Year',
        field: 'year',
        hide: activeTab === 'amenity',
        minWidth: 100,
      },
      {
        headerName: 'Plate Number',
        field: 'platenumber',
        hide: activeTab === 'amenity',
        minWidth: 100,
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        filter: 'agDateColumnFilter',
        filterParams,
        valueFormatter: dateFormatter,
        minWidth: 100,
      },
      {
        headerName: 'Valid Through',
        field: 'validThru',
        filter: 'agDateColumnFilter',
        filterParams,
        valueFormatter: dateFormatter,
        minWidth: 100,
      },
      {
        headerName: 'Pass Status',
        field: 'passStatus',
        cellRenderer: PassStatus,
        minWidth: 160,
      },
      {
        headerName: 'Payment Status',
        field: 'paid',
        cellRenderer: PaymentStatus,
        minWidth: 160,
      },
    ],
    [activeTab],
  );

  // Export to CSV
  const onBtnExport = useCallback(() => {
    const columnKeys = [
      'passType',
      'passNumber',
      'addons',
      'startDate',
      'validThru',
      'passShared',
      'passStatus',
      'paid',
      'passPrice',
      'make',
      'model',
      'color',
      'platenumber',
      'primarydrivername',
      'driverslicense',
    ];
    const headers: string[] = [];
    columnDefs.slice(0, -1).forEach(col => {
      // headers.push(col?.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    const allNodes: any[] = [];
    gridRef.current?.api.forEachNode(row => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach((row: any) => {
        const { data: item } = row;
        const itemData: any[] = [];
        columnKeys.forEach((key: string) => {
          if (key === 'paid') {
            itemData.push(paymentStatusMapTitles[item[key]] || item[key]);
          } else if (key === 'passStatus') {
            itemData.push(passStatusTitlesMap[item[key]] || item[key]);
          } else if (key === 'validThru' || key === 'startDate') {
            itemData.push(formatDate(new Date(item[key])));
          } else {
            itemData.push(item[key]);
          }
        });
        dataToExport.push(itemData.join(','));
      });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);

  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div>
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />
        <div className={dataGridStyle.paginationBox}>
          <label htmlFor="page-num-inpt">
            Show
            <select
              className={dataGridStyle.selectBox}
              value={itemsPerPage}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setItemsPerPage(Number.parseInt(e.target.value, 10));
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            Per Page
          </label>
        </div>
        <button className={dataGridStyle.btnExport} onClick={onBtnExport}>
          Export CSV
        </button>
      </div>
      <div className={dataGridStyle.radiusBox}>
        <div
          className="ag-theme-alpine"
          style={{
            height: '100%',
            width: '100%',
            overflow: 'scroll',
          }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            paginationPageSize={itemsPerPage}
            onGridReady={onGridReady}
            overlayLoadingTemplate={'<div>Loading...</div>'}
            // paginationAutoPageSize
            ref={gridRef}
          />
        </div>
      </div>
    </div>
  );
}
