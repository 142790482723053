import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import GenericAlert from '../../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../../../common_lib_front/components/popUp/popUp';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';
import {
  passStatusMapTitles,
  paymentStatusMapTitles,
} from '../../../../../common_lib_front/types/passInfo';
import { formatDate } from '../../../../../common_lib_front/utilities/formatDate';
import ActionsBtn from '../../../../../components/actionsBtn/actionsBtn';
import useGetMyGuests from '../../../../../hooks/useGetMyguests';
import { classNames } from '../../../../../utils/css';
import style from './deleteInvite.module.css';

// actual delete - do not use
// const DELETE_USER = gql`
// mutation DeleteUser (
//   $userId: String
// ) {
//   deleteUser (
//     userId: $userId
//   ) {
//     success
//     error
//   }
// }
// `;
const DELETE_USER = gql`
  mutation HideNotDeleteUser($registrationId: String!, $passIds: [String!]) {
    hideInvite(registrationId: $registrationId, passIds: $passIds) {
      success
      error
    }
  }
`;
type DELETE_USER_VARS = {
  registrationId: string;
  passIds: string[];
};
type DELETE_USER_RES = {
  hideInvite: backendResponse<undefined>;
};

// type deleteInviteProps = {
//   data: Array<InvitedGuestInfoType>;
//   refetch: (
//     variables?: Partial<GET_GUESTS_VARS> | undefined,
//   ) => Promise<ApolloQueryResult<GET_GUESTS_RES>>;
// };
export default function DeleteInvite(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [checked, setChecked] = useState<Record<number, boolean>>({});
  const [showList, setShowList] = useState<boolean>(false);

  const history = useHistory();
  const { registrationId } = useParams<{ registrationId: string }>();
  const { data } = useGetMyGuests({
    registrationIds: [registrationId],
    pageNumber: 0,
    pageSize: 0,
  });

  const [doDeleteUser] = useMutation<DELETE_USER_RES, DELETE_USER_VARS>(DELETE_USER, {
    onCompleted: d => {
      if (d?.hideInvite?.success) {
        history.goBack();
      } else {
        setAlert(d.hideInvite.error);
      }
    },
    onError: e => {
      setAlert(e.message);
    },
  });

  const guest = useMemo(
    () => data?.find(g => g.registration?.registrationId === registrationId),
    [registrationId, data],
  );
  function getWarningText() {
    if (!guest?.passes?.length)
      return (
        <div>
          Are you sure you want to delete the guest pass for {guest?.guestInfo?.firstName}{' '}
          {guest?.guestInfo?.lastName}?{' '}
          <h4 className={style.smallWarning}>
            This will deactivate their pass and it will no longer function
          </h4>
        </div>
      );
    return (
      <div>
        Are you sure you want to delete the guest pass for {guest?.guestInfo?.firstName}{' '}
        {guest?.guestInfo?.lastName}?{' '}
        <h4 className={style.smallWarning}>
          This will deactivate their pass{guest.passes.length > 1 ? 'es' : ''} and{' '}
          {guest.passes.length > 1 ? 'they' : 'it'} will no longer function
        </h4>
        {/* <p className={style.deleteTexts}>
          They have {guest.passes.length} pass
          {guest.passes.length > 1 ? 'es' : ''} that will be lost. Lost passes will not be
          able to be refunded. Refunds should be processed before a guest is deleted.
        </p>{' '} */}
      </div>
    );
  }
  function getPassStyles<T extends { status: string }>(pass: T) {
    const classes = [style.statusBox];
    switch (pass.status) {
      case 'active':
        classes.push(style.active);
        break;
      case 'inactive':
        classes.push(style.readyToScan);
        break;
      case 'incomplete':
        classes.push(style.incomplete);
        break;
      case 'expired':
        classes.push(style.expired);
        break;
      case 'refunded':
        classes.push(style.refunded);
        break;
      case 'incomplete-rental-car':
        classes.push(style.incompleteRentalCar);
        break;
      case 'invite-deleted':
        classes.push(style.inviteDeleted);
        break;
    }
    return classNames(...classes);
  }
  return (
    <PopUp largeSize close={() => history.goBack()} title="Delete Guest">
      <div className={style.visibleBox}>
        <div className={style.container}>
          <div className={style.alertPic}>
            <GenericAlert color="red" title={alert} hidden={!alert} />
          </div>
          <h2 className={style.warning}>Warning:</h2>
          <p className={style.instruction}>{getWarningText()}</p>
        </div>
        <div className={style.box}>
          <div className={style.guestInfoBox}>
            <div className={style.guestHeader}>Guest Information</div>
            <table className={style.guestTable}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{`${guest?.guestInfo?.firstName} ${guest?.guestInfo?.lastName}`}</td>
                </tr>
                <tr>
                  <th>Email Address</th>
                  <td className={style.breakWord}>{guest?.guestInfo?.email}</td>
                </tr>
                <tr>
                  <th>Phone Number</th>
                  <td>{guest?.guestInfo?.phoneNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={style.boxRight}>
            <div className={style.borderBox}>
              <table width="100%" cellSpacing="0px" className={style.table}>
                <thead className={`${style.tableHeader} `}>
                  <tr>
                    <th className={style.checkboxTh}>
                      <input
                        className={style.input}
                        type="checkbox"
                        onChange={e => {
                          const res: typeof checked = {};
                          guest?.passes?.forEach((_, i) => {
                            res[i] = e.target.checked;
                          });
                          setChecked(res);
                        }}
                      />
                    </th>
                    <th className={style.tHeaderItem}>Rental Address</th>
                    <th className={style.tHeaderItem}>Arrival Date</th>
                    <th className={style.tHeaderItem}>Departure Date</th>
                    <th className={style.tHeaderItem}>Pass Status</th>
                    <th className={style.tHeaderItem}>Payment Status</th>
                    <th className={style.tHeaderItem}>&#160;</th>
                  </tr>
                </thead>
                <tbody>
                  {guest?.passes?.map((pass, idx) => (
                    <tr className={`${style.sheet} white`} key={idx}>
                      <th className={style.checkBoxTd}>
                        <input
                          className={style.input}
                          type="checkbox"
                          checked={!!checked[idx]}
                          disabled={pass.status === 'invite-deleted'} // {!['active', 'inactive'].includes(guest?.passes?.[idx]?.status || '')}
                          onChange={e => {
                            setChecked(val => ({ ...val, [idx]: e.target.checked }));
                          }}
                        />
                      </th>
                      <td className={style.item}>{guest.communityRental.address}</td>
                      <td className={style.item}>
                        {guest.communityRental.arrivalDate &&
                          formatDate(new Date(guest.communityRental.arrivalDate))}
                      </td>
                      <td className={style.item}>
                        {guest.communityRental.departureDate &&
                          formatDate(new Date(guest.communityRental.departureDate))}
                      </td>
                      <td className={style.item}>
                        <div className={getPassStyles(pass)}>
                          {passStatusMapTitles[pass.status]}
                        </div>
                      </td>
                      <td className={style.item}>{paymentStatusMapTitles[pass.paid]}</td>
                      <td className={style.btnAction}>
                        <ActionsBtn>
                          <div className={style.actionsBtnItems}>
                            <Link to={`/invite-guest/edit/${registrationId}`}>
                              <button>Edit</button>
                            </Link>
                            <Link to={`/invite-guest/refund/${registrationId}`}>
                              <button>Refund</button>
                            </Link>
                          </div>
                        </ActionsBtn>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={style.cancelBtn}>
              <GenericButton
                title="Cancel"
                clickHandler={() => setShowList(false)}
                outline="large"
              />
            </div>
            {guest?.passes?.length && (
              <div className={style.btn}>
                <GenericButton
                  title="Refund"
                  clickHandler={() =>
                    history.replace(`/invite-guest/refund/${registrationId}`)
                  }
                  size="large"
                  color="blue"
                />
              </div>
            )}
            {Object.values(checked).some(val => val) && (
              <div className={style.btn}>
                <GenericButton
                  title="Delete"
                  clickHandler={() => {
                    const passes: string[] = [];
                    Object.entries(checked).forEach(([idx, isChecked]) => {
                      const pid = guest?.passes?.[Number(idx)]?.passId;
                      if (isChecked && !!pid) {
                        passes.push(pid);
                      }
                    });
                    doDeleteUser({
                      variables: {
                        registrationId,
                        passIds: passes,
                      },
                    });
                  }}
                  size="large"
                  color="blue"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PopUp>
  );
}
