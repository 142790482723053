import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';

const EDIT_PAYMENT_STATUSES = gql`
  mutation Mutation($paid: Boolean, $registrationIds: [String]) {
    editPaymentStatuses(paid: $paid, registrationIds: $registrationIds) {
      success
      error
    }
  }
`;
type EDIT_PAYMENT_STATUSES_VARS = {
  paid: boolean;
  registrationIds: string[];
};
type EDIT_PAYMENT_STATUSES_RES = {
  editPaymentStatuses: backendResponse<undefined>;
};

export default function MarkPayedPasses(): ReactElement {
  const history = useHistory();
  const [doEditPaymentStatuses] = useMutation<
    EDIT_PAYMENT_STATUSES_RES,
    EDIT_PAYMENT_STATUSES_VARS
  >(EDIT_PAYMENT_STATUSES);

  useEffect(() => {
    const registrationIds = window.location.hash
      .replace('#', '')
      .split(',')
      .filter(id => !!id);
    doEditPaymentStatuses({
      variables: {
        paid: true,
        registrationIds,
      },
    }).then(async () => {
      history.replace('/invite-guest/guest-list');
    });
  }, [doEditPaymentStatuses, history]);

  return <div />;
}
