import { gql, useQuery } from '@apollo/client';
import { ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import GenericAlert from '../../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { PopUp } from '../../../../../common_lib_front/components/popUp/popUp';
import style from './editPass.module.css';
import EditPassForm from './editPassForm';
import usePassEdit from './usePassEdit';

export const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        type
        color
        licensePlate
        year
        primaryDriverName
      }
    }
  }
`;

type editVehiclePassData = {
  licensePlate: string;
  color: string;
  type: string;
  make: string;
  year: number;
  vehicleId: string;
};

type amenityPassData = {
  vehicleId: string;
  primaryDriverName: string;
  startDate: string;
  endDate: string;
};

const GET_PASS = gql`
  query Query($passId: String!) {
    getPass(passId: $passId) {
      error
      success
      data {
        number
        startDate
        endDate
      }
    }
  }
`;

interface LocationProps {
  data: {
    activeTab: string;
  };
}

export default function EditPass(): ReactElement {
  const { state } = useLocation<LocationProps>();

  const { passId, passInfoId, number } = useParams<{
    passId: string;
    passInfoId: string;
    number: string;
  }>();

  const history = useHistory();
  const { data: passDetails } = useQuery(GET_PASS, {
    variables: {
      passId: passId,
    },
  });

  const {
    loading,
    error: mutationError,
    submit,
  } = usePassEdit({ passInfoId, passId, state });

  const [amenityPassData, setAmenityPassData] = useState<amenityPassData>({
    primaryDriverName: '',
    startDate: '',
    endDate: '',
    vehicleId: '',
  });

  const [vehiclePassData, setVehiclePassData] = useState<editVehiclePassData>({
    licensePlate: '',
    color: '',
    type: '',
    make: '',
    year: 0,
    vehicleId: '',
  });

  const { data: vehicleDetails, error: fetchError } = useQuery(GET_VEHICLE, {
    variables: {
      passId,
    },
    onCompleted: d => {
      if (d.getVehicle.success && d.getVehicle.data) {
        // setData(d.getVehicle.data);
      }
    },
  });

  function updateVehicleData(value: SetStateAction<editVehiclePassData>): void {
    setVehiclePassData(value);
  }

  function updateAmenityData(value: SetStateAction<amenityPassData>): void {
    setAmenityPassData(value);
  }

  useEffect(() => {
    if (state.data.activeTab === 'amenity') {
      setAmenityPassData({
        primaryDriverName: vehicleDetails?.getVehicle?.data?.primaryDriverName,
        startDate: passDetails?.getPass?.data[0].startDate || '',
        endDate: passDetails?.getPass?.data[0].endDate || '',
        vehicleId: vehicleDetails?.getVehicle?.data?.vehicleId,
      });
    } else {
      setVehiclePassData(vehicleDetails?.getVehicle?.data);
    }
  }, [state.data, vehicleDetails, passDetails]);

  return (
    <PopUp
      close={() => history.goBack()}
      title={state.data.activeTab === 'amenity' ? 'Edit Credential' : 'Edit Vehicle'}
    >
      <form
        className={style.wrapper}
        onSubmit={e => {
          e.preventDefault();
          submit(state.data.activeTab === 'amenity' ? amenityPassData : vehiclePassData)
            .then(() => {
              history.goBack();
            })
            .catch((err: any) => {
              // console.warn(err);
            });
        }}
      >
        {typeof fetchError === 'string' && (
          <GenericAlert color="red" title={fetchError} />
        )}
        <GenericAlert color="red" title={mutationError} hidden={!mutationError} />
        <div className={style.details}>
          {state.data.activeTab === 'amenity' ? '' : 'Vehicle Details'}
        </div>

        <div className={style.passNumber}>{number}</div>
        <EditPassForm
          idx={0}
          title={
            state.data.activeTab === 'amenity' ? 'Edit Guest Information' : 'Edit Vehicle'
          }
          amenityPassData={amenityPassData}
          vehiclePassData={vehiclePassData}
          updateVehicleData={updateVehicleData}
          updateAmenityData={updateAmenityData}
          activeTab={state.data.activeTab}
          passInfoId={passInfoId}
        />
        {loading ? (
          <div
            className={
              state.data.activeTab !== 'amenity'
                ? style.submitButton
                : style.amenitySubmitBtn
            }
          >
            <LoadingDiamonds />
          </div>
        ) : (
          <div
            className={
              state.data.activeTab !== 'amenity'
                ? style.submitButton
                : style.amenitySubmitBtn
            }
          >
            <GenericButton
              type="submit"
              title="Save Changes"
              color="blue"
              size="medium"
              outline="none"
              // disabled={!data}
            />
          </div>
        )}
      </form>
    </PopUp>
  );
}
