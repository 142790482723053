import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useMemo } from 'react';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import {
  AugmentedDurationInfo,
  augmentDurationInfo,
} from '../common_lib_front/utilities/augmentDuration';

const GET_PASS_INFOS = gql`
  query GetPassInfosByCommunity($communityId: String!) {
    getPassInfosByCommunity(complete: true, communityId: $communityId) {
      success
      error
      data {
        description
        name
        passInfoId
        passType
        portal
        durationInfo {
          duration1
          duration2
          type
        }
      }
    }
  }
`;
export type rawPassInfoDataType = {
  description: string;
  name: string;
  passInfoId: string;
  passType: string;
  portal: string;
  durationInfo: {
    duration1: number;
    duration2: number;
    type: number;
  };
};
export type passInfoDataType = rawPassInfoDataType & {
  durationInfo: AugmentedDurationInfo;
};
type GET_PASS_INFOS_VARS = {
  communityId: string;
};
type GET_PASS_INFOS_RES = {
  getPassInfosByCommunity: backendResponse<rawPassInfoDataType[]>;
};

type propsType = {
  portal?: string;
  dateRangeFrom?: string;
  dateRangeTo?: string;
  // similar to date range above, but will not filter
  // only used to augment duration infos
  startDate?: string;
  endDate?: string;
  onChange?: (passInfoData: passInfoDataType[]) => void;
  specifficPassInfoId?: string;
  sort?: (a: passInfoDataType, b: passInfoDataType) => number;
  filters?: ((p: passInfoDataType) => boolean)[];
};
type returnType = {
  validPassInfoDatas: passInfoDataType[];
  allPassInfoDatas: passInfoDataType[];
  specifficPassInfoData?: passInfoDataType;
  error?: string;
};

export function useValidPassInfos(props: propsType): returnType {
  const {
    dateRangeFrom,
    dateRangeTo,
    onChange,
    portal,
    specifficPassInfoId,
    sort,
    startDate,
    endDate,
    filters,
  } = props;
  const { communityId } = useContext(CommunityContext);
  // const [allPassInfoDatas, setAllPassInfoDatas] = useState<passInfoDataType[]>([]);
  // const [validPassInfoDatas, setValidPassInfoDatas] = useState<passInfoDataType[]>([]);

  const { data, error } = useQuery<GET_PASS_INFOS_RES, GET_PASS_INFOS_VARS>(
    GET_PASS_INFOS,
    {
      variables: {
        communityId,
      },
      onError: e => {
        console.error(e.message);
      },
    },
  );

  const allPassInfoDatas = useMemo(() => {
    const res =
      data?.getPassInfosByCommunity.data
        ?.filter(raw => !!raw.durationInfo)
        .map(raw => ({
          ...raw,
          durationInfo: augmentDurationInfo(raw.durationInfo, startDate, endDate),
        })) || [];
    return res;
  }, [data, startDate, endDate]);

  const validPassInfoDatas = useMemo(() => {
    let tmp = [...allPassInfoDatas];
    // filter pass types by portal
    if (portal) {
      tmp = tmp.filter(pinfo => pinfo.portal === portal);
    }
    if (filters) {
      for (const fn of filters) {
        tmp = tmp.filter(fn);
      }
    }
    // filter pass types by duration
    if (dateRangeFrom && dateRangeTo) {
      // if dates have inconsistent times, round to nearest day
      // this is unlikely to be an issue
      const duration =
        (new Date(dateRangeTo).getTime() - new Date(dateRangeFrom).getTime()) /
        1000 / // milisecond to second
        60 / // second to minute
        60 / // minute to hour
        24; // hour to day
      tmp = tmp.filter(
        pinfo =>
          typeof pinfo.durationInfo.maxDuration !== 'number' ||
          pinfo.durationInfo.maxDuration >= duration,
      );
    }
    if (sort) {
      tmp.sort(sort);
    }
    return tmp;
    // do not re-run when sort function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPassInfoDatas, dateRangeFrom, dateRangeTo, portal]);

  useEffect(() => {
    onChange?.(validPassInfoDatas);
    // do not re-run when on change function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validPassInfoDatas]);

  // find speciffic pass info data from id if provided
  const specifficPassInfoData = useMemo(() => {
    if (!specifficPassInfoId) return undefined;
    return allPassInfoDatas.find(pinfo => pinfo.passInfoId === specifficPassInfoId);
  }, [allPassInfoDatas, specifficPassInfoId]);

  return {
    validPassInfoDatas,
    allPassInfoDatas,
    specifficPassInfoData,
    error:
      data?.getPassInfosByCommunity.error || error
        ? 'Something went wrong. Could not load pass types.'
        : undefined,
  };
}
