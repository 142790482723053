import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useRef, useState } from 'react';
import useMyRentals from '../../pages/host/myRentals/useMyRentals';
import dataGridStyle from './dataGrid.module.css';
import useColumnDefs, { defaultColDef } from './useColumnDefs';

export default function MyRentalsGrid(): React.ReactElement {
  const gridRef = useRef<AgGridReact>(null);
  const { rentals } = useMyRentals();
  const [itemsPerPage, setItemsPerPage] = useState<number>(25);
  const columnDefs = useColumnDefs();

  return (
    <div>
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={e => {
            gridRef.current?.api.setQuickFilter(e.target.value);
          }}
          placeholder="Search anything here .."
        />

        {/*<button onClick={onBtnExport}>Download CSV </button>*/}
        <button
          className={dataGridStyle.btnExport}
          onClick={() => {
            // setOpen(true);
            gridRef.current?.api.exportDataAsCsv();
          }}
        >
          Export Rentals
        </button>
      </div>
      <div className={dataGridStyle.paginationBox}>
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={dataGridStyle.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>
      <div className={dataGridStyle.radiusBox}>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%', overflow: 'scroll' }}
        >
          <AgGridReact
            rowData={rentals}
            rowSelection={'multiple'}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            animateRows={true}
            pagination={true}
            paginationPageSize={itemsPerPage}
            // paginationAutoPageSize
            ref={gridRef}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}
