import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { RecipientInfo } from '../../../components/inviteGuestForm/inviteGuestForm';

export const INVITE_VENDOR = gql`
  mutation Mutation($inviteVendorInput: InviteVendorInput!) {
    inviteVendor(inviteVendorInput: $inviteVendorInput) {
      data {
        companyInfoId
      }
      error
      success
    }
  }
`;

export type INVITE_VENDOR_VARS = {
  inviteVendorInput: {
    destination: string;
    startDate: string;
    endDate: string;
    companyInfoId?: string | null;
    companyName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
  };
};

export type INVITE_VENDOR_RES = {
  inviteVendor: backendResponse<{
    companyInfoId: string;
  }>;
};

export const inviteVendor = (data: RecipientInfo) => {
  const variables: INVITE_VENDOR_VARS = {
    inviteVendorInput: {
      destination: data.address,
      startDate: data.arrivalDate,
      endDate: data.departureDate,
      companyInfoId: data.companyId,
      companyName: data.companyName,
      email: data.email || null,
      phoneNumber: data.phoneNumber || null,
    },
  };
  return backendClient
    .mutate<INVITE_VENDOR_RES, INVITE_VENDOR_VARS>({
      mutation: INVITE_VENDOR,
      variables,
    })
    .then(res => ({
      data: res.data?.inviteVendor,
      error:
        res.data?.inviteVendor.error || !!res.errors
          ? 'Could not complete invite.'
          : undefined,
    }))
    .catch(() => ({
      data: undefined,
      error: 'Something went wrong. Could not complete invite.',
    }));
};

export const inviteMultipleVendors = (data: RecipientInfo[]) =>
  Promise.all(data.map(v => inviteVendor(v)))
    .then(res => ({
      data: res.map(r => r.data),
      errors: res.map(r => r.error),
    }))
    .catch(() => {
      throw Error('Something went wrong');
    });
