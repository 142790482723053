import React, { ReactElement, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cardPicAmex from '../../../assets/paymentMethodAMEX.svg';
import cardPicDiscover from '../../../assets/paymentMethodDiscover.svg';
import cardPicMaster from '../../../assets/paymentMethodMaster.svg';
import cardPicVisa from '../../../assets/paymentMethodVisa.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import { rentalUnitInfo } from '../../../common_lib_front/types/rentalUnitInfo';
import HostPassSummary from '../../../components/hostPassSummary/hostPassSummary';
import RentalRegistrationNav from '../../../components/rantalRegistrationNav/rentalRegistrationNav';
import style from './secureCheckout.module.css';
import useSecureCheckout from './useSecureCheckout';

export default function RentalSecureCheckout(): ReactElement {
  const { registrationId, rentalData, advanceStep, updateApplicationStatus } =
    useSecureCheckout();
  const history = useHistory();

  const { communityId } = useContext(CommunityContext);

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RentalRegistrationNav
          stepNum={2}
          title="Step 2: Review Information"
          subtitle="Please verify all information below is correct"
        />
        <form
          className={style.form}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            advanceStep();
            updateApplicationStatus();
            sessionStorage.setItem('checkoutPassesList', JSON.stringify(rentalData));
            history.replace(`/resident/rental-registration/${registrationId}/receipt`);
          }}
        >
          <div className={style.flexBox}>
            <div className={style.boxLeft}>
              <div className={style.infoFormBox}>
                <h3
                  className={`${style.reviewTitle} ${style.hidden} textColorSuperDark `}
                >
                  Review Information
                </h3>
                <p className={`${style.subTitle} ${style.hidden} textColorSuperDark `}>
                  Please verify all information below is correct
                </p>
                <div className={style.infoFormBox}>
                  {rentalData?.map((r: rentalUnitInfo, index: number) => (
                    <>
                      <div className={`${style.header} superDarkGrey `} key={index}>
                        <h5 className={`${style.title} textColorWhite `}>
                          {index + 1}. Rental Unit Information
                        </h5>
                        <div className={`${style.btnEdit}`}>
                          <GenericButton
                            title="Edit"
                            color="transparent"
                            outline="no-border"
                            clickHandler={() => {
                              history.replace(
                                `/resident/rental-registration/${registrationId}/rental-units`,
                              );
                            }}
                          />
                        </div>
                      </div>
                      <table key={r.rentalUnitId} className={style.tableBox}>
                        <tr>
                          <th>PROPERTY ADDRESS</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.address}
                          </td>
                        </tr>
                        <tr>
                          <th>CITY</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.city}
                          </td>
                        </tr>
                        <tr>
                          <th>STATE</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.state}
                          </td>
                        </tr>
                        <tr>
                          <th>ZIP CODE</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.zipCode}
                          </td>
                        </tr>
                        <div className={style.manageInfoText}>
                          Rental Management Information
                        </div>
                        <tr>
                          <th>
                            {communityId === 'sipoa'
                              ? 'MANAGEMENT COMPANY NAME'
                              : 'COMPANY NAME OR YOUR NAME IF SELF-MANAGED'}
                          </th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.companyName}
                          </td>
                        </tr>
                        <tr>
                          <th>PROPERTY MANAGER</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.propertyManagerName}
                          </td>
                        </tr>
                        <tr>
                          <th>PROPERTY MANAGER PHONE NUMBER</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.propertyManagerPhone}
                          </td>
                        </tr>
                        <tr>
                          <th>PRIMARY EMERGENCY CONTACT NAME</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.primaryEmergencyContactName}
                          </td>
                        </tr>
                        <tr>
                          <th>PRIMARY EMERGENCY PHONE#</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.primaryEmergencyPhone}
                          </td>
                        </tr>
                        <tr>
                          <th>SECONDARY EMERGENCY PHONE#</th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.secondaryEmergencyPhone}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>PRIMARY EMERGENCY CONTACT EMAIL ADDRESS </th>
                          <td className={`${style.personalInfoDetail} textColorDark `}>
                            {r.email}
                          </td>
                        </tr> */}
                      </table>
                    </>
                  ))}
                </div>
                <div className={`${style.boxRight} ${style.hidden}`}>
                  <HostPassSummary registrationId={registrationId} allowEdit />
                  <div className={style.paymentBox}>
                    <div className={style.paymentInfoBox}>
                      <div className={style.innerBox}>
                        <h3 className={`${style.paymentTitle} textColorDark `}>
                          Credit Card
                        </h3>
                        <div className={style.inputLong}>
                          <InputField
                            closedInput
                            htmlFor="cc-name-inpt"
                            labelTitle="Name on Card"
                          />
                        </div>
                        <div className={style.inputLong}>
                          <InputField
                            closedInput
                            htmlFor="cc-num-inpt"
                            labelTitle="Card Number"
                          />
                        </div>
                        <div className={style.inputShortBox}>
                          <div className={style.inputShort}>
                            <InputField
                              closedInput
                              htmlFor="cc-exp-date-inpt"
                              labelTitle="EXP. Date"
                              inputType="date"
                            />
                          </div>
                          <div className={style.inputShort}>
                            <InputField
                              closedInput
                              htmlFor="cc-csv-inpt"
                              labelTitle="CSV"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.cardBox}>
                    <div className={style.cardInnerBox}>
                      <h4 className={`${style.cardTitle} textColorSuperDark`}>
                        All major credit and debit cards are accepted
                      </h4>
                      <div className={style.imgBox}>
                        <img src={cardPicVisa} alt="" className={style.img} />
                        <img src={cardPicMaster} alt="" className={style.img} />
                        <img src={cardPicDiscover} alt="" className={style.img} />
                        <img src={cardPicAmex} alt="" className={style.img} />
                      </div>
                      <p className={`${style.cardDescription} textColorMedium`}>
                        Prices in US Dollars (USD). Community Access Passes are Total
                        calculated at checkout.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.btn}>
            <GenericButton
              size="large"
              title="Submit Information"
              color="blue"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
