import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as UserImg } from '../../assets/userMobile.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import store from '../../common_lib_front/utilities/store';
import { logout } from '../../common_lib_front/utilities/thunks';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import style from './userProfileButton.module.css';

type propsType = {
  isHost: boolean;
};

export const UserProfileButton = (props: propsType): ReactElement => {
  const { isHost } = props;
  const {
    open,
    setOpen,
    containerId: parentId,
  } = useContainerBlur('user-profile-btn', {
    autoCloseInnerButton: true,
  });
  const smallScreen = useMediaQuery('(max-width: 767px)');

  const [user, setUser] = useState<any>(store.getState().user);
  useEffect(() => store.subscribe(() => setUser(store.getState().user)), [setUser]);
  return (
    <div id={parentId} className={style.box}>
      <GenericButton
        color={smallScreen ? 'white' : 'yellow'}
        icon={() => <UserImg className={`${style.userImg}`} />}
        shape="circle"
        title={smallScreen ? '' : user?.firstName?.slice(0, 1).toUpperCase() || ''}
        clickHandler={() => {
          setOpen(o => !o);
        }}
      />
      {open ? (
        <div className={`${style.itemsBox} white`}>
          <h4
            className={`${style.name} textColorMedium`}
          >{`${user?.firstName} ${user?.lastName}`}</h4>
          <p className={`${style.email} textColorMedium`}>{user?.email}</p>
          {/* {!pathname.includes('/resident/registration/') ? ( */}
          <Link to={`/resident/edit-profile/my-profile?isHost=${String(isHost)}`}>
            <button id="my-profile-btn" className={`${style.btn} textColorMedium white`}>
              <div className={style.imgUser} /> My Profile
            </button>
          </Link>
          {/* ) : null} */}
          <button
            id="logout-btn"
            onClick={() => {
              // store.dispatch({ type: 'user/logout' });
              store.dispatch(logout);
            }}
            className={`${style.btn} textColorMedium white`}
          >
            <div className={style.imgSignout} />
            Sign Out
          </button>
          <p className={`${style.help} textColorMedium`}>Help</p>
        </div>
      ) : null}
    </div>
  );
};

export default UserProfileButton;
