import React, {
  ReactElement,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import InputField from '../../../../../common_lib_front/components/inputField/inputField';
import { PassBuilderDateInputWithFetchDurtionInfo } from '../../../../../common_lib_front/components/passBuilderDateRange/withFetchDurationInfo';
import VehicleTypeSelect from '../../../../../common_lib_front/components/vehicleTypeSelect/vehicleTypeSelect';
import style from './editPassForm.module.css';

type vehiclePassData = {
  vehicleId: string;
  make: string;
  type: string;
  color: string;
  licensePlate: string;
  year: number;
};

type amenityPassData = {
  primaryDriverName: string;
  startDate: string;
  endDate: string;
  vehicleId: string;
};

type EditPassFormProps = {
  vehiclePassData: vehiclePassData;
  amenityPassData: amenityPassData;
  idx: number;
  title?: string;
  updateVehicleData: Dispatch<SetStateAction<vehiclePassData>>;
  updateAmenityData: Dispatch<SetStateAction<amenityPassData>>;
  activeTab: string;
  passInfoId: string;
};

export const EditPassForm = (props: EditPassFormProps): ReactElement => {
  const {
    idx,
    vehiclePassData,
    amenityPassData,
    updateVehicleData,
    updateAmenityData,
    activeTab,
    passInfoId,
  } = props;

  const [passAmenityData, setPassAmenityData] = useState<amenityPassData>();
  const [passVehicleData, setPassVehicleData] = useState<vehiclePassData>();

  useEffect(() => {
    if (activeTab === 'amenity') {
      setPassAmenityData(amenityPassData);
    } else {
      setPassVehicleData(vehiclePassData);
    }
  }, [amenityPassData, activeTab, vehiclePassData]);

  return (
    <div className={style.infoFormBox}>
      {activeTab !== 'amenity' ? (
        <div className={style.inputShortBox}>
          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor={`make-inpt-${idx}`}
              required={true}
              labelTitle={'Vehicle Make'}
              inputType="text"
              inputValue={passVehicleData?.make}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateVehicleData(prev => ({
                  ...prev,
                  make: e.target.value,
                }));
              }}
            />
          </div>

          <div className={style.inputShort}>
            <VehicleTypeSelect
              htmlFor={`type-inpt-${idx}`}
              labelTitle={'Vehicle Type'}
              inputValue={passVehicleData?.type}
              changeHandler={e => {
                updateVehicleData(prev => ({
                  ...prev,
                  type: e.target.value,
                }));
              }}
              required={true}
            />
          </div>

          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor={`color-inpt-${idx}`}
              required={true}
              labelTitle={'Vehicle color'}
              inputType="text"
              inputValue={passVehicleData?.color}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateVehicleData(prev => ({
                  ...prev,
                  color: e.target.value,
                }));
              }}
            />
          </div>

          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor={`license-plate-inpt-${idx}`}
              required={true}
              labelTitle={'Vehicle License Plate'}
              inputType="text"
              inputValue={passVehicleData?.licensePlate}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateVehicleData(prev => ({
                  ...prev,
                  licensePlate: e.target.value,
                }));
              }}
            />
          </div>

          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor={`year-inpt-${idx}`}
              //required={true}
              labelTitle={'Vehicle Year'}
              inputType="number"
              inputValue={`${passVehicleData?.year}`}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateVehicleData(prev => ({
                  ...prev,
                  year: parseFloat(e.target.value),
                }));
              }}
            />
          </div>
        </div>
      ) : null}

      {activeTab === 'amenity' ? (
        <div className={style.inputShortBox}>
          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor={`name-inpt-${idx}`}
              required={true}
              labelTitle={'Name'}
              inputType="text"
              inputValue={passAmenityData?.primaryDriverName}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateAmenityData(prev => ({
                  ...prev,
                  primaryDriverName: e.target.value,
                }));
              }}
            />
          </div>

          <PassBuilderDateInputWithFetchDurtionInfo
            startDate={passAmenityData?.startDate || ''}
            endDate={passAmenityData?.endDate || ''}
            setter={obj =>
              updateAmenityData(prev => ({
                ...prev,
                ...obj,
              }))
            }
            passInfoId={passInfoId}
            autoSetDefault={cfg => [0, 1].includes(cfg.type)}
            id={`date-range-${idx}-inpt`}
            required
          />
        </div>
      ) : null}
    </div>
  );
};

EditPassForm.defaultProps = {};

export default EditPassForm;
