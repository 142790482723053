/* eslint-disable indent */
import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddImg } from '../../../assets/add.svg';
import { ReactComponent as DelBtn } from '../../../assets/deleteRed.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';
import style from './addFamilyAndFriends.module.css';
import useAddFamilyOrFriend from './useFriendAndFamily';

export const ADD_FAMILY_FRIEND = gql`
  mutation addFamilyMember($data: FamilyMemberInput!) {
    addFamilyMember(data: $data) {
      success
      error
    }
  }
`;

export default function AddFamilyMembers(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const history = useHistory();
  const [adding, setAdding] = useState<boolean>(false);
  const [employeeSelect, setEmployeeSelect] = useState<boolean>(false);
  const { communityId } = useContext(CommunityContext);

  useEffect(() => {
    if (['pinnacleport', 'carillonbeach'].indexOf(communityId) > -1) {
      setEmployeeSelect(true);
    }
  }, []);

  const { employees, onAddMore, deleteOne, edit, errorStrings, onSubmit } =
    useAddFamilyOrFriend();

  const [doAddMember] = useMutation(ADD_FAMILY_FRIEND);
  const submitHandler = async () => {
    const isValid = onSubmit();
    if (!isValid) {
      return;
    }
    try {
      setAdding(true);
      setAlert('');
      await Promise.all(
        employees?.map(e =>
          doAddMember({
            variables: {
              data: {
                firstName: e?.firstName,
                lastName: e?.lastName,
                email: e?.email,
                phoneNumber: e?.phoneNumber,
                file: e?.file,
                fileName: e?.fileName,
                driversLicenseNum: e?.driverLc,
                driversLicenseExp: e?.driverLcExp,
                isLicenseSelected: !employeeSelect,
                driversLicenseState: e?.driversLicenseState,
              },
            },
          }).then(({ data }) => {
            if (data.addFamilyMember.success) {
              history.goBack();
            } else {
              setAlert(data.addFamilyMember.error);
            }
          }),
        ),
      );
    } catch (error: any) {
      setAlert(error?.message);
      setAlertColor('red');
    } finally {
      setAdding(false);
    }
  };
  const myEmpInputRef = useRef<HTMLInputElement[]>([]);

  myEmpInputRef.current = [];
  const addToEmpRefs: (el: HTMLInputElement) => void = el => {
    if (el && !myEmpInputRef.current.includes(el)) {
      myEmpInputRef.current.push(el);
    }
  };

  const fileToBase64 = function (file: any): Promise<string> {
    return new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };
  const selectForUpload = async function (event: any, employee: any, index: number) {
    if (!event?.target?.files[0]) return;
    const url = URL.createObjectURL(event.target.files[0]);
    const s = await fileToBase64(event.target.files[0]);
    edit(index, {
      ...employee,
      fileName: event.target.files[0].name,
      file: s,
      fileUrl: url,
    });
    // edit(index, { ...employee, file: event.target.files});
  };

  const getButtonText = (index: number) => (
    <span>
      {/* eslint-disable prettier/prettier */}
      {employees[index] && employees[index].fileName
        ? employees[index].fileName
        : !employeeSelect
          ? "Scan/Upload Driver's License"
          : 'Upload Family/Friend picture'}
      {/* eslint-enable prettier/prettier */}
    </span>
  );

  return (
    <PopUp title="Add Family Member or Friend" close={() => history.goBack()}>
      <div className={style.box}>
        {['sipoa'].indexOf(communityId) < 0 && (
          <div style={{ display: 'block' }} className={style.title}>
            To add an family member or friend, please enter the family member or
            friend&apos;s name, phone number and email.
          </div>
        )}
        {['sipoa'].indexOf(communityId) > -1 && (
          <div style={{ display: 'block' }} className={`${style.list} ${style.title}`}>
            <span>
              Completing this form adds the individual to your profile and supports
              requesting an access pass.
            </span>
            <ul>
              <li>
                To add a family member or friend, please enter the name, phone number and
                email.
              </li>
              <li>
                For requesting an Annual / Long-Term Pass, you <u>must</u> complete the
                entire form and upload a Driver License image.
              </li>
            </ul>
          </div>
        )}
        <div className={style.box}>
          {['pinnacleport', 'carillonbeach', 'sipoa'].indexOf(communityId) < 0 ? (
            <>
              <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
                <p>An family member / friend Driver License or picture is required</p>
              </div>
              <div className={style.completeBox}>
                <input
                  type="checkbox"
                  checked={employeeSelect}
                  onChange={() => {
                    setEmployeeSelect(!employeeSelect);
                  }}
                  className={style.checkbox}
                />
                <p className={style.completeBoxLabel}>Upload Family/Friend picture</p>
              </div>
            </>
          ) : null}
        </div>

        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            submitHandler();
          }}
          className={style.form}
        >
          <div>
            {adding ? (
              <div style={{ paddingBottom: '100px' }}>
                <LoadingDiamonds title="Saving Employee(s)" />
              </div>
            ) : (
              <div className={style.card}>
                {employees?.map((employee, index) => (
                  <div key={index} className={style.infoBox}>
                    <GenericAlert
                      color={alertColor}
                      title={errorStrings?.[index]}
                      hidden={!errorStrings?.[index]}
                    />

                    <div className={style.header}>{`Family Member / Friend ${
                      ['sipoa'].indexOf(communityId) > -1 ? '' : index + 1
                    }`}</div>
                    <div className={style.employeeBox}>
                      <div className={style.delBtnDiv}>
                        <button
                          type="button"
                          onClick={() => deleteOne(index)}
                          className={style.delBtn}
                        >
                          <DelBtn />
                        </button>
                      </div>
                      <div className={style.flexBox}>
                        <div className={style.boxLeft}>
                          <div className={style.row}>
                            {['sipoa'].indexOf(communityId) < 0 && (
                              <div className={style.input}>
                                <input
                                  accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                                  hidden
                                  ref={addToEmpRefs}
                                  type="file"
                                  name="file"
                                  onChange={e => selectForUpload(e, employee, index)}
                                />
                                <button
                                  type="button"
                                  onClick={e => {
                                    e.preventDefault();
                                    myEmpInputRef.current[index].click();
                                  }}
                                  className={style.uploadeBtn}
                                >
                                  {getButtonText(index)}
                                </button>
                                {}
                              </div>
                            )}
                            <div className={style.rowName}>
                              <div className={style.inputShort}>
                                <InputField
                                  smallSize
                                  highlightOnFocus
                                  htmlFor={`f-name-inpt-${index}`}
                                  labelTitle="First Name*"
                                  inputType="text"
                                  inputValue={employee?.firstName || ''}
                                  changeHandler={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    edit(index, {
                                      ...employee,
                                      firstName: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className={style.inputShort}>
                                <InputField
                                  smallSize
                                  highlightOnFocus
                                  htmlFor={`l-name-inpt-${index}`}
                                  labelTitle="Last Name*"
                                  inputType="text"
                                  inputValue={employee?.lastName || ''}
                                  changeHandler={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    edit(index, {
                                      ...employee,
                                      lastName: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className={style.input}>
                              <PhoneInputWrapper
                                smallStandardInput
                                inputTitle="Phone Number*"
                                value={employee?.phoneNumber || ''}
                                changeHandler={(val: string) => {
                                  edit(index, { ...employee, phoneNumber: val });
                                }}
                              />
                            </div>
                          </div>
                          <div className={style.input}>
                            <InputField
                              smallSize
                              highlightOnFocus
                              htmlFor={`email-inpt-${index}`}
                              labelTitle="Email*"
                              inputType="email"
                              inputValue={employee?.email || ''}
                              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                edit(index, { ...employee, email: e.target.value });
                              }}
                            />
                          </div>
                          {!employeeSelect && (
                            <div className={style.rowName}>
                              <div className={style.input}>
                                <InputField
                                  smallSize
                                  highlightOnFocus
                                  htmlFor={`driverLc-inpt-${index}`}
                                  labelTitle="Driver's License Number"
                                  inputType="driverLc"
                                  inputValue={employee?.driverLc || ''}
                                  changeHandler={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    edit(index, {
                                      ...employee,
                                      driverLc: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className={style.input}>
                                <InputField
                                  smallSize
                                  highlightOnFocus
                                  htmlFor={`driversLicenseState-inpt-${index}`}
                                  labelTitle="Driver's License State"
                                  inputType="driversLicenseState"
                                  inputValue={employee?.driversLicenseState || ''}
                                  changeHandler={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    edit(index, {
                                      ...employee,
                                      driversLicenseState: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {!employeeSelect && (
                            <div className={style.input}>
                              <InputField
                                smallSize
                                highlightOnFocus
                                htmlFor={`driverLcExp-inpt-${index}`}
                                labelTitle="Driver's License Expiration"
                                inputType="date"
                                inputValue={employee?.driverLcExp || ''}
                                changeHandler={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  edit(index, {
                                    ...employee,
                                    driverLcExp: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className={style.boxRight}>
                          {employee?.fileUrl ? (
                            <img
                              width="100%"
                              height="auto"
                              alt="Not available"
                              src={employee?.fileUrl}
                              className={style.img}
                            />
                          ) : (
                            <div className={style.noImage}>
                              <p>Select picture</p>
                            </div>
                          )}
                          {/* <img width={500} height={350} src={employee?.fileUrl} /> */}
                        </div>
                      </div>

                      {['sipoa'].indexOf(communityId) > -1 && (
                        <div className={style.uploadBtn}>
                          <input
                            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                            hidden
                            ref={addToEmpRefs}
                            type="file"
                            name="file"
                            onChange={e => selectForUpload(e, employee, index)}
                          />
                          <button
                            type="button"
                            onClick={e => {
                              e.preventDefault();
                              myEmpInputRef.current[index].click();
                            }}
                            className={style.uploadeBtn}
                          >
                            {getButtonText(index)}
                          </button>
                          {}
                        </div>
                      )}
                    </div>
                    <div />
                  </div>
                ))}
              </div>
            )}
            <div className={style.addBtnBox}>
              <div className={style.addBtn}>
                <GenericButton
                  icon={() => <AddImg className={style.addBtnImg} />}
                  title="Add More"
                  type="button"
                  clickHandler={onAddMore}
                />
              </div>
            </div>
          </div>
          <div>
            <GenericAlert color={alertColor} title={alert} hidden={!alert} />
          </div>
          <div className={style.btnBox}>
            <div className={style.btn}>
              <GenericButton
                size="large"
                color="blue"
                title="Save"
                type="submit"
                disabled={adding}
              />
            </div>
          </div>
        </form>
      </div>
    </PopUp>
  );
}
