export type PassShareInfo = {
  passId: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  [key: string]: any;
};

export function newPassShareInfo(data: any = {}): PassShareInfo {
  const res = {
    passId: '',
    firstName: '',
    lastName: '',
    email: null,
    phone: null,
  };
  Object.assign(res, data);
  return res;
}

export default PassShareInfo;
