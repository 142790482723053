import { useEffect } from 'react';
import UserInfo from '../types/userInfo';

type SegmentIdentifyProps = {
  communityCode: string;
  email: string;
  firstName: string;
  lastName: string;
  pdkId?: number;
  phoneNumber: string;
  roles: Array<string>;
  status: string;
};

export default function useSegmentIdentify(loggedIn: boolean, userInfo: UserInfo): void {
  useEffect(() => {
    if (loggedIn && userInfo) {
      const trackingProperties: SegmentIdentifyProps = {
        communityCode: userInfo.communityCode,
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        pdkId: userInfo.pdkId,
        phoneNumber: userInfo.phoneNumber,
        roles: userInfo.roles,
        status: userInfo.status,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      analytics.identify(userInfo.userId, trackingProperties);
    }
  }, [loggedIn, userInfo]);
}
