import { gql, useMutation, useQuery } from '@apollo/client';
import { ReactElement, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';

const GET_REGISTRATION = gql`
  query GetRegistration {
    getRegistrationData(complete: false, type: "residentPass") {
      success
      error
      data {
        registrationId
        stepNumber
      }
    }
  }
`;
type GET_REGISTRATION_RES = {
  getRegistrationData: backendResponse<
    {
      registrationId: string;
      stepNumber?: number;
    }[]
  >;
};

const CREATE_REGISTRATION = gql`
  mutation CreateRegistration {
    createRegistration(type: "resident-pass") {
      success
      error
      data {
        registrationId
        stepNumber
      }
    }
  }
`;

const CREATE_RESIDENT_APPLICATION = gql`
  mutation CreateResidentApplication($registrationId: String!) {
    createApplication(type: "resident-pass", registrationId: $registrationId) {
      success
      error
    }
  }
`;

export default function CreateRegistration(): ReactElement {
  const [redirect, setRedirect] = useState<string>('');
  const [
    doCreateApplication,
    {
      data: createApplicationData,
      loading: createApplicationLoading,
      error: createApplicationError,
    },
  ] = useMutation(CREATE_RESIDENT_APPLICATION, {
    fetchPolicy: 'network-only',
    onError: d => {
      console.log(d.message);
    },
    onCompleted: d => {
      console.log('created application');
    },
  });

  const [doCreateRegistration, { error }] = useMutation(CREATE_REGISTRATION, {
    fetchPolicy: 'no-cache',
    variables: {},
    onError: (e: any) => {
      console.log(e);
      if (process.env.REACT_APP_DEBUG === 'true') {
        setRedirect('/resident/get-passes/resident-pass/0');
      }
    },
    onCompleted: (d: any) => {
      if (d.createRegistration.success) {
        doCreateApplication({
          variables: {
            registrationId: d.createRegistration.data[0].registrationId,
          },
        });
        setRedirect(
          `/resident/get-passes/resident-pass/${d.createRegistration.data[0].registrationId}/pass-selection`,
        );
      }
    },
  });

  useQuery<GET_REGISTRATION_RES>(GET_REGISTRATION, {
    variables: {},
    fetchPolicy: 'no-cache',
    onError: e => {
      console.error(e);
      doCreateRegistration();
    },
    onCompleted: d => {
      const selectedReg = d.getRegistrationData.data?.find(
        // use only registrations that are still on the first step
        //
        // NOTE: currently a new registration is created at step 1,
        // then uppon completing the first step, the registration step is edited to 1 again.
        // This means that there is no way to diferentiate between a new registration and one that has completed the first step
        // Therefore, this means that we are starting a new registration each time we enter this page.
        // in the future we should continue a registration if and only if the first step has not yet been completed.
        r => r.registrationId && typeof r.stepNumber === 'number' && r.stepNumber < 1,
      );
      if (selectedReg) {
        setRedirect(
          `/resident/get-passes/resident-pass/${selectedReg.registrationId}/pass-selection`,
        );
      } else {
        doCreateRegistration();
      }
    },
  });

  if (redirect) return <Redirect to={redirect} />;
  if (error) return <div>{`Error! ${error.message}`}</div>;
  return <div>Loading ...</div>;
}
