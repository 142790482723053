import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { backendResponse } from '../../common_lib_front/types/backendResponse';

export const GET_HOST_INFO = gql`
  query GetHostInfoData($registrationId: String!) {
    getHostInfoData(registrationId: $registrationId) {
      success
      error
      data {
        email
        firstName
        lastName
        companyName
        hostInfoId
        phoneNumber
        address
        city
        state
        zipCode
        ownerPin
      }
    }
  }
`;
export type GET_HOST_INFO_VARS = {
  registrationId: string;
};
export type GET_HOST_INFO_DATA = {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  hostInfoId: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  ownerPin: string;
};
export type GET_HOST_INFO_RES = {
  getHostInfoData?: backendResponse<GET_HOST_INFO_DATA>;
};

type useGetHostInfoOptions = QueryHookOptions<GET_HOST_INFO_RES, GET_HOST_INFO_VARS>;
type useGetHostInfoResult = QueryResult<GET_HOST_INFO_RES, GET_HOST_INFO_VARS> & {
  result?: GET_HOST_INFO_DATA;
};
export const useGetHostInfo = (options: useGetHostInfoOptions): useGetHostInfoResult => {
  const res = useQuery<GET_HOST_INFO_RES, GET_HOST_INFO_VARS>(GET_HOST_INFO, options);
  return useMemo(
    () => ({
      ...res,
      result: res.data?.getHostInfoData?.data || undefined,
    }),
    [res],
  );
};
