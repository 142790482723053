import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const HIDE_INVITES = gql`
  mutation HideInvites($registrationIds: [String]) {
    hideMultipleInvites(registrationIds: $registrationIds) {
      error
      success
    }
  }
`;
type HIDE_INVITES_VARS = {
  registrationIds: string[];
};
type HIDE_INVITES_RES = {
  hideMultipleInvites: backendResponse<undefined>;
};

export function hideInvites(variables: HIDE_INVITES_VARS) {
  return backendClient.mutate<HIDE_INVITES_RES, HIDE_INVITES_VARS>({
    mutation: HIDE_INVITES,
    variables,
  });
}
