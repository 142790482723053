import React, { ReactElement, useContext, useMemo } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { portalOptions } from '../../common_lib_front/types/portalOptions';
// type for base level config
type textConfig = {
  communityTitle: string | ReactElement;
  communitySubTitle: string | ReactElement;
  alertText: string | ReactElement;
};
// type for second level of object
type communityTextConfigOptions = {
  [property in portalOptions | 'default']?: Partial<textConfig>;
};
// type for default option of object
interface textsDefaultOption extends communityTextConfigOptions {
  default: textConfig; // require only default option
}
// type for confing object
type createAccountTextsType = {
  default: textsDefaultOption; // require only default option
  [communityId: string]: Partial<communityTextConfigOptions> | undefined;
};

export default function useCreateAccountTexts(
  role: portalOptions | 'default' = 'default', // should use portal options
): textConfig {
  const { communityId, name } = useContext(CommunityContext);
  return useMemo((): textConfig => {
    // ============== config object ============
    const createAccountTexts: createAccountTextsType = {
      default: {
        default: {
          communityTitle: `Welcome to ${name} ${role.charAt(0).toUpperCase()}${role.slice(
            1,
          )} Registration Portal`,
          communitySubTitle: `Registration is required for all ${role}s entering the community.  Please create an account below to begin your registration and receive your community access pass.`,
          alertText: '',
        },
      },
      palmettodunes: {
        default: {
          alertText:
            'IMPORTANT NOTICE: Please create new account login information for this year because this is a new system from what has been used previously.Please also check the emails you have received regarding the registration process to find the community code you need to create this account.',
          communitySubTitle:
            'Registration is required for all individuals entering the community.  Please create an account below to begin your registration and receive your community access pass.',
        },
      },
      grandcomplex: {
        resident: {
          communityTitle: 'Welcome to the Community Owner Portal',
          communitySubTitle:
            "Please create an account below using your unique community code. If you don't know your community code, please contact your community administrator or visit your community's website for instructions on how to receive your code.",
        },
        guest: {
          communityTitle: 'Welcome to the Grand Complex Guest Parking Terminal',
          communitySubTitle: (
            <div>
              A community pass is required to use the Grand Complex parking garage. Please
              follow the instructions below to purchase your community pass.
              <b>
                Please note that you are only allowed to park one vehicle in the parking
                garage during your stay.
              </b>
              Your host should have previously emailed you your login information. If you
              have not received or have lost your login information, please contact your
              host. The price per pass is $10.00.
            </div>
          ),
        },
      },
      watercolor: {
        resident: {
          communityTitle: 'Welcome to the WaterColor Community Host Portal',
          communitySubTitle: ' ',
        },
        guest: {
          communityTitle: 'Welcome to the WaterColor Community Guest Registration Portal',
          communitySubTitle: (
            <div>
              Registration is required for all guests entering the community. If
              you&apos;ve previously created an account, please login below to register a
              new visit and receive your community access pass.
            </div>
          ),
        },
      },
    };
    // ============== end config object ============
    const getAttribute = (attr: keyof textConfig): string | ReactElement => {
      const commTexts = createAccountTexts[communityId];
      if (commTexts) {
        // check if config exists for community
        const roleFinal = commTexts[role]?.[attr];
        if (roleFinal) {
          // check if attribute exists for community and role
          return roleFinal;
        }
        const communityDefaultfinal = commTexts[role]?.[attr];
        if (communityDefaultfinal) {
          // check if attribute exists for community default
          return communityDefaultfinal;
        }
      }
      const defaultRoleAttr = createAccountTexts.default[role]?.[attr];
      if (defaultRoleAttr) {
        // check if attribute exists for default community speciffic role
        return defaultRoleAttr;
      }
      // defaut and default return
      return createAccountTexts.default.default[attr];
    };

    return {
      communityTitle: getAttribute('communityTitle'),
      communitySubTitle: getAttribute('communitySubTitle'),
      alertText: getAttribute('alertText'),
    };
  }, [communityId, role, name]);
}
