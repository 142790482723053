import { useMutation } from '@apollo/client';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import GenericAlert from '../../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { PopUp } from '../../../../../common_lib_front/components/popUp/popUp';
import SearchSelector from '../../../../../common_lib_front/components/searchSelector/searchSelector';
import useOrderPrice from '../../../../../common_lib_front/hooks/useOrderPrice';
import {
  formatDateForInput,
  formatDateForInputUTC,
} from '../../../../../common_lib_front/utilities/formatDate';
import MyPassGrid from '../../../../../components/myPassGrid/myPassGrid';
import MyPassesTab from '../../../../../components/myPasssesTab/myPassesTab';
import { useGetHostRentals } from '../../../../../hooks/useGetHostRentals';
import useGetMyGuests, { invitedGuestInfo } from '../../../../../hooks/useGetMyguests';
import useGuestPasses from '../../../../../hooks/useGuestPasses';
import { createPaymentSession } from '../../../../../hooks/usePaymentSession';
import style from './editGuest.module.css';
import {
  EDIT_COMMUNITY_RENTAL,
  EDIT_COMMUNITY_RENTAL_VARS,
  EDIT_COMMUNITY_RENTAL_RES,
  EDIT_INVITED_GUEST,
  EDIT_INVITED_GUEST_RES,
  EDIT_INVITED_GUEST_VARS,
} from './editGuestRequests';

export default function EditGuest(): ReactElement {
  const history = useHistory();

  const { registrationId } = useParams<{ registrationId: string }>();
  const { data } = useGetMyGuests({
    registrationIds: [registrationId],
    pageSize: 0,
    pageNumber: 0,
  });
  const guest = useMemo(
    () => data?.find(g => g.registration?.registrationId === registrationId),
    [data, registrationId],
  );

  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const { data: myRentals } = useGetHostRentals({
    variables: {
      hostInfoId: null,
      approvedOnly: true,
    },
  });

  const [guestChangeData, setGuestChangeData] = useState<
    Partial<invitedGuestInfo['guestInfo']>
  >({});
  // update guest info if guest changes
  useEffect(() => {
    setGuestChangeData(JSON.parse(JSON.stringify(guest?.guestInfo || {})));
  }, [guest]);
  // helper to set guest info
  const guestChangeDataHelper = (obj: Partial<invitedGuestInfo['guestInfo']>) =>
    setGuestChangeData({ ...guestChangeData, ...obj });
  // community rental info
  const [rentalChangeData, setRentalChangeData] =
    useState<Partial<invitedGuestInfo['communityRental']>>();
  // update rental info if guest changes
  useEffect(() => {
    setRentalChangeData(JSON.parse(JSON.stringify(guest?.communityRental || {})));
  }, [guest]);
  const rentalChangeDataHelper = (
    obj: Partial<Required<invitedGuestInfo>['communityRental']>,
  ) => {
    const res = {
      address: obj.address || rentalChangeData?.address,
      arrivalDate: obj.arrivalDate || rentalChangeData?.arrivalDate,
      departureDate: obj.departureDate || rentalChangeData?.departureDate,
    };
    setRentalChangeData(res);
  };
    
  // edit community rental
  const [editGuestRental] = useMutation<
    EDIT_COMMUNITY_RENTAL_RES,
    EDIT_COMMUNITY_RENTAL_VARS
  >(EDIT_COMMUNITY_RENTAL, {
    onError: () => {
      setAlert('Something went wrong. Changes may not have been saved.');
      setAlertColor('red');
    },
    onCompleted: d => {
      const err = d.editCommunityRental.error;
      if (err) {
        setAlert(err);
        setAlertColor('red');
      }
    },
  });
  
  console.log('guest?.passes====>', guest?.passes);
  
  const { getOrderPriceAsync } = useOrderPrice(guest?.passes?.map(({ startDate, endDate, ...rest }) => rest) || []);

  // edit guest info
  const [editGuest] = useMutation<EDIT_INVITED_GUEST_RES, EDIT_INVITED_GUEST_VARS>(
    EDIT_INVITED_GUEST,
    {
      onError: () => {
        setAlert('Something went wrong. Changes may not have been saved.');
        setAlertColor('red');
      },
      // call edit on all relevant rentals and passes
      onCompleted: d => {
        const err = d.editInvitedGuest.error;
        if (err) {
          setAlert(err);
          setAlertColor('red');
          return;
        }
        if (
          guest &&
          (
            ['address', 'arrivalDate', 'departureDate'] as Array<
              keyof invitedGuestInfo['communityRental']
            >
          ).some(
            k =>
              rentalChangeData?.[k] && rentalChangeData[k] !== guest?.communityRental[k],
          )
        ) {

          return editGuestRental({
            variables: {
              communityRentalId: guest.communityRental.communityRentalId,
              arrivalDate:
                rentalChangeData?.arrivalDate || guest.communityRental.arrivalDate,
              departureDate:
                rentalChangeData?.departureDate || guest.communityRental.departureDate,
              address: rentalChangeData?.address || guest.communityRental.address,
            },
          })
            .then(async res => {
              if (res.data?.editCommunityRental.error) {
                throw Error(res.data.editCommunityRental.error);
              }
              
              const price = await getOrderPriceAsync();
              console.log('price====>', price);
              
              if(price > 0) {
                const paymentSessionId = ( 
                  await createPaymentSession({
                    newPaymentSession: {
                      registrationIds: [guest.registration?.registrationId],
                    },
                  })
                ).data?.createPaymentSession.data?.paymentSessionId;
                const qp = new URLSearchParams();
                console.log('paymentSessionId===>', paymentSessionId);

                qp.set('paymentSessionId', paymentSessionId || '');
                history.push(`/invite-guest/payment?${qp.toString()}`);
              } else {
                history.replace('/invite-guest/guest-list');
              }
            })
            .catch(() => {
              setAlert('Something went wrong. Changes may not have been saved');
              setAlertColor('red');
            });
        } else {
          history.replace('/invite-guest/guest-list');
        }
      },
    },
  );

  const getAddressValidation = async (partial: string) =>
    myRentals?.getRentalsByHost.data
      ?.filter(rental => rental.address.includes(partial))
      .map(rental => rental.address) || [];

  const submitHandler = async () => {
    if (guest?.guestInfo?.userId && guest?.registration?.registrationId) {
      editGuest({
        variables: {
          newGuestInfo: {
            firstName: guestChangeData?.firstName || guest.guestInfo.firstName,
            lastName: guestChangeData?.lastName || guest.guestInfo.lastName,
            email: guestChangeData?.email || guest.guestInfo?.email || '',
            phoneNumber: guestChangeData?.phoneNumber || guest.guestInfo.phoneNumber,
          },
          registrationId: guest.registration?.registrationId,
        },
      });
    }
  };

  const { allPasses, activeTab, setActiveTab } = useGuestPasses({
    registrationId: registrationId,
  });

  const { t } = useTranslation();

  return (
    <PopUp largeSize close={() => history.goBack()} title="Edit Reservation">
      <div>
        <div className={style.formParent}>
          <form
            onSubmit={e => {
              e.preventDefault();
              submitHandler();
            }}
            className={style.formBox}
          >
            <GenericAlert title={alert} color={alertColor} hidden={!alert} />
            <div className={style.box}>
              <div className={style.header}>Guest Information</div>
              <div className={style.flexBox}>
                <div className={style.boxLeft}>
                  <div className={style.shortInputBox}>
                    <div className={style.inputShort}>
                      <InputField
                        htmlFor="f-name"
                        labelTitle="First Name"
                        smallSize
                        highlightOnFocus
                        // inputPlaceholder={guest?.guestInfo?.firstName}
                        inputValue={guestChangeData?.firstName || ''}
                        changeHandler={e =>
                          guestChangeDataHelper({ firstName: e.target.value })
                        }
                      />
                    </div>
                    <div className={style.inputShort}>
                      <InputField
                        htmlFor="l-name"
                        labelTitle="Last Name"
                        smallSize
                        highlightOnFocus
                        // inputPlaceholder={guest?.guestInfo?.lastName}
                        inputValue={guestChangeData?.lastName || ''}
                        changeHandler={e =>
                          guestChangeDataHelper({ lastName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <InputField
                    htmlFor="email"
                    labelTitle="Email Address"
                    smallSize
                    highlightOnFocus
                    inputValue={guestChangeData?.email}
                    changeHandler={e => guestChangeDataHelper({ email: e.target.value })}
                  />
                  <PhoneInputWrapper
                    inputTitle="Phone Number"
                    changeHandler={val => {
                      guestChangeDataHelper({ phoneNumber: val });
                    }}
                    smallStandardInput
                    value={guestChangeData?.phoneNumber}
                  />
                </div>
                <div className={style.boxRight}>
                  {rentalChangeData && (
                    <div className={style.rentalItem}>
                      <div className={style.rentalInputBox}>
                        <div className={style.searchInput}>
                          <SearchSelector
                            htmlFor={'address'}
                            title={'Rental Address'}
                            value={rentalChangeData.address || ''}
                            changeHandler={val =>
                              rentalChangeDataHelper({ address: val })
                            }
                            fetch={getAddressValidation}
                          />
                        </div>
                      </div>
                      <InputField
                        htmlFor={'arrival-date'}
                        labelTitle="Arrival Date"
                        inputType="date"
                        smallSize
                        highlightOnFocus
                        max={(() => {
                          const boundDate = new Date(
                            rentalChangeData?.departureDate || '',
                          );
                          boundDate.setDate(boundDate.getDate() - 1);
                          return formatDateForInput(boundDate);
                        })()}
                        inputValue={formatDateForInputUTC(rentalChangeData.arrivalDate)}
                        changeHandler={e =>
                          rentalChangeDataHelper({ arrivalDate: e.target.value })
                        }
                      />
                      <InputField
                        htmlFor={'departure-date'}
                        labelTitle="Departure Date"
                        inputType="date"
                        smallSize
                        highlightOnFocus
                        min={(() => {
                          const boundDate = new Date(rentalChangeData.arrivalDate || '');
                          boundDate.setDate(boundDate.getDate() + 1);
                          return formatDateForInput(boundDate);
                        })()}
                        inputValue={formatDateForInputUTC(rentalChangeData.departureDate)}
                        changeHandler={e =>
                          rentalChangeDataHelper({ departureDate: e.target.value })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={style.btnBox}>
              <div className={style.btn}>
                <GenericButton
                  title="Save Changes"
                  size="medium"
                  color="blue"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
        <div className={style.gridContainer}>
          <div className={`${style.box__flexBox} white `}>
            <div className={style.box__tabBox}>
              <MyPassesTab
                title={t('Vehicle Passes')}
                clickHandler={() => {
                  setActiveTab('Current');
                }}
                active={activeTab === 'Current'}
                highlightOnFocus={activeTab === 'Current'}
              />
              <MyPassesTab
                title={t('Amenity Passes')}
                clickHandler={() => {
                  setActiveTab('amenity');
                }}
                active={activeTab === 'amenity'}
                highlightOnFocus={activeTab === 'amenity'}
              />
            </div>
            <div className={`${style.box__innerBox} white `}>
              <MyPassGrid data={allPasses} activeTab={activeTab} />
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  );
}
