import { gql } from '@apollo/client';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';

export const ADD_PASS = gql`
  mutation Mutation($passInfoId: String!, $registrationId: String!) {
    addPassToInvite(passInfoId: $passInfoId, registrationId: $registrationId) {
      data {
        passes {
          passId
        }
      }
      success
      error
    }
  }
`;
export type ADD_PASS_VARS = {
  passInfoId: string;
  registrationId: string;
};
export type ADD_PASS_RES = {
  addPassToInvite: backendResponse<{
    passes: {
      passId: string;
    }[];
  }[]>;
};
