import { gql, useMutation } from '@apollo/client';
import { ReactElement, useEffect } from 'react';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';
import { RecipientInfo } from '../../../../../components/inviteGuestForm/inviteGuestForm';
import InviteGuestFormContainer from '../../../../../components/inviteGuestForm/inviteGuestFormContainer';
import { useGetHostRentals } from '../../../../../hooks/useGetHostRentals';
import { IGPassInfo } from '../../inviteGuestRequests';
import { InviteGuestDispatchType } from '../../useInviteGuest';

const INVITE = gql`
  mutation FastPassInvite($fastPassInfos: [FastPassInput!]!) {
    fastPassInvite(fastPassInfos: $fastPassInfos) {
      success
      error
      data {
        passes {
          paid
          passId
        }
        registration {
          registrationId
        }
      }
    }
  }
`;
type INVITE_VARS = {
  fastPassInfos: {
    guestInfo: {
      email?: string;
      phoneNumber?: string;
      firtName?: string;
      lastName?: string;
    };
    rentalInfo: {
      address: string;
      arrivalDate: string;
      departureDate: string;
    };
    passes: {
      passInfoId: string;
      endDate: string;
      startDate: string;
    }[];
  }[];
};
type INVITE_RES = {
  fastPassInvite: backendResponse<
    {
      passes: {
        paid: boolean;
        passId: string;
      }[];
      registration: {
        registrationId: string;
      };
    }[]
  >;
};

type FastPassProps = {
  data: RecipientInfo[];
  editData: InviteGuestDispatchType;
  fastPassSelected: boolean;
  setFastPassSelected: any;
  passInfoData: IGPassInfo[];
};
export default function FastPass(props: FastPassProps): ReactElement {
  const { data, editData, fastPassSelected, setFastPassSelected } = props;

  const [doInvite, { error, data: queryResponse, loading }] = useMutation<
    INVITE_RES,
    INVITE_VARS
  >(INVITE);

  // ensure that all fast passes are payed by host
  useEffect(() => {
    data.forEach((ri, idx) => {
      if (!ri.willPayForPass) {
        editData({
          type: 'editRecipient',
          payload: {
            recipientIdx: idx,
            recipientData: {
              willPayForPass: true,
            },
          },
        });
      }
    });
  }, [data, editData]);

  const { data: hostRentals } = useGetHostRentals({
    variables: {
      hostInfoId: null,
      approvedOnly: true,
    },
  });

  return (
    <InviteGuestFormContainer
      data={data}
      editData={editData}
      fastPassSelected={fastPassSelected}
      setFastPassSelected={setFastPassSelected}
      maxPasses={150}
      loading={loading}
      targetPortal="guest"
      disabled={loading}
      formConfig={{
        firstName: { required: false, optional: true },
        lastName: { required: false, optional: true },
        companyName: { hidden: true },
        email: { required: false, optional: true },
        phoneNumber: { required: false, optional: true },
        address: {
          label: "Rental Address of Guest's Stay",
        },
        willPayForPass: {
          hidden: true,
        },
        passType: {},
      }}
      formTextConfig={{
        recipientTag: 'Pass',
        recipientMessage:
          'To issue a fast pass, please select from your approved rentals list and enter an arrival date and departure date.',
      }}
      validators={[
        {
          validIf: ri =>
            !!hostRentals?.getRentalsByHost.data?.find(a => a.address === ri.address),
          message: 'Please select an approved address from the drop down for all passes.',
        },
      ]}
      submitHandler={async () => {
        const fastPassInfos = data.map(ri => ({
          guestInfo: {
            email: ri.email,
            phoneNumber: ri.phoneNumber,
            firstName: ri.firstName,
            lastName: ri.lastName,
          },
          rentalInfo: {
            address: ri.address,
            arrivalDate: ri.arrivalDate,
            departureDate: ri.departureDate,
          },
          passes: ri.passes.map(pass => ({
            passInfoId: pass.passInfoId,
            startDate: pass.startDate,
            endDate: pass.endDate,
          })),
        }));
        return doInvite({
          variables: {
            fastPassInfos,
          },
        }).then(res => {
          res.data?.fastPassInvite.data?.forEach((recipient, recipientIdx) => {
            editData({
              type: 'editRecipient',
              payload: {
                recipientIdx,
                recipientData: {
                  registrationId: recipient.registration.registrationId,
                },
              },
            });
            recipient.passes.forEach((rental, rentalIdx) => {
              editData({
                type: 'editRental',
                payload: {
                  recipientIdx,
                  rentalIdx,
                  passData: {
                    passId: rental.passId,
                  },
                },
              });
            });
          });
          return {
            data: res.data?.fastPassInvite ? [res.data.fastPassInvite] : undefined,
          };
        });
      }}
      error={
        queryResponse?.fastPassInvite.error ||
        (error?.message ? 'Something went wrong. Please try again later.' : '')
      }
    />
  );
}
