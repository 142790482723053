import { ApolloError, gql, useMutation } from '@apollo/client';
import React, { ChangeEvent, ReactElement, useState, useContext, useRef } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import SearchSelector from '../../../common_lib_front/components/searchSelector/searchSelector';
import { ADDRESS_VALIDATION } from '../../../common_lib_front/hooks/useAddressValidation';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import UserInfo, { newUserInfo } from '../../../common_lib_front/types/userInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

import { checkNull } from '../../../common_lib_front/utilities/BackendAPI';
import store from '../../../common_lib_front/utilities/store';
import EditProfileTemplate from '../../../components/editProfileTemplate/editProfileTemplate';
import {
  HostInfoForm,
  IHostInfoFormApi,
} from '../../../components/hostInfoForm/hostInfoForm';
import useGetUserInfo from '../../../hooks/useGetUserInfo';
import style from './myProfile.module.css';
import UseMyProfile from './useMyProfile';
export const EDIT_USER_INFO = gql`
  mutation editResidentProfile($newProfileInfo: UpdateResidentProfileInput!) {
    editResidentProfile(newProfileInfo: $newProfileInfo) {
      success
      error
    }
  }
`;

export const EDIT_USER_HOST_INFO = gql`
  mutation EditUserInfo(
    $zipCode: String!
    $city: String!
    $state: String!
    $country: String!
    $address: String!
    $phoneNumber: String!
    $lastName: String!
    $firstName: String!
  ) {
    editUserInfo(
      zipCode: $zipCode
      city: $city
      state: $state
      country: $country
      address: $address
      phoneNumber: $phoneNumber
      lastName: $lastName
      firstName: $firstName
    ) {
      success
      error
    }
  }
`;
export const EDIT_USER_PROPERTY_INFO = gql`
  mutation editResidentProperty(
    $propertyId: String!
    $newPropertyInfo: ResidentPropertyUpdateInput!
  ) {
    editResidentProperty(propertyId: $propertyId, newPropertyInfo: $newPropertyInfo) {
      success
      error
    }
  }
`;

type ADDRESS_VALIDATION_VARS = {
  communitySymbol: string;
  address: string;
};

type ADDRESS_VALIDATION_RES = {
  addressValidation: backendResponse<
    {
      address: string;
    }[]
  >;
};

export default function MyProfile(): ReactElement {
  const [userInfo, setUserInfo] = useState<UserInfo>(newUserInfo({}));
  const [aditionalAddress, setAditionalAddress] = useState('');
  const [newAddressData, setNewAddressData] = useState<Array<any>>([]);
  const [addressList, setAddressList] = useState<Array<any>>([]);

  const { communityId } = useContext(CommunityContext);
  const hostFormApi = useRef<IHostInfoFormApi | null>(null);

  const [alert, setAlert] = useState<string>('');
  const {
    role,
    residentInfo,
    setResidentInfo,
    multiSelect,
    setMultiSelect,
    deleteProperty,
    addProperty,
  } = UseMyProfile();
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const hiddenAlert = () => {
    setTimeout(() => {
      setAlert('');
    }, 4000);
  };

  const onError = (e: ApolloError) => {
    setAlert(e.message);
    setAlertColor('red');
  };
  const onCompleted = (d: any) => {
    if (!d.getUserInfo.success) {
      setAlert(d.getUserInfo.error);
      setAlertColor('red');
    } else {
      setUserInfo(newUserInfo(d.getUserInfo.data));
    }
  };
  const { userInfo: fetchedUserInfo, refetch: refetchUserInfo } = useGetUserInfo(
    onCompleted,
    onError,
  );

  const [doSubmit] = useMutation(EDIT_USER_INFO, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      refetchUserInfo();
      if (d.editResidentProfile.success) {
        setAlert('Changes saved successfully');
        setAlertColor('green');
        hiddenAlert();
        if (userInfo.firstName !== '' || userInfo.lastName !== '') {
          store.dispatch({
            type: 'UPDATE_PROFILE',
            payload: userInfo,
          });
        }
      } else {
        setAlert(d.editResidentProfile.error);
        setAlertColor('red');
      }
    },
  });

  const multiAddressHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setMultiSelect(true);
    } else {
      setMultiSelect(false);
    }
  };
  const deletePropertyCall = (propertyId: string) => {
    if (residentInfo?.communityAddresses?.length > 0) {
      const add = residentInfo.communityAddresses.filter((ele: any) => {
        return ele.propertyId !== propertyId && ele.street !== residentInfo.street;
      });
      setResidentInfo({
        ...residentInfo,
        communityAddresses: add,
      });
      if (propertyId) {
        deleteProperty(propertyId);
      }
    }
  };
  const [doSubmitHost] = useMutation(EDIT_USER_HOST_INFO, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      refetchUserInfo();
      if (d.editUserInfo.success) {
        setAlert('Changes saved successfully');
        setAlertColor('green');
        hiddenAlert();
        // if they are changing
        if (userInfo.firstName !== '' || userInfo.lastName !== '') {
          store.dispatch({
            type: 'UPDATE_PROFILE',
            payload: userInfo,
          });
        }
      } else {
        setAlert(d.editUserInfo.error);
        setAlertColor('red');
      }
    },
  });
  const [doSubmitProperty] = useMutation(EDIT_USER_PROPERTY_INFO, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      // refetchUserInfo();
      // refetchUserCommunityInfo();
      if (d.editResidentProperty.success) {
        setAlert('Changes saved successfully');
        setAlertColor('green');
        hiddenAlert();
        // if they are changing
        if (userInfo.firstName !== '' || userInfo.lastName !== '') {
          store.dispatch({
            type: 'UPDATE_PROFILE',
            payload: userInfo,
          });
        }
      } else {
        setAlert(d.editResidentProperty.error);
        setAlertColor('red');
      }
    },
  });
  const getResidentPropertyValues = () => {
    const newUserInfo: any = {};
    newUserInfo.state = residentInfo.state;
    newUserInfo.street = residentInfo.street;
    newUserInfo.unit = residentInfo.unit;
    newUserInfo.city = residentInfo.city;
    newUserInfo.zipCode = residentInfo.zipCode;
    return checkNull(newUserInfo);
  };
  const submitResidentProperty = () => {
    doSubmitProperty({
      variables: {
        propertyId: residentInfo.propertyId,
        newPropertyInfo: getResidentPropertyValues(),
      },
    });
  };
  const submitHostInfo = () => {
    doSubmitHost({
      variables: {
        ...userInfo,
      },
    });
  };
  const getResidentProfileValues = () => {
    const newUserInfo: any = {};
    newUserInfo.email == residentInfo.email;
    newUserInfo.firstName = residentInfo.firstName;
    newUserInfo.lastName = residentInfo.lastName;
    newUserInfo.phoneNumber = residentInfo.phoneNumber;
    newUserInfo.altPhoneNum = residentInfo.altPhoneNum;
    newUserInfo.mailingStreet = residentInfo.mailingStreet;
    newUserInfo.mailingUnit = residentInfo.mailingUnit;
    newUserInfo.mailingCity = residentInfo.mailingCity;
    newUserInfo.mailingState = residentInfo.mailingState;
    newUserInfo.mailingZipCode = residentInfo.mailingZipCode;
    newUserInfo.emergencyFirstName = residentInfo.emergencyFirstName;
    newUserInfo.emergencyLastName = residentInfo.emergencyLastName;
    newUserInfo.emergencyPhoneNumber = residentInfo.emergencyPhoneNumber;
    newUserInfo.emergencyEmail = residentInfo.emergencyEmail;
    newUserInfo.ownerPin = residentInfo.ownerPin;
    return checkNull(newUserInfo);
  };
  const submitResidentProfile = () => {
    doSubmit({
      variables: {
        newProfileInfo: getResidentProfileValues(),
      },
    });
  };
  const checkDisable = () => {
    const s = residentInfo.communityAddresses.filter(
      (el: any) => el.street != residentInfo.street,
    );
    if (!s.length) return false;
    else return true;
  };

  return (
    <EditProfileTemplate active="profile">
      <div className={`${style.subHeader} mainColor `}>
        <div className={`${style.circle} white `} />
        <h1 className={`${style.title} textColorWhite `}>My Profile</h1>
      </div>
      <div className={style.form}>
        <form
          className={style.innerForm}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (role === 'host') {
              hostFormApi.current?.submit().then(res => {
                if (res.errors) {
                  setAlert('Something went wrong. Your changes may not be saved.');
                  setAlertColor('red');
                } else if (res.data?.editOrInsertHostInfo.error) {
                  setAlert(res.data.editOrInsertHostInfo.error);
                  setAlertColor('red');
                } else {
                  setAlert('Your changes have been saved.');
                  setAlertColor('green');
                  hiddenAlert();
                }
              });
              // submitHostInfo();
              console.log('saving user info');
            } else {
              // check for the community address matching.
              let isValid = true;
              if (residentInfo.street) {
                if (addressList && addressList.length > 0) {
                  isValid =
                    addressList.filter(val => val.address === residentInfo.street)
                      .length === 0
                      ? false
                      : true;
                }
              }
              if (isValid) {
                setAlert('');
                submitResidentProfile();
                submitResidentProperty();
              } else {
                setAlert('Please enter correct community address');
                setAlertColor('red');
              }
            }
          }}
        >
          <GenericAlert color={alertColor} title={alert} hidden={!alert} />
          {role === 'host' ? (
            <div>
              {/* <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="f-name-inpt"
                    labelTitle="First Name"
                    inputType="text"
                    inputPlaceholder={fetchedUserInfo?.firstName}
                    inputValue={userInfo?.firstName}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({ ...userInfo, firstName: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="l-name-inpt"
                    labelTitle="Last Name"
                    inputType="text"
                    inputPlaceholder={fetchedUserInfo?.lastName}
                    inputValue={userInfo.lastName}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({ ...userInfo, lastName: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={style.inputLong}>
                <PhoneInputWrapper
                  standardInput
                  inputTitle="Phone Number"
                  value={userInfo.phoneNumber}
                  placeHolder={fetchedUserInfo?.phoneNumber}
                  changeHandler={(e: string) => {
                    setUserInfo({ ...userInfo, phoneNumber: e });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="address-inpt"
                    labelTitle="Primary Address"
                    inputType="text"
                    inputPlaceholder={fetchedUserInfo?.address}
                    inputValue={userInfo.address}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({ ...userInfo, address: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="city-inpt"
                    labelTitle="City"
                    inputType="text"
                    inputPlaceholder={fetchedUserInfo?.city}
                    inputValue={userInfo.city}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({ ...userInfo, city: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="state-inpt"
                    labelTitle="State"
                    inputType="text"
                    inputPlaceholder={fetchedUserInfo?.state}
                    inputValue={userInfo.state}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({ ...userInfo, state: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="zip-inpt"
                    labelTitle="Zip Code"
                    inputType="text"
                    formatCode="zip-strict"
                    inputPlaceholder={fetchedUserInfo?.zipCode}
                    inputValue={userInfo.zipCode}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({ ...userInfo, zipCode: e.target.value });
                    }}
                  />
                </div>
              </div> */}
              <HostInfoForm apiRef={hostFormApi} />
            </div>
          ) : (
            <div>
              <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="f-name-inpt"
                    labelTitle="First Name"
                    inputType="text"
                    inputPlaceholder={fetchedUserInfo?.firstName}
                    inputValue={residentInfo?.firstName}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({ ...residentInfo, firstName: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="l-name-inpt"
                    labelTitle="Last Name"
                    inputType="text"
                    inputPlaceholder={residentInfo?.lastName}
                    inputValue={residentInfo.lastName}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({ ...residentInfo, lastName: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="email-inpt"
                  disabled
                  labelTitle="Email"
                  inputType="text"
                  inputPlaceholder={residentInfo?.email}
                  inputValue={residentInfo.email}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, email: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <PhoneInputWrapper
                  standardInput
                  inputTitle="Phone Number (Primary)"
                  value={residentInfo.phoneNumber}
                  placeHolder={residentInfo?.phoneNumber}
                  changeHandler={(e: string) => {
                    setResidentInfo({ ...residentInfo, phoneNumber: e });
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <PhoneInputWrapper
                  standardInput
                  inputTitle="Phone Number (Alternate)"
                  value={residentInfo.altPhoneNum}
                  placeHolder={residentInfo?.altPhoneNum}
                  changeHandler={(e: string) => {
                    setResidentInfo({ ...residentInfo, altPhoneNum: e });
                  }}
                />
              </div>
              <h2> Community Address</h2>
              <div className={style.inputLong}>
                <div className={`${style.searchSelector}`}>
                  <span className={`${style.inputLong}`}>
                    <SearchSelector
                      htmlFor={'Street'}
                      title={'Street'}
                      value={residentInfo.street || ''}
                      changeHandler={(val: string) => {
                        setResidentInfo({ ...residentInfo, street: val });
                      }}
                      fetch={address =>
                        backendClient
                          .query<ADDRESS_VALIDATION_RES, ADDRESS_VALIDATION_VARS>({
                            query: ADDRESS_VALIDATION,
                            variables: {
                              address,
                              communitySymbol: communityId,
                            },
                          })
                          .then((d: any) => {
                            setAddressList(d.data.addressValidation.data);
                            return d.data.addressValidation.data.map(
                              (a: { address: string }) => a.address,
                            );
                          })
                          .catch(() => [] as string[])
                      }
                      selectHandler={d => {
                        const filtered = addressList.filter(
                          address => address.address === d,
                        );
                        if (filtered.length > 0) {
                          setResidentInfo({
                            ...residentInfo,
                            ...{
                              street: filtered[0]?.address,
                              city: filtered[0]?.city,
                              state: filtered[0]?.state,
                              zipCode: filtered[0]?.zip,
                            },
                          });
                        }
                      }}
                      required
                    />
                  </span>
                </div>
              </div>
              <div className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="unit-inpt"
                  labelTitle="Apt/Unit"
                  inputType="text"
                  formatCode="unit"
                  inputPlaceholder={residentInfo?.unit}
                  inputValue={residentInfo.unit}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, unit: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="city-inpt"
                    labelTitle="City"
                    disabled={true}
                    inputType="text"
                    inputPlaceholder={residentInfo?.city}
                    inputValue={residentInfo.city}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({ ...residentInfo, city: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="state-inpt"
                    labelTitle="State"
                    inputType="text"
                    disabled={true}
                    inputPlaceholder={residentInfo?.state}
                    inputValue={residentInfo.state}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({ ...residentInfo, state: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={style.inputShort}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="zip-inpt"
                  labelTitle="Zip Code"
                  inputType="text"
                  disabled={true}
                  formatCode="zip-strict"
                  inputPlaceholder={residentInfo?.zipCode}
                  inputValue={residentInfo.zipCode}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, zipCode: e.target.value });
                  }}
                />
              </div>
              <div className={style.inline_label}>
                <label>
                  Please check if you own multiple properties within the community and add
                  each property
                </label>
                <InputField
                  htmlFor="resident-multipleProperties-input"
                  inputType="checkbox"
                  checked={multiSelect}
                  disabled={checkDisable()}
                  changeHandler={multiAddressHandler}
                  autoComplete={false}
                />
              </div>
              {multiSelect && (
                <div className={style.inputBox}>
                  {/* <span className={style.inputFullLength}> */}
                  <div className={`${style.searchSelector}`}>
                    {/* <span className={`${style.inputLong}`}> */}
                    <SearchSelector
                      htmlFor={'street-add-new'}
                      title={'Street'}
                      value={aditionalAddress || ''}
                      changeHandler={(val: string) => {
                        setAditionalAddress(val);
                      }}
                      fetch={(address: string) => {
                        return backendClient
                          .query({
                            query: ADDRESS_VALIDATION,
                            variables: {
                              address: address,
                            },
                          })
                          .then((d: any) => {
                            setNewAddressData(d.data.addressValidation.data);
                            return d.data.addressValidation.data.map(
                              (a: { address: string }) => a.address,
                            );
                          });
                      }}
                      selectHandler={d => {
                        const filtered = newAddressData.filter(
                          address => address.address === d,
                        );
                        if (filtered.length) {
                          const insert = {
                            street: filtered[0]?.address,
                            unit: '',
                            city: filtered[0]?.city,
                            state: filtered[0]?.state,
                            zipCode: filtered[0]?.zip,
                          };
                          addProperty?.(insert);
                          setAditionalAddress('');
                        }
                      }}
                    />
                    {/* </span> */}
                  </div>
                  {/* </span> */}
                </div>
              )}
              {multiSelect && residentInfo.communityAddresses.length > 0 ? (
                <>
                  {residentInfo.communityAddresses
                    .filter((el: any) => el.street != residentInfo.street)
                    .map((address: any, key: any) => {
                      return (
                        <div className={style.inputBox} key={key}>
                          {/* <span className={style.inputLong}> */}
                          <InputField
                            closedInput
                            htmlFor={`add-street-${key + 2}`}
                            labelTitle={`Property #${key + 2}`}
                            inputValue={address.street || ''}
                            disabled={true}
                            autoComplete={false}
                          />
                          <a
                            href="javascript:void(0);"
                            onClick={() => deletePropertyCall(address.propertyId)}
                          >
                            delete
                          </a>
                          {/* </span> */}
                        </div>
                      );
                    })}
                </>
              ) : null}
              <h2> Mailing Address</h2>
              <div className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="mailingStreet-inpt"
                  labelTitle="Street Address"
                  inputType="text"
                  formatCode="mailingStreet"
                  inputPlaceholder={residentInfo?.mailingStreet}
                  inputValue={residentInfo.mailingStreet}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, mailingStreet: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="mailingUnit-inpt"
                  labelTitle="Apt/Unit"
                  inputType="text"
                  formatCode="mailingUnit"
                  inputPlaceholder={residentInfo?.mailingUnit}
                  inputValue={residentInfo.mailingUnit}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, mailingUnit: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="mailing-city-inpt"
                    labelTitle="City"
                    inputType="text"
                    inputPlaceholder={residentInfo?.mailingCity}
                    inputValue={residentInfo.mailingCity}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({ ...residentInfo, mailingCity: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="mailingState-inpt"
                    labelTitle="State"
                    inputType="text"
                    inputPlaceholder={residentInfo?.mailingState}
                    inputValue={residentInfo.mailingState}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({ ...residentInfo, mailingState: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={style.inputShort}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="mailingZipCode-inpt"
                  labelTitle="Zip Code"
                  inputType="text"
                  formatCode="zip-strict"
                  inputPlaceholder={residentInfo?.mailingZipCode}
                  inputValue={residentInfo.mailingZipCode}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, mailingZipCode: e.target.value });
                  }}
                />
              </div>
              <h2> Emergency Contact</h2>
              <div className={style.inputBox}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="e-f-name-inpt"
                    labelTitle="First Name"
                    inputType="text"
                    inputPlaceholder={residentInfo?.emergencyFirstName}
                    inputValue={residentInfo?.emergencyFirstName}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({
                        ...residentInfo,
                        emergencyFirstName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="e-l-name-inpt"
                    labelTitle="Last Name"
                    inputType="text"
                    inputPlaceholder={residentInfo?.emergencyLastName}
                    inputValue={residentInfo.emergencyLastName}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setResidentInfo({
                        ...residentInfo,
                        emergencyLastName: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="e-email-inpt"
                  labelTitle="Email"
                  inputType="text"
                  inputPlaceholder={residentInfo?.emergencyEmail}
                  inputValue={residentInfo.emergencyEmail}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, emergencyEmail: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <PhoneInputWrapper
                  standardInput
                  inputTitle="Phone Number"
                  value={residentInfo.emergencyPhoneNumber}
                  placeHolder={residentInfo?.emergencyPhoneNumber}
                  changeHandler={(e: string) => {
                    setResidentInfo({ ...residentInfo, emergencyPhoneNumber: e });
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="e-verification-inpt"
                  labelTitle="Owner Verification Password / Pin"
                  inputType="text"
                  inputPlaceholder={residentInfo?.ownerPin}
                  inputValue={residentInfo.ownerPin}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setResidentInfo({ ...residentInfo, ownerPin: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
          <div className={style.btnBox}>
            <div className={style.saveBtn}>
              <GenericButton
                color="blue"
                size="large"
                type="submit"
                title="Save Changes"
              />
            </div>
          </div>
        </form>
      </div>
    </EditProfileTemplate>
  );
}
