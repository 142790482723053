import React, { ReactElement, useContext } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import {
  newRentalUnitInfo,
  rentalUnitInfo,
} from '../../../common_lib_front/types/rentalUnitInfo';
import FormCollection from '../../../components/formCollection/formCollection';
import RentalRegistrationNav from '../../../components/rantalRegistrationNav/rentalRegistrationNav';
import RentalUnitForm from '../../../components/rentalUnitForm/rentalUnitForm';
import style from './rentalInformation.module.css';
import useRentalInformation from './useRentalInformation';

export default function RentalInformation(): ReactElement {
  const {
    rentals,
    setRentals,
    submitHandler,
    setCopyIdx,
    doCopy,
    fetchAddressValidation,
    searchSelectorHandler,
    error,
    doDeleteRental,
    copyIdx,
  } = useRentalInformation();

  const { communityId } = useContext(CommunityContext);

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RentalRegistrationNav
          title={
            communityId === 'sipoa'
              ? 'Step 1: Register Your Rental Property'
              : 'Step 1: Register Your Short-Term Rental Property'
          }
          subtitle={
            <p>
              Please register all the addresses of the rental units that you own.
              <br />
              {communityId === 'sipoa' ? null : (
                <em>
                  To view rentals you have previously registered go to the My Rentals
                  page.
                </em>
              )}
            </p>
          }
          stepNum={1}
        />
        <form
          className={style.form}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <GenericAlert title={error} hidden={!error} color="red" />
          <div className={style.flexBox}>
            <div className={style.boxLeft}>
              <FormCollection<rentalUnitInfo>
                data={rentals}
                setData={setRentals}
                newElement={(data?: any) => newRentalUnitInfo(data)}
                min={1}
                /* eslint-disable-next-line react/display-name */
                mapper={(removeHandler, updateHandler) => (elem, idx: number) =>
                  (
                    <RentalUnitForm
                      key={idx}
                      showCopyButton
                      data={elem}
                      idx={idx}
                      remove={() => {
                        removeHandler(idx);
                        if (elem.rentalUnitId) {
                          doDeleteRental(elem.rentalUnitId);
                        }
                      }}
                      update={(obj: any) => updateHandler(idx, obj)}
                      copyHandler={setCopyIdx}
                      pasteHandler={doCopy}
                      fetchAddressValidation={fetchAddressValidation}
                      searchSelectorHandler={(val: string) =>
                        searchSelectorHandler(idx, val)
                      }
                      isCopied={idx === copyIdx}
                    />
                  )}
              />
            </div>
            <div className={`${style.boxRight} ${style.hidden}`}>
              <div className={style.infoBox}>
                {rentals.map((rental: any, idx: number) => (
                  <div className={style.tableContainer} key={idx}>
                    <h6 className={`${style.title} textColorDark `}>
                      {idx === undefined ? '' : ` ${idx + 1}.`}
                      Transportation Pass
                    </h6>
                    <div className={style.tableContainer}>
                      <table className={style.tableBox}>
                        <thead>
                          <tr>
                            <th className={`${style.tdBox} textColorSuperDark `}>
                              Start Date
                            </th>
                            <th className={`${style.tdBox} textColorSuperDark `}>
                              Valid Through
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={`${style.tdBoxSpace} textColorMain `}>
                              Tue, Feb 16, 2021
                            </td>
                            <td className={`${style.tdBoxSpace} textColorMain `}>
                              Tue, Feb 16, 2021
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <table className={style.tableBelow}>
                      <tr>
                        <th>Subtotal</th>
                        <td>$150</td>
                      </tr>
                      <tr>
                        <th>Platform Fee</th>
                        <td>$15</td>
                      </tr>
                      <tr>
                        <th>Credit Card Fee</th>
                        <td>2.99%</td>
                      </tr>
                    </table>
                  </div>
                ))}
                <div className={style.totalBox}>
                  <h1 className={`${style.total}`}>
                    <h4 className={`${style.textLeft} textColorDark `}>Total</h4>
                    <h4 className={`${style.textRight} textColorDark `}>$300.00</h4>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className={style.btn}>
            <GenericButton size="large" color="blue" title="Continue" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
