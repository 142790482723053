import { Alert, Snackbar } from '@mui/material';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PopUp } from '../popUp/popUp';
import { ContactDataFormWrapper } from './contactDataFormWrapper';
import { ContactInputs } from './contactInputs';
import {
  contactTypeToFamilyMemberInput,
  FamilyFriendsApiWrapper,
  familyMemberResponseToContactType,
} from './familyFriendsApiWrapper';

export type CFPprops = {
  familyMemberId?: string;
};

export function ContactFormPopup({
  familyMemberId: propFMID,
}: CFPprops): React.ReactElement {
  const { memberId: paramFMID } = useParams<{ memberId?: string }>();
  const history = useHistory();
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  console.log(snackbarMessage);

  const safeFamilyMemberId = useMemo(() => {
    return propFMID || paramFMID;
  }, [propFMID, paramFMID]);

  return (
    <PopUp
      title={
        safeFamilyMemberId
          ? 'Edit Family Member or Friend'
          : 'Add Family Member or Friend'
      }
      isOpen={true}
      close={() => {
        history.goBack();
      }}
    >
      {/* {snackbarMessage && <Alert severity="error">{snackbarMessage}</Alert>} */}
      <FamilyFriendsApiWrapper
        familyMemberId={safeFamilyMemberId}
        render={({ getQuery, editMutation, addMutation }) => (
          <ContactDataFormWrapper
            reportError={message => setSnackbarMessage(message)}
            initialData={
              getQuery.data?.getFamilyMember.data?.[0] &&
              familyMemberResponseToContactType(getQuery.data?.getFamilyMember.data?.[0])
            }
            onSubmit={val => {
              try {
                if (safeFamilyMemberId) {
                  editMutation[0]({
                    variables: {
                      familyMemberId: safeFamilyMemberId,
                      data: contactTypeToFamilyMemberInput(val),
                    },
                  })
                    .then(val => {
                      if (val.data?.editFamilyMember.success) {
                        history.goBack();
                      }
                    })
                    .catch(err => {
                      console.error(err);
                      setSnackbarMessage(
                        'Something went wrong. Your data may not have been saved',
                      );
                    });
                } else {
                  addMutation[0]({
                    variables: {
                      data: contactTypeToFamilyMemberInput(val),
                    },
                  })
                    .then(val => {
                      if (val.data?.addFamilyMember.success) {
                        history.goBack();
                      }
                    })
                    .catch(err => {
                      console.error(err);
                      setSnackbarMessage(
                        'Something went wrong. Your data may not have been saved',
                      );
                    });
                }
              } catch (err) {
                console.error(err);
                setSnackbarMessage(
                  'Something went wrong. Your data may not have been saved',
                );
              }
            }}
            render={({ data, setData }) => (
              <ContactInputs data={data} setData={setData} />
            )}
          />
        )}
      />
      <Snackbar
        open={Boolean(snackbarMessage)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        // message={snackbarMessage}
        onClose={() => setSnackbarMessage('')}
      >
        <Alert color="error">{snackbarMessage}</Alert>
      </Snackbar>
    </PopUp>
  );
}
