import { useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GenericAlert from '../../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { getOrderPrice } from '../../../../../common_lib_front/hooks/useOrderPrice';
import useGetMyGuests from '../../../../../hooks/useGetMyguests';
import { createPaymentSession } from '../../../../../hooks/usePaymentSession';
import { IGPassInfo } from '../../inviteGuestRequests';
import GuestDetails from '../guestDetails/guestDetails';
import style from './addPassToInvite.module.css';
import { ADD_PASS, ADD_PASS_RES, ADD_PASS_VARS } from './addPassToRegistration';

type AddPassToInvitePropsType = {
  passInfoData: IGPassInfo[];
};
export default function AddPassToInvite(
  props: AddPassToInvitePropsType,
): React.ReactElement {
  const { passInfoData } = props;
  const history = useHistory();
  const { registrationId } = useParams<{ registrationId?: string }>();
  const { data: registrationData } = useGetMyGuests({
    registrationIds: [registrationId || ''],
  });
  const validPassInfos: IGPassInfo[] = useMemo(() => {
    const validPassInfoIds: string[] =
      registrationData?.flatMap(r => r.passes.map(p => p.passInfoId)) || [];
    return passInfoData.filter(pid => validPassInfoIds.includes(pid.passInfoId));
  }, [passInfoData, registrationData]);
  const [passInfoId, setPassInfoId] = useState<string>(
    validPassInfos?.[0]?.passInfoId || '',
  );
  // const { getOrderPriceAsync } = useOrderPrice(newRegistrationData?.[0]?.passes?.map(({ ...rest }) => rest) || []);
  const [doAddPass, { loading, error, data }] = useMutation<ADD_PASS_RES, ADD_PASS_VARS>(
    ADD_PASS,
    {
      onError: e => {
        console.error(e);
      },
      onCompleted: async d => {
        if (d.addPassToInvite.success) {
          if (registrationData && registrationData.length > 0) {
            const passes = registrationData[0]?.passes?.map(pass => ({
              registrationId: pass.registrationId,
              passId: pass.passId,
              passInfoId: pass.passInfoId,
              startDate: new Date(pass.startDate),
              endDate: new Date(pass.endDate),
            }));

            if (d.addPassToInvite.data) {
              const passId = d.addPassToInvite.data?.[0].passes[0].passId;
              const additionalPass = {
                registrationId: registrationData[0].passes[0].registrationId,
                passId: passId,
                passInfoId: registrationData[0].passes[0].passInfoId,
                startDate: new Date(),
                endDate: new Date(),
              };
              const allPasses = [...passes, additionalPass];
              getOrderPrice({
                data: {
                  passes: allPasses,
                },
              }).then(async resp => {
                if (
                  resp?.data?.getOrderPrice?.data?.totalPrice &&
                  resp?.data?.getOrderPrice?.data?.totalPrice > 0
                ) {
                  const paymentSessionId = (
                    await createPaymentSession({
                      newPaymentSession: {
                        registrationIds: [registrationId || ''],
                      },
                    })
                  ).data?.createPaymentSession.data?.paymentSessionId;
                  if (!paymentSessionId) {
                    console.error('No payment session id');
                    return;
                  }
                  history.push(
                    `/invite-guest/payment?paymentSessionId=${paymentSessionId}`,
                  );
                } else {
                  history.replace('/invite-guest/guest-list');
                }
              });
            }
          }
        }
      },
    },
  );

  return (
    <GuestDetails refundMode={false}>
      <form
        className={style.wrapper}
        onSubmit={e => {
          e.preventDefault();
          // should never trigger if routing is set up correctly
          if (!registrationId) return;
          doAddPass({
            variables: {
              passInfoId,
              registrationId,
            },
          });
        }}
      >
        <div className={style.header}>
          <h2>Add Pass to This Invite</h2>
        </div>
        <GenericAlert
          color="red"
          title="Something went wrong. Pass may not have been created"
          hidden={!error}
        />
        <GenericAlert
          color="red"
          title={data?.addPassToInvite.error}
          hidden={!data?.addPassToInvite.error}
        />
        <GenericAlert
          color="green"
          title="Pass successfully added"
          hidden={!data?.addPassToInvite.success}
        />
        <label htmlFor="add-pass-type">
          Pass Type
          <select
            id="add-pass-type"
            value={passInfoId}
            onChange={e => setPassInfoId(e.target.value)}
            required
          >
            <option value="" hidden>
              Select Pass Type
            </option>
            {validPassInfos.map(pid => (
              <option key={pid.passInfoId} value={pid.passInfoId}>
                {pid.name}
              </option>
            ))}
          </select>
        </label>
        {loading ? (
          <div className={style.loadContainer}>
            <LoadingDiamonds />
          </div>
        ) : (
          <GenericButton
            type="submit"
            title="Add To Invite"
            color="blue"
            outline="none"
            size="medium"
          />
        )}
      </form>
    </GuestDetails>
  );
}
