import React, { ReactElement } from 'react';
import style from './loaderCircle.module.css';

interface Props {
  title?: string;
  className?: string;
}

export default function LoaderCircle(props: Props): ReactElement {
  const { title, className } = props;
  return (
    <div className={style.loader_modal}>
      <div className={style.modal_content}>
        <p className={`${style.loading} ${className}`}>
          <span className={style.custom_loader}></span>
          {title}
        </p>
        <h2>Recording your information</h2>
        <p>
          your information is being recorded.This may take a few minutes. <br />
          Please dont leave or refresh this page
        </p>
      </div>
    </div>
  );
}

LoaderCircle.defaultProps = {
  title: '',
  className: '',
};
