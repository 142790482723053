import { gql, useMutation } from '@apollo/client';
import { ReactElement, useState, useEffect, useContext } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import EditProfileTemplate from '../../../components/editProfileTemplate/editProfileTemplate';

import style from './paymentMethod.module.css';

const CREATE_PAYMENT_SESSION = gql`
  mutation CreatePaymentSetupSession($cancelUrl: String!, $successUrl: String!) {
    createPaymentSetupSession(cancelUrl: $cancelUrl, successUrl: $successUrl) {
      success
      error
      url
    }
  }
`;

type CREATE_PAYMENT_SESSION_VARS = {
  cancelUrl: string;
  successUrl: string;
};

type CREATE_PAYMENT_SESSION_RES = {
  createPaymentSetupSession: {
    success: boolean;
    error: string;
    url: string;
  };
};

export default function PaymentMethods(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { communityId } = useContext(CommunityContext);

  const [createPaymentSession, { loading }] = useMutation<
    CREATE_PAYMENT_SESSION_RES,
    CREATE_PAYMENT_SESSION_VARS
  >(CREATE_PAYMENT_SESSION);

  useEffect(() => {
    // Extract the 'success' query parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get('success');
    // If 'success' parameter is 'true', set the success message
    if (successParam === 'true') {
      setAlert('Payment method has been set up successfully.');
      setAlertColor('green');
    } else if (successParam === 'false') {
      setAlert('Something went wrong! Please try again after some time.');
      setAlertColor('red');
    }
  }, [location.search]);
  return (
    <EditProfileTemplate active="paymentMethods">
      <div className={style.alert}>
        <div className={style.alertBox}>
          <GenericAlert color={alertColor} title={alert} hidden={!alert} />
        </div>
      </div>
      <div className={`${style.subHeader} mainColor`}>
        <div className={`${style.circle} white`} />
        <h1 className={`${style.title} textColorWhite`}>Payment Methods</h1>
      </div>
      <div className={style.form}>
        <div className={style.btnBox}>
          <div className={style.saveBtn}>
            <GenericButton
              color="blue"
              size="large"
              type="submit"
              title="US Bank Account (ACH) Setup"
              disabled={loading}
              clickHandler={() => {
                if (loading) {
                  return;
                }

                createPaymentSession({
                  variables: {
                    cancelUrl: `${window.location.origin}/${communityId}/resident/edit-profile/payment-methods?success=false`,
                    successUrl: `${window.location.origin}/${communityId}/resident/edit-profile/payment-methods?success=true`,
                  },
                })
                  .then(res => {
                    if (res.data?.createPaymentSetupSession.url) {
                      window.location.href = res.data.createPaymentSetupSession.url;
                    } else {
                      setAlert(res.data?.createPaymentSetupSession.error || '');
                    }
                  })
                  .catch(err => {
                    setAlert(err.message);
                  });
              }}
            />
          </div>
        </div>
      </div>
    </EditProfileTemplate>
  );
}
