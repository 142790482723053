import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import DocumentTypeDropdown from '../../../common_lib_front/components/documentTypesDropDown/documentTypesDropdown';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import SpecialInstruction from '../../../common_lib_front/components/specialInstruction/specialInstruction';
import HostRegistrationNav from '../../../components/hostRegistrationNav/hostRegistrationNav';
import { useDocumentConfigs } from '../../../hooks/useDocumentConfigs';
import style from './documents.module.css';

interface IFileList {
  userDocumentName: string;
  file: string;
  fileName: string;
  documentType: string;
  // size: number;
}

export default function Documents(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [files, setFiles] = useState<IFileList[]>([]);
  const { registrationId } = useParams<{ registrationId: string }>();
  const history = useHistory();
  const [doc, setDoc] = useState<string>('');
  const [docType, setDocType] = useState<string>('');
  const [docId, setDocId] = useState<string>('');
  const [docExpireDate, setDocExpireDate] = useState<string>('');
  const [userDocumentName, setUserDocumentName] = useState<string>('');
  const [selectedDocName, setSelectedDocName] = useState<string>('');
  const { communityId, featuresConfig, hostDocumentUploadConfig } =
    useContext(CommunityContext);
  const [expirationDateRequired, setExpirationDateRequired] = useState<boolean>(
    communityId === 'debordieu' ? true : false,
  );
  const { data: documentTypesData } = useDocumentConfigs({
    fetchPolicy: 'network-only',
  });
  const documentTypes = documentTypesData?.getCommunityDocumentsConfig?.data || [];

  const EDIT_STEP_NUMBER = gql`
    mutation EditRegistrationStepNumber($registrationId: String!) {
      editRegistrationStepNumber(
        stepNumber: 3
        registrationId: $registrationId
        registrationType: "host"
      ) {
        success
        error
      }
    }
  `;

  const GET_USER_DOCUMENTS_QUERY = gql`
    query GetUserDocuments {
      getUserDocuments {
        success
        error
        data {
          userId
          documentId
          documentType
          userDocumentName
          uploadDate
          documentExpiration
        }
      }
    }
  `;

  const DELETE_REGISTRATION_DOCUMENT_QUERY = gql`
    mutation deleteUserDocumentById($documentId: String!) {
      deleteUserDocumentById(documentId: $documentId) {
        success
        error
      }
    }
  `;

  const ADD_USER_DOCUMENT_MUTATION = gql`
    mutation AddUserDocument(
      $file: String!
      $fileName: String!
      $documentType: String!
      $userDocumentName: String!
      $documentExpiration: DateTime
    ) {
      addUserDocument(
        file: $file
        fileName: $fileName
        documentType: $documentType
        userDocumentName: $userDocumentName
        documentExpiration: $documentExpiration
      ) {
        success
        error
        data {
          userId
          documentId
          documentUrl
          documentType
          userDocumentName
          documentExpiration
          uploadDate
        }
      }
    }
  `;

  const [doNextStep] = useMutation(EDIT_STEP_NUMBER);

  const { data, loading, error, refetch } = useQuery(GET_USER_DOCUMENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: d => {
      if (d.getUserDocuments.success) {
        setFiles(d.getUserDocuments.data);
      }
      if (!d.getUserDocuments.success) {
        setAlert(d.getUserDocuments.error);
        setAlertColor('red');
      }
    },
  });

  const handleChange = () => {
    // DOCUMENT UPLOAD NOT REQUIRED
    // if (files && files.length > 0) {
    doNextStep({ variables: { registrationId } }).then(() => {
      history.replace(`/resident/host-registration/${registrationId}/secure-checkout`);
    });
    // } else {
    //   setAlert('Please upload at least one document');
    //   setAlertColor('red');
    // }
  };

  const setDocumentType = (docID: string) => {
    if (documentTypes && documentTypes.length > 0) {
      const docFilter = documentTypes.filter(ele => ele.documentConfigId === docID);
      if (docFilter.length > 0) {
        setExpirationDateRequired(docFilter[0].noExpirationDate == true ? false : true);
        setDocType(docFilter[0].name);
        setDocId(docFilter[0].documentConfigId);
      }
    }
  };

  const setExpirationDate = (date: string) => {
    setDocExpireDate(date);
    if (date != '') {
      setExpirationDateRequired(false);
    } else {
      setExpirationDateRequired(true);
    }
  };

  const [deleteDocuments] = useMutation(DELETE_REGISTRATION_DOCUMENT_QUERY, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (!d.deleteUserDocumentById.success) {
        setAlert(d.deleteUserDocumentById.error);
        setAlertColor('red');
      } else {
        refetch();
      }
    },
  });

  const handleDelete = async (documentId: string) => {
    deleteDocuments({
      variables: {
        documentId,
      },
    });
  };

  type ADD_USER_DOCUMENT_VARS = {
    file: string;
    fileName: string;
    documentType: string;
    userDocumentName: string;
    documentExpiration?: string | null;
  };

  const [doAddDocument] = useMutation<any, ADD_USER_DOCUMENT_VARS>(
    ADD_USER_DOCUMENT_MUTATION,
    {
      onCompleted: d => {
        if (d.addUserDocument.success) {
          refetch();
          setDocType('');
          setDocId('');
          setDocExpireDate('');
          setDoc('');
          setUserDocumentName('');
          setSelectedDocName('');
        }
      },
      onError: e => {
        setAlert(e?.message);
        setAlertColor('red');
      },
    },
  );

  const submitHandler = async () => {
    try {
      await doAddDocument({
        variables: {
          file: doc,
          fileName: selectedDocName,
          userDocumentName,
          documentType: docType,
          documentExpiration: docExpireDate || null,
        },
      });
    } catch (error: any) {
      setAlert(error?.message);
      setAlertColor('red');
    }
  };

  async function selectForUpload(event: any) {
    const file = event[0];
    if (!file) return;
    const imgDataURL: string = await new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
    setDoc(imgDataURL);
    setSelectedDocName(file.name);
  }
  let documentStepNumber = 3;
  let documentSubTitle = null;
  if (!featuresConfig?.host?.initialRegistration?.educationStepEnabled) {
    documentStepNumber = 2;
  }
  if (hostDocumentUploadConfig?.instructionText) {
    const splitted = hostDocumentUploadConfig.instructionText.split('\n');

    if (splitted.length > 1) {
      documentSubTitle = (
        <ul>
          {splitted && splitted.map((item: string, i: number) => <li key={i}>{item}</li>)}
        </ul>
      );
    } else {
      documentSubTitle = splitted[0];
    }
    documentStepNumber = 2;
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <HostRegistrationNav
          stepNum={documentStepNumber}
          title={'Step ' + documentStepNumber + ': Upload Documents'}
          subtitle=""
        />
        {alert ? <GenericAlert title={alert} color={alertColor} /> : <></>}
        <div className={style.documentArea}>
          {hostDocumentUploadConfig?.specialInstructions?.length && (
            <SpecialInstruction
              pathName="host.host-registration.documents"
              instructions={hostDocumentUploadConfig?.specialInstructions}
            />
          )}
          <div className={style.uploadBox}>
            <div style={{ display: 'flex' }}>
              {communityId === 'debordieu' ? (
                <>
                  <div style={{ marginRight: '10px' }}>
                    <DocumentTypeDropdown
                      title={'Document Type'}
                      htmlFor="business-type-inpt"
                      value={docId || ''}
                      documentTypes={documentTypes || []}
                      required={true || !!doc}
                      largeSize={true}
                      changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setDocumentType(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ marginRight: '10px' }}>
                    <InputField
                      highlightOnFocus
                      largeSize
                      htmlFor="doc-name-inpt"
                      labelTitle="Document Name"
                      inputType="text"
                      inputValue={userDocumentName}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setUserDocumentName(e.target.value);
                      }}
                    />
                  </div>
                  <InputField
                    highlightOnFocus
                    htmlFor="doc-exp-inpt"
                    labelTitle="Document Expiration"
                    inputType="date"
                    inputValue={docExpireDate}
                    largeSize={true}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setExpirationDate(e.target.value);
                    }}
                  />
                </>
              ) : (
                <>
                  <div style={{ marginRight: '10px' }}>
                    <InputField
                      highlightOnFocus
                      largeSize
                      htmlFor="doc-name-inpt"
                      labelTitle="Document Name"
                      inputType="text"
                      inputValue={userDocumentName}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setUserDocumentName(e.target.value);
                      }}
                    />
                  </div>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="doc-type-inpt"
                    labelTitle="Document Type"
                    inputType="text"
                    inputValue={docType}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDocType(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Dropzone onDrop={acceptedFiles => selectForUpload(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section style={{ width: '100%' }} className="wfp--dropzone">
                    <div
                      style={{ width: '100%' }}
                      {...getRootProps({ className: 'wfp--dropzone__input' })}
                    >
                      <input {...getInputProps()} />
                      <div className={style.typeItem}>
                        <div className={style.lowerPart}>
                          <p className={style.dragTitle}>
                            <div>{selectedDocName || 'Drag File to Upload'}</div>
                          </p>
                          <p className={style.dragSubTitle}>or</p>
                          <button className={style.browseBtn}>Browse Files</button>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              submitHandler();
            }}
            disabled={!doc || !docType || !userDocumentName || expirationDateRequired}
            className={style.addBtn}
          >
            Upload
          </button>
        </div>

        {loading ? (
          <div className={style.loader}>
            {' '}
            <LoadingDiamonds />{' '}
          </div>
        ) : (
          <div>
            {files.map((ele: any, index: number) => (
              <div className={style.documentInput} key={index}>
                <a href={ele?.documentUrl} target="_blank" rel="noreferrer" title="view">
                  {ele?.userDocumentName}
                </a>
                <span
                  className={style.cross_sign}
                  title="remove"
                  onClick={() => handleDelete(ele.documentId)}
                ></span>
              </div>
            ))}
          </div>
        )}

        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              size="large"
              title="Continue"
              type="button"
              color="blue"
              clickHandler={() => handleChange()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
