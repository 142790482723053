import { gql, useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { PopUp } from '../../common_lib_front/components/popUp/popUp';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import style from './guestList.module.css';

const GET_EXPORT = gql`
  query GetExport($byPurchaseDate: Boolean!, $endDate: DateTime!, $startDate: DateTime!) {
    exportGuestList(
      byPurchaseDate: $byPurchaseDate
      endDate: $endDate
      startDate: $startDate
    ) {
      data
      error
      success
    }
  }
`;
type GET_EXPORT_VARS = {
  byPurchaseDate: boolean;
  endDate: string;
  startDate: string;
};
type GET_EXPORT_RES = {
  exportGuestList: backendResponse<string>;
};

type exportPopupProps = {
  setOpen: (val: boolean) => void;
};
export default function ExportPopup(props: exportPopupProps): React.ReactElement {
  const { setOpen } = props;
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [method, setMethod] = useState<'byPurchase' | 'byDates'>('byDates');
  const [doExport] = useLazyQuery<GET_EXPORT_RES, GET_EXPORT_VARS>(GET_EXPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: d => {
      const res = d.exportGuestList.data;
      if (!res) return;
      console.log(res);
      const useUrl = window.webkitURL || window.URL;
      const a = document.createElement('a');
      a.download = `guest-export-${startDate}-${endDate}-${method}-${Date.now()}.csv`;
      const bb = new Blob([res], { type: 'text/csv' });
      a.href = useUrl.createObjectURL(bb);
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
      a.click();
    },
  });

  return (
    <PopUp
      title="Export Guest List"
      close={() => {
        setOpen(false);
      }}
    >
      <form
        className={style.wrapper}
        onSubmit={e => {
          e.preventDefault();
          console.log(e);
          doExport({
            variables: {
              startDate,
              endDate,
              byPurchaseDate: method === 'byPurchase',
            },
          });
        }}
      >
        <div className={style.dateinputShortBox}>
          <div className={style.dateInput}>
            <InputField
              htmlFor="start-date"
              labelTitle="Start Date"
              inputType="date"
              max={endDate}
              inputValue={startDate}
              changeHandler={e => {
                setStartDate(e.target.value);
              }}
              required
              smallSize
              highlightOnFocus
            />
          </div>
          <div className={style.rectBox}>
            <div className={style.rect} />
          </div>
          <div className={style.dateInput}>
            <InputField
              htmlFor="end-date"
              labelTitle="End Date"
              inputType="date"
              min={startDate}
              inputValue={endDate}
              changeHandler={e => {
                setEndDate(e.target.value);
              }}
              required
              smallSize
              highlightOnFocus
            />
          </div>
        </div>
        <div className={style.selectBtnBox}>
          <div
            className={
              method === 'byDates' ? style.selectBtnActive : style.selectBtnInactive
            }
          >
            <GenericButton
              title="Export Reservations with an Arrival Date within the Selected Range"
              clickHandler={() => setMethod('byDates')}
              // color={method === 'byDates' ? 'blue' : 'grey'}
              size="large"
              outline="none"
            />
          </div>
          <span className={style.btnSpacer} />
          <div
            className={
              method === 'byPurchase' ? style.selectBtnActive : style.selectBtnInactive
            }
          >
            <GenericButton
              title="Export Reservations Whose Purchase Date is within the Selected Range”"
              clickHandler={() => setMethod('byPurchase')}
              // color={method === 'byPurchase' ? 'blue' : 'grey'}
              size="large"
              outline="none"
            />
          </div>
        </div>
        <div className={style.btnBox}>
          <GenericButton size="medium" title="Export" color="yellow" type="submit" />
        </div>
      </form>
    </PopUp>
  );
}
