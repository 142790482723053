import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IDocumentConfig } from '../../../../common_lib_front/types/documentInfo';
import PassInfo from '../../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import store, {
  documentConfig as reduxDocumentConfig,
} from '../../../../common_lib_front/utilities/store';

const GET_PASSES = gql`
  query GetPasses($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
      }
    }
  }
`;

const EDIT_STEP = gql`
  mutation editStep($registrationId: String!) {
    editRegistrationStepNumber(
      registrationId: $registrationId
      stepNumber: 2
      registrationType: "residentPass"
    ) {
      success
      error
    }
  }
`;

type CommunityType = {
  passes: Array<PassInfo>;
  errorMessage: string;
  doCompleteHandle: () => void;
  redirect: string;
};

export default function useCommunityOrientation(): CommunityType {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [errorMsg, setErrorMsg] = useState<string | null>();

  const [redirect, setRedirect] = useState<string>('');

  const [doEditStep] = useMutation(EDIT_STEP, {
    onError: e => {
      setErrorMsg(e.message);
    },
    onCompleted: d => {
      if (!d.editRegistrationStepNumber.success) {
        setErrorMsg(d.editRegistrationStepNumber.error);
      } else {
        setRedirect(
          `/resident/get-passes/resident-pass/${registrationId}/secure-checkout`,
        );
      }
    },
  });

  const { data: passesData } = useQuery(GET_PASSES, {
    variables: { registrationId },
    fetchPolicy: 'cache-and-network',
    onError: (e: ApolloError) => {
      setErrorMsg(e.message);
    },
    onCompleted: async d => {
      if (!d.getPassesByRegistration.success) {
        setErrorMsg(d.getPassesByRegistration.error);
      } else {
      }
    },
  });

  const doCompleteHandle = async () => {
    doEditStep({
      variables: {
        registrationId,
      },
    });
  };

  return {
    passes: passesData?.getPassesByRegistration?.data || [],
    errorMessage: errorMsg || '',
    doCompleteHandle,
    redirect,
  };
}
