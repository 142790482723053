import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import alertPic from '../../../assets/alert.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';

import style from './deleteDoc.module.css';

export const DELETE_DOCUMENT = gql`
  mutation deleteFamilyMember($familyMemberId: String!) {
    deleteFamilyMember(familyMemberId: $familyMemberId) {
      success
      error
    }
  }
`;

export default function DeleteDocument(): ReactElement {
  const history = useHistory();
  const { memberId } = useParams<{ memberId: string }>();
  const [alert, setAlert] = useState<string>('');

  const [doDeleteMember, { loading }] = useMutation(DELETE_DOCUMENT, {
    onError: e => {
      setAlert(e.message);
    },
    onCompleted: d => {
      if (d.deleteFamilyMember.success) {
        history.goBack();
      } else {
        setAlert(d.deleteFamilyMember.error);
      }
    },
  });
  return (
    <PopUp title="Delete Family Member or Friend" close={() => history.goBack()}>
      <div className={style.box}>
        <div className={style.alertBox}>
          <img src={alertPic} alt="" className={style.alertImg} />
          <h2>Warning:</h2>
          <h4 className={style.text}>
            Are you sure you want to delete the family member or friend ?
          </h4>
        </div>
        <GenericAlert color="red" title={alert} hidden={!alert} />
        <div className={style.btnBox}>
          <button onClick={() => history.goBack()} className={style.cancel}>
            Cancel
          </button>
          <button
            onClick={() => doDeleteMember({ variables: { familyMemberId: memberId } })}
            className={style.delete}
          >
            Delete
          </button>
        </div>
      </div>
    </PopUp>
  );
}
