import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const GET_INFO = gql`
  query GetCheckoutInfo {
    getResidentProfile {
      success
      error
      data {
        userId
        firstName
        lastName
        email
        phoneNumber
        altPhoneNum
        mailingStreet
        mailingUnit
        mailingCity
        mailingState
        mailingZipCode
        emergencyFirstName
        emergencyLastName
        emergencyPhoneNumber
        emergencyEmail
        ownerPin
      }
    }
    getPropertiesByUser {
      success
      error
      data {
        zipCode
        state
        city
        street
        unit
      }
    }
  }
`;

const FINALIZE_RESIDENT_REGISTRATION = gql`
  mutation FinalizeResidentRegistration($registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: 5
      registrationId: $registrationId
      registrationType: "resident"
    ) {
      success
      error
    }
    editApplicationStatus(registrationId: $registrationId, status: "pending-review") {
      success
      error
    }
  }
`;

type SecureCheckout = {
  registrationId: string;
  registrationData: any;
  loading: boolean;
  error: string;
  advanceStep: () => void;
};

export default function useRegistrationSecureCheckout(): SecureCheckout {
  const { registrationId } = useParams<{ registrationId: string }>();
  const {
    data: allData,
    loading: allLoading,
    error: allError,
  } = useQuery(GET_INFO, {
    fetchPolicy: 'network-only',
  });

  const [doAdvanceStep] = useMutation(FINALIZE_RESIDENT_REGISTRATION);
  const advanceStep = () => {
    doAdvanceStep({
      variables: {
        registrationId,
      },
    });
  };

  return {
    registrationId,
    registrationData:
      {
        ...allData?.getResidentProfile?.data,
        ...allData?.getPropertiesByUser?.data[
          allData?.getPropertiesByUser?.data.length - 1
        ],
      } || {},
    loading: allLoading,
    error: allError?.message || '',
    advanceStep,
  };
}
