import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  ADDRESS_VALIDATION,
  ADDRESS_VALIDATION_RES,
  ADDRESS_VALIDATION_VARS,
} from '../../../common_lib_front/hooks/useAddressValidation';
import { rentalUnitInfo } from '../../../common_lib_front/types/rentalUnitInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
const DELETE_RENTAL = gql`
  mutation Mutation($rentalUnitId: String!) {
    deleteRental(rentalUnitId: $rentalUnitId) {
      success
      error
      data {
        rentalUnitId
      }
    }
  }
`;
const GET_RENTAL = gql`
  query GetRentalWithOccupancy($rentalUnitId: String!) {
    getRentalWithOccupancy(rentalUnitId: $rentalUnitId) {
      success
      error
      data {
        rentalUnitId
        registrationId
        address
        city
        state
        zipCode
        companyName
        primaryEmergencyContactName
        primaryEmergencyPhone
        secondaryEmergencyPhone
        propertyManagerName
        propertyManagerPhone
        email
        passStatus
        occupancy
        startDate
        endDate
        hostPay
        guestPay
        hostPropertySlug
        guestPropertySlug
        enableContactDetails
      }
    }
  }
`;
const EDIT_RENTAL = gql`
  mutation Mutation(
    $email: String!
    $primaryEmergencyContactName: String!
    $secondaryEmergencyPhone: String!
    $primaryEmergencyPhone: String!
    $propertyManagerPhone: String!
    $propertyManagerName: String!
    $companyName: String!
    $zipCode: String!
    $state: String!
    $city: String!
    $address: String!
    $rentalUnitId: String!
    $hostPay: Boolean
    $guestPay: Boolean
    $enableContactDetails: Boolean
  ) {
    editRental(
      rentalUnitInfo: {
        email: $email
        primaryEmergencyContactName: $primaryEmergencyContactName
        secondaryEmergencyPhone: $secondaryEmergencyPhone
        primaryEmergencyPhone: $primaryEmergencyPhone
        propertyManagerPhone: $propertyManagerPhone
        propertyManagerName: $propertyManagerName
        companyName: $companyName
        zipCode: $zipCode
        state: $state
        city: $city
        address: $address
        rentalUnitId: $rentalUnitId
        hostPay: $hostPay
        guestPay: $guestPay
        enableContactDetails: $enableContactDetails
      }
      rentalUnitId: $rentalUnitId
    ) {
      success
      error
    }
  }
`;

type useMyRentalsRes = {
  history: any;
  rental: rentalUnitInfo | undefined;
  deleteRental: () => void;
  editRental: (obj: { key: keyof rentalUnitInfo; val: string }, idx: number) => void;
  editAll: () => Promise<any>;
  alert: string;
  alertColor: 'red' | 'green';
  valid: () => boolean;
  addressValidation: (val: string) => Promise<Array<string>>;
  rentalUnitId: string;
  refetchData: () => void;
};
export default function useMyRentals(): useMyRentalsRes {
  const history = useHistory();
  const { rentalUnitId } = useParams<{ rentalUnitId: string }>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [rental, setRental] = useState<rentalUnitInfo>();

  const { data, loading, error, refetch } = useQuery(GET_RENTAL, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
    variables: { rentalUnitId: rentalUnitId },
  });

  useEffect(() => {
    if (rentalUnitId) {
      setRental(data?.getRentalWithOccupancy?.data[0]);
    } else setRental(<any>history.location.state);
  }, [setRental, history.location.state, data]);

  const editRental = (obj: { key: keyof rentalUnitInfo; val: string }) => {
    const res: any = { ...rental };
    Object.assign(res, obj);
    setRental(res);
  };

  const [doEditRental] = useMutation(EDIT_RENTAL, {
    onCompleted: () => {
      // history.goBack();
    },
  });
  const refetchData = () => {
    refetch();
  };

  const editAll = async () =>
    doEditRental({
      variables: rental,
    })
      .then(() => {
        setAlert('Rentals Edited Successfully');
        setAlertColor('green');
      })
      .catch(() => {
        setAlert('Something went wrong. Changes may not be saved');
        setAlertColor('red');
      });

  const valid = () => {
    let returnValue = true;

    // Create an array of fields to ignore
    const fieldsToIgnore = ['email', 'price', 'secondaryEmergencyPhone'];
    // const shouldIgnoreField = field => fieldsToIgnore.includes(field);
    // this way will bypass check secondary phone (optional)
    const newRentalValid =
      rental?.secondaryEmergencyPhone === ''
        ? { ...rental, secondaryEmergencyPhone: 'optional' }
        : rental;

    Object.entries({ ...newRentalValid }).forEach(([key, value]) => {
      if (value === '' && !fieldsToIgnore.includes(key)) {
        returnValue = false;
      }
      return value;
    });

    if (!returnValue) {
      setAlert('Please fill in all fields');
      setAlertColor('red');
      return false;
    }

    return true;
  };
  const addressValidation = (val: string) =>
    backendClient
      .query<ADDRESS_VALIDATION_RES, ADDRESS_VALIDATION_VARS>({
        query: ADDRESS_VALIDATION,
        variables: {
          address: val,
        },
      })
      .then(res => res.data.addressValidation.data?.map(addVal => addVal.address) || []);

  const [doDeleteRental] = useMutation(DELETE_RENTAL, {
    onCompleted: () => {
      history.goBack();
    },
  });

  return {
    history,
    rental,
    deleteRental: () => {
      doDeleteRental({
        variables: {
          rentalUnitId: rental?.rentalUnitId,
        },
      });
    },
    editRental,
    editAll,
    alert,
    alertColor,
    valid,
    addressValidation,
    rentalUnitId,
    refetchData,
  };
}
