import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import {
  ADDRESS_VALIDATION,
  ADDRESS_VALIDATION_RES,
  ADDRESS_VALIDATION_VARS,
} from '../../../common_lib_front/hooks/useAddressValidation';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import {
  newRentalUnitInfo,
  rentalUnitInfo,
} from '../../../common_lib_front/types/rentalUnitInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
const GET_RENTALS = gql`
  query Query {
    getRentalByUserWithOccupancy {
      success
      error
      data {
        rentalUnitId
        registrationId
        address
        city
        state
        zipCode
        companyName
        primaryEmergencyContactName
        primaryEmergencyPhone
        secondaryEmergencyPhone
        propertyManagerName
        propertyManagerPhone
        email
        passStatus
        occupancy
        startDate
        endDate
      }
    }
  }
`;

const DELETE_RENTAL = gql`
  mutation Mutation($rentalUnitId: String!) {
    deleteRental(rentalUnitId: $rentalUnitId) {
      success
      error
      data {
        rentalUnitId
      }
    }
  }
`;

const EDIT_RENTAL = gql`
  mutation Mutation(
    $email: String
    $primaryEmergencyContactName: String
    $secondaryEmergencyPhone: String
    $primaryEmergencyPhone: String
    $companyName: String
    $zipCode: String
    $state: String
    $city: String
    $address: String
    $rentalUnitId: String!
  ) {
    editRental(
      rentalUnitInfo: {
        email: $email
        primaryEmergencyContactName: $primaryEmergencyContactName
        secondaryEmergencyPhone: $secondaryEmergencyPhone
        primaryEmergencyPhone: $primaryEmergencyPhone
        companyName: $companyName
        zipCode: $zipCode
        state: $state
        city: $city
        address: $address
      }
      rentalUnitId: $rentalUnitId
    ) {
      success
      error
    }
  }
`;

type useMyRentalsRes = {
  rentals: Array<rentalUnitInfo>;
  editRental: (obj: { key: keyof rentalUnitInfo; val: string }, idx: number) => void;
  deleteRental: (id: string) => void;
  editAll: () => Promise<any>;
  alert: string;
  alertColor: 'red' | 'green';
  valid: () => boolean;
  addressValidation: (val: string) => Promise<Array<string>>;
};
export default function useMyRentals(): useMyRentalsRes {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [rentals, setRentals] = useState<Array<rentalUnitInfo>>([]);
  const editRental = (obj: { key: keyof rentalUnitInfo; val: string }, idx: number) => {
    const res = [...rentals];
    Object.assign(res[idx], obj);
    setRentals(res);
  };

  const { refetch: rentalRefetch } = useQuery(GET_RENTALS, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getRentalByUserWithOccupancy.success) {
        setRentals(
          d.getRentalByUserWithOccupancy.data?.map((r: any) => newRentalUnitInfo(r)),
        );
      }
    },
  });

  const [doDeleteRental] = useMutation(DELETE_RENTAL);
  const deleteRentalHelper = (id: string) =>
    doDeleteRental({
      variables: { rentalUnitId: id },
    }).then(() => {
      console.log(rentals);
      let tmp = [...rentals];
      tmp = tmp.filter((val: rentalUnitInfo) => val.rentalUnitId !== id);
      console.log(tmp);
      setRentals(tmp);
    });

  const [doEditRental] = useMutation(EDIT_RENTAL, {
    onCompleted: () => rentalRefetch(),
  });
  const editAll = async () =>
    Promise.all(
      rentals.map((val: rentalUnitInfo) =>
        doEditRental({
          variables: val,
        }),
      ),
    )
      .then(() => {
        setAlert('Rentals Edited Successfully');
        setAlertColor('green');
      })
      .catch(() => {
        setAlert('Something went wrong. Changes may not be saved');
        setAlertColor('red');
      });

  const valid = () => {
    let returnValue = true;
    rentals.forEach((val: rentalUnitInfo) => {
      Object.values(val).forEach(value => {
        if (value === '') {
          returnValue = false;
        }
        return value;
      });
    });
    if (!returnValue) {
      setAlert('Please fill in all fields');
      setAlertColor('red');
      return false;
    }
    return true;
  };

  const addressValidation = (val: string) =>
    backendClient
      .query<ADDRESS_VALIDATION_RES, ADDRESS_VALIDATION_VARS>({
        query: ADDRESS_VALIDATION,
        variables: {
          address: val,
        },
      })
      .then(res => res.data.addressValidation.data?.map(addVal => addVal.address) || []);

  return {
    rentals,
    editRental,
    deleteRental: deleteRentalHelper,
    editAll,
    alert,
    alertColor,
    valid,
    addressValidation,
  };
}
