import { isEqual } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Alert } from '../../../assets/alertRed.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';
import RentalUnitForm from '../../../components/rentalUnitForm/rentalUnitForm';
import style from './editRental.module.css';
import useEditRental from './useEditRental';

export default function EditRental(): ReactElement {
  const {
    history,
    rental,
    deleteRental,
    editRental,
    editAll,
    alert,
    alertColor,
    valid,
    addressValidation,
  } = useEditRental();

  const [confirmChange, setConfirmChange] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [changeValue, setChangeValue] = useState<boolean>(false);

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      if (!ref.current && value) {
        ref.current = value;
      }
    });
    return ref.current;
  }

  const prevValue = usePrevious(rental);

  const trimHandle = useCallback((data: any) => {
    let param = data;

    if (Object.prototype.toString.call(param) === '[object Object]') {
      Object.keys(param).forEach(item => {
        param[item] = trimHandle(param[item]);
      });
    }

    if (Object.prototype.toString.call(param) === '[object String]') {
      param = param.trim();
    }
    return param;
  }, []);

  useEffect(() => {
    trimHandle(rental);

    if (prevValue) {
      setChangeValue(!isEqual(prevValue, rental));
    }
  }, [prevValue, rental, trimHandle]);

  return (
    <PopUp close={() => history.goBack()} title="Edit Rental Information">
      {confirmChange && (
        <PopUp title="" close={() => setConfirmChange(false)}>
          <div className={style.container}>
            <div className={style.alertPic}>
              <Alert />
            </div>
            <h2 className={style.warning}>Warning:</h2>
            <p className={style.instruction}>
              Are you sure you want to save changes to this rental?
            </p>
            <p className={style.instruction}>
              Once you save changes, Your rental registration status will change back to
              &quot;Pending Review&quot;.
            </p>
            <div className={style.flex}>
              <div className={style.btn}>
                <GenericButton
                  title="Cancel"
                  clickHandler={() => setConfirmChange(false)}
                  outline="large"
                />
              </div>
              <div className={style.btn}>
                <GenericButton
                  title="Confirm"
                  clickHandler={() => editAll()}
                  size="large"
                  color="blue"
                />
              </div>
            </div>
          </div>
        </PopUp>
      )}

      {confirmDelete && (
        <PopUp title="" close={() => setConfirmDelete(false)}>
          <div className={style.container}>
            <div className={style.alertPic}>
              <Alert />
            </div>
            <h2 className={style.warning}>Warning:</h2>
            <p className={style.instruction}>
              Are you sure you want to delete the rental?
            </p>
            <div className={style.flex}>
              <div className={style.btn}>
                <GenericButton
                  title="Cancel"
                  clickHandler={() => setConfirmDelete(false)}
                  outline="large"
                />
              </div>
              <div className={style.btn}>
                <GenericButton
                  title="Confirm"
                  clickHandler={() => deleteRental()}
                  size="large"
                  color="blue"
                />
              </div>
            </div>
          </div>
        </PopUp>
      )}

      {rental && (
        <form
          className={style.box}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (valid()) {
              setConfirmChange(true);
            }
          }}
        >
          <div className={style.alertBox}>
            <GenericAlert title={alert} hidden={!alert} color={alertColor} />
          </div>
          <div className={style.formBox}>
            <RentalUnitForm
              key={rental.rentalUnitId}
              showEditButton
              data={rental}
              idx={0}
              remove={() => setConfirmDelete(true)}
              update={(obj: any) => editRental(obj, 0)}
              fetchAddressValidation={addressValidation}
            />
          </div>
          {changeValue ? (
            <div className={style.submitBtn}>
              <GenericButton
                size="large"
                title="Save Changes"
                color="blue"
                type="submit"
              />
            </div>
          ) : null}
        </form>
      )}
    </PopUp>
  );
}
