import React from 'react';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { hostInfo, newHostInfo } from '../../common_lib_front/types/hostInfo';
import { HostInfoFormAction } from './hostInfoForm';
import style from './hostInfoForm.module.css';

export type HostInfoFormData = hostInfo;
export const defaultHostInfoData = newHostInfo();

type HostInfoFormBaseProps = {
  data: HostInfoFormData;
  update: React.Dispatch<HostInfoFormAction>;
};

export function HostInfoFormBase(props: HostInfoFormBaseProps): React.ReactElement {
  const { data, update } = props;

  return (
    <div className={style.formWrapper}>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-firstname-inpt"
          labelTitle="First Name"
          inputValue={data.firstName}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ firstName: e.target.value });
          }}
          required
        />
      </div>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-lastname-inpt"
          labelTitle="Last Name"
          inputValue={data.lastName}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ lastName: e.target.value });
          }}
          required
        />
      </div>
      <div className={style.fullWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-company-name"
          labelTitle="Company Name (Property Management Companies Only)"
          inputValue={data.companyName || ''}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ companyName: e.target.value });
          }}
        />
      </div>
      <div className={style.fullWidth}>
        <PhoneInputWrapper
          standardInput
          inputTitle="Phone Number"
          value={data.phoneNumber}
          changeHandler={val => {
            update({ phoneNumber: val });
          }}
          required
        />
      </div>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-address-inpt"
          labelTitle="Mailing Address"
          inputValue={data.address}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ address: e.target.value });
          }}
          required
        />
      </div>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-city-inpt"
          labelTitle="City"
          inputValue={data.city}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ city: e.target.value });
          }}
          required
        />
      </div>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-state-inpt"
          labelTitle="State"
          inputValue={data.state}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ state: e.target.value });
          }}
          required
        />
      </div>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-zip-inpt"
          formatCode="zip-strict"
          labelTitle="Zip Code"
          inputValue={data.zipCode}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ zipCode: e.target.value });
          }}
          required
          minLength={5}
        />
      </div>
      <div className={style.halfWidth}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="host-pin-inpt"
          labelTitle="Owner Pin"
          inputValue={data.ownerPin}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            update({ ownerPin: e.target.value });
          }}
          required
          minLength={2}
        />
        <div className={style.ownerPinText}>
          Owner Pin (This is the number you use to request guest passes)
        </div>
      </div>
    </div>
  );
}
