import { gql, useMutation, useQuery } from '@apollo/client';
import store, { getUserDetails } from '../../../common_lib_front/utilities/store';
// import { useParams } from 'react-router-dom';

const CHECK_RESIDENT_REGISTRATION = gql`
  query GetResidentRegistrations {
    getRegistrations(complete: false, type: "resident") {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

const CREATE_RESIDENT_REGISTRATION = gql`
  mutation CreateResidentRegistration {
    createRegistration(type: "resident") {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

const CREATE_RESIDENT_APPLICATION = gql`
  mutation CreateResidentApplication($registrationId: String!) {
    createApplication(type: "resident", registrationId: $registrationId) {
      success
      error
    }
  }
`;

type RegistrationInitialization = {
  registrationId?: string;
  loading: boolean;
  error?: string;
};

export default function useRegistrationProcessInitialization(): RegistrationInitialization {
  const [
    doCreateApplication,
    {
      data: createApplicationData,
      loading: createApplicationLoading,
      error: createApplicationError,
    },
  ] = useMutation(CREATE_RESIDENT_APPLICATION, { fetchPolicy: 'network-only' });

  const [
    doCreateRegistration,
    {
      data: createRegistrationData,
      loading: createRegistrationLoading,
      error: createRegistrationError,
    },
  ] = useMutation(CREATE_RESIDENT_REGISTRATION, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.createRegistration.success) {
        doCreateApplication({
          variables: {
            registrationId: d.createRegistration.data[0].registrationId,
          },
        });
      }
    },
  });

  const {
    data: getData,
    loading: getLoading,
    error: getError,
  } = useQuery(CHECK_RESIDENT_REGISTRATION, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getRegistrations.success) {
        if (d.getRegistrations.data.length < 1) {
          doCreateRegistration();
        }
      }
    },
  });

  // console.log(createRegistrationData);
  // console.log(createRegistrationData?.createRegistration);
  // console.log(createRegistrationData?.createRegistration?.data);
  // console.log(createRegistrationData?.createRegistration?.data?.[0]);
  // console.log(createRegistrationData?.createRegistration?.data?.[0]?.registrationId);
  return {
    registrationId:
      getData?.getRegistrations?.data?.[0]?.registrationId ||
      createRegistrationData?.createRegistration?.data?.[0]?.registrationId,
    loading: getLoading || createRegistrationLoading || createApplicationLoading,
    error:
      createRegistrationError?.message ||
      createApplicationError?.message ||
      createApplicationData?.createApplication?.error ||
      getError?.message ||
      getData?.getRegistration?.error ||
      undefined,
  };
}
