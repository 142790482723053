import { gql, useMutation } from '@apollo/client';
import { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './generatePasses.module.css';

const ADVANCE_STEP_NUMBER = gql`
  mutation AdvanceStepNum($registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: 5
      registrationId: $registrationId
      registrationType: "residentPass"
    ) {
      success
      error
    }
  }
`;

export default function GeneratePasses(): ReactElement {
  const { registrationId } = useParams<{ registrationId: string }>();
  const history: any = useHistory();

  const [doSubmit] = useMutation(ADVANCE_STEP_NUMBER, {
    variables: {
      registrationId,
    },
    onCompleted: d => {
      if (d.editRegistrationStepNumber.success) {
        history.replace(`/resident/get-passes/resident-pass/${registrationId}/receipt`);
      }
    },
  });

  useEffect(() => {
    doSubmit();
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.box} white`}>
        <div className={styles.loader} />
      </div>
    </div>
  );
}
