import { FetchResult } from '@apollo/client';
import React, { useEffect, useReducer } from 'react';
import { newHostInfo } from '../../common_lib_front/types/hostInfo';
import { useGetHostApplication } from '../../hooks/useGetApplication';
import { EDIT_HOST_INFO_RES, editHostInfo } from './editHostInfoRequest';
import { useGetHostInfo } from './getHostInfoRequest';
import {
  HostInfoFormBase,
  HostInfoFormData,
  defaultHostInfoData,
} from './hostInfoFormBase';

export type IHostInfoFormApi = {
  getData: () => HostInfoFormData;
  getInitialData: () => HostInfoFormData | undefined;
  update: React.Dispatch<HostInfoFormAction>;
  submit: () => Promise<FetchResult<EDIT_HOST_INFO_RES>>;
};

export type HostInfoFormAction = Partial<HostInfoFormData>;
export type HostInfoFormDataReducerType = React.Reducer<
  HostInfoFormData,
  HostInfoFormAction
>;
export const HostInfoFormDataReducer: HostInfoFormDataReducerType = (prev, action) => {
  return { ...prev, ...action };
};

export type HostInfoFromProps = {
  apiRef?: React.MutableRefObject<IHostInfoFormApi | null>;
};

export function HostInfoForm(props: HostInfoFromProps): React.ReactElement {
  const { data: hostApplicationData } = useGetHostApplication();
  const { apiRef } = props;
  const [data, update] = useReducer(
    HostInfoFormDataReducer,
    {},
    () => defaultHostInfoData,
  );
  const hostRegistrationId = hostApplicationData?.getApplication?.data?.registrationId;

  const { result: initialData, refetch: refetchInitialData } = useGetHostInfo({
    variables: {
      registrationId: hostRegistrationId || '',
    },
    skip: !hostApplicationData?.getApplication.data?.registrationId,
    onCompleted: d => {
      if (d.getHostInfoData?.data) {
        update(d.getHostInfoData.data);
      }
    },
    onError: e => {
      console.error(e);
    },
  });

  // attatch api to external ref
  useEffect(() => {
    if (apiRef === undefined) return;
    apiRef.current = {
      getData: () => data,
      getInitialData: () => newHostInfo(initialData),
      update,
      submit: () =>
        editHostInfo({
          variables: {
            ...data,
            registrationId: hostRegistrationId || '',
          },
        }).then(res => {
          refetchInitialData();
          return res;
        }),
    };
  }, [data, apiRef, initialData, refetchInitialData, hostRegistrationId]);

  return <HostInfoFormBase data={data} update={update} />;
}
