/* eslint-disable prettier/prettier */
import React, { ReactElement, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LockImg } from '../../assets/lockSmall.svg';
import { ReactComponent as ProfileImg } from '../../assets/profileSmall.svg';
import { ReactComponent as VehicleImg } from '../../assets/vehicle.svg';
import { ReactComponent as PaymentMethodImg } from '../../common_lib_front/assets/card.svg';
import { ReactComponent as FriendsImg } from '../../common_lib_front/assets/employee.svg';
import { ReactComponent as DocImg } from '../../common_lib_front/assets/fileSmall.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
// import { getUserDetails } from '../../common_lib_front/utilities/store';
import UseMyProfile from '../../pages/profile/myProfile/useMyProfile';
import style from './editProfileTemplate.module.css';

interface EditProfileGuestProps {
  children?: ReactElement | ReactElement[];
  active?: string;
}

export default function EditProfileTemplate(props: EditProfileGuestProps): ReactElement {
  const { children, active } = props;
  const { search } = useLocation();
  // const userDetails = getUserDetails();
  const { role, getProfileLoading } = UseMyProfile();
  // TODO: remove this it is only for example
  const tmpStyles: any = {
    profile: {},
    password: {},
    familyAndfriends: {},
    myDocuments: {},
    myVehicles: {},
    paymentMethods: {},
  };
  const currentCommunityData = useContext(CommunityContext);
  switch (active) {
    case 'profile':
      // TODO: add styles for active 'my profile' tab
      tmpStyles.profile.backgroundColor = 'var(--thirdColor)';
      break;
    case 'password':
      // TODO: add styles for active 'reset password' tab
      tmpStyles.password.backgroundColor = 'var(--thirdColor)';
      break;
    case 'familyAndfriends':
      // TODO: add styles for active 'familyAndfriends' tab
      tmpStyles.familyAndfriends.backgroundColor = 'var(--thirdColor)';
      break;
    case 'myDocuments':
      // TODO: add styles for active 'myDocuments' tab
      tmpStyles.myDocuments.backgroundColor = 'var(--thirdColor)';
      break;
    case 'paymentMethods':
      // TODO: add styles for active 'myDocuments' tab
      tmpStyles.paymentMethods.backgroundColor = 'var(--thirdColor)';
      break;
    case 'myVehicles':
      tmpStyles.myVehicles.backgroundColor = 'var(--thirdColor)';
      break;
    default:
    // TODO: add default styles
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.subNav} darkGrey `}>
          {getProfileLoading ? (
            <LoadingDiamonds />
          ) : (
            <div className={style.btnBox}>
              <Link to={{ pathname: '/resident/edit-profile/my-profile', search }}>
                <button
                  style={tmpStyles.profile}
                  className={`${style.btn} textColorWhite  darkGrey `}
                >
                  <div>
                    {' '}
                    <ProfileImg />
                  </div>
                  <p className={style.text}>My Profile</p>
                </button>
              </Link>
              <Link to={{ pathname: '/resident/edit-profile/reset-password', search }}>
                <button
                  style={tmpStyles.password}
                  className={`${style.btn}  textColorWhite darkGrey `}
                >
                  <div>
                    {' '}
                    <LockImg />
                  </div>
                  <p className={style.text}>Change Password</p>
                </button>
              </Link>
              { /* TODO there should possibly be further logic here to ensure that documents being
              enabled for one user role does not make the link show for the other user role */}
              { currentCommunityData?.featuresConfig?.host?.initialRegistration?.documentStepEnabled
              || currentCommunityData?.featuresConfig?.resident?.registration?.documentStepEnabled ? (
                  <Link to={{ pathname: '/resident/edit-profile/my-documents', search }}>
                    <button
                      style={tmpStyles.myDocuments}
                      className={`${style.btn}  textColorWhite darkGrey `}
                    >
                      <div>
                        {' '}
                        <DocImg />
                      </div>
                      <p className={style.text}>My Documents</p>
                    </button>
                  </Link>
                ) : null}
              {/* eslint-ignore-next-line prettier/prettier*/}
              {/* prettier-ignore */}
              {(role === 'host' || role === 'resident') && ['watercolor', 'pinnacleport'].includes(currentCommunityData?.communityId)
                ? (
                  <Link to={{ pathname: '/resident/edit-profile/payment-methods', search }}>
                    <button
                      style={tmpStyles.paymentMethods}
                      className={`${style.btn}  textColorWhite darkGrey `}
                    >
                      <div>
                        {' '}
                        <PaymentMethodImg />
                      </div>
                      <p className={style.text}>Payment Methods</p>
                    </button>
                  </Link>
                ) : null}
              {currentCommunityData?.featuresConfig?.resident?.familyAndFriendsPasses?.enabled && role !== 'host' ? (
                <>
                  <Link to={{ pathname: '/resident/edit-profile/friends-and-family', search }}>
                    <button
                      style={tmpStyles.familyAndfriends}
                      className={`${style.btn}  textColorWhite darkGrey `}
                    >
                      <div>
                        {' '}
                        <FriendsImg />
                      </div>
                      <p className={style.text}>Family and Friends</p>
                    </button>
                  </Link>
                  <Link to={{ pathname: '/resident/edit-profile/my-vehicles', search }}>
                    <button
                      style={tmpStyles.myVehicles}
                      className={`${style.btn}  textColorWhite darkGrey `}
                    >
                      <div>
                        {' '}
                        <VehicleImg style={{ stroke: 'white', fill: 'white' }} />
                      </div>
                      <p className={style.text}>My Vehicles</p>
                    </button>
                  </Link>
                </>
              ) : null}
            </div>
          )}
        </div>
        <div className={style.infoBox}>{children}</div>
      </div>
    </div>
  );
}
EditProfileTemplate.defaultProps = {
  children: undefined,
  active: '',
};
