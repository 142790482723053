import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import { backendClient } from '../common_lib_front/utilities/BackendAPI';

const GET_USER_DOCUMENT_QUERY = gql`
  query GetUserDocument($documentId: String!) {
    getUserDocument(documentId: $documentId) {
      success
      error
      data {
        fileName
        documentConfigId
        userId
        documentId
        documentUrl
        documentType
        userDocumentName
        uploadDate
        documentExpiration
      }
    }
  }
`;

export type GET_USER_DOCUMENT_VARS = {
  documentId: string;
};

export type GET_USER_DOCUMENT_RES = {
  getUserDocument: backendResponse<{ documentUrl: string }>;
};

type optionsType = QueryHookOptions<GET_USER_DOCUMENT_RES, GET_USER_DOCUMENT_VARS>;
export const useDocuments = (options: optionsType) =>
  useQuery<GET_USER_DOCUMENT_RES, GET_USER_DOCUMENT_VARS>(
    GET_USER_DOCUMENT_QUERY,
    options,
  );

export const getDocUrl = async (documentId: string) =>
  backendClient.query<GET_USER_DOCUMENT_RES, GET_USER_DOCUMENT_VARS>({
    query: GET_USER_DOCUMENT_QUERY,
    variables: {
      documentId,
    },
  });
