import { ReactElement, useState } from 'react';
import GuestDataGrid from '../../../../../components/guestDataGrid/guestDataGrid';
import ExportPopup from '../../../../../components/guestList/exportPopup';
import InviteGuestNav from '../../../../../components/inviteGuestNav/inviteGuestNav';
import style from './guestList.module.css';

export default function FastPasList(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  if (open) {
    return <ExportPopup setOpen={setOpen} />;
  }

  return (
    <div className={style.ListBox}>
      <InviteGuestNav activeTab="fast-pass-list" />
      <GuestDataGrid
        title="Fast Passes"
        fastPassesOnly={true}
        onBtnExport={() => {
          setOpen(true);
        }}
      />
    </div>
  );
}
