import { Button, Container, Typography } from '@mui/material';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useInputConfig } from './communityConfigToRemove';
import { contactType } from './contactType';

export type CDWRednerProps = {
  data: contactType;
  setData: React.Dispatch<SetStateAction<contactType>>;
};

export type CDWProps = {
  initialData?: contactType;
  onSubmit?: (value: contactType) => void;
  reportError?: (message: string) => void;
  render: (props: CDWRednerProps) => React.ReactElement;
};

export function ContactDataFormWrapper({
  render,
  initialData,
  onSubmit,
  reportError,
}: CDWProps): React.ReactElement {
  const [data, setData] = useState<contactType>({});
  const [inputCfg] = useInputConfig();

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        // validata data if config is provided
        if (inputCfg?.inputs) {
          if (
            !Object.entries(inputCfg?.inputs).every(([k, v]) => {
              if (v.hidden) return true;
              if (!v.required) return true;
              return Boolean(data[k as keyof contactType]);
            })
          ) {
            reportError?.(
              'Please be sure to fill out all the required fields. Required fields are marked with a "*"',
            );
            return;
          }
        }
        onSubmit?.(data);
      }}
    >
      {inputCfg?.largeInstruction && (
        <Container
          style={{
            marginTop: '30px',
          }}
        >
          <Typography align="center" variant="h6">
            {inputCfg.largeInstruction}
          </Typography>
        </Container>
      )}
      {render({ data, setData })}
      <Container
        maxWidth="xs"
        style={{
          marginTop: '40px',
        }}
      >
        <Button
          fullWidth
          type="submit"
          variant="contained"
          style={{
            backgroundColor: 'var(--mainColor)',
          }}
        >
          Submit
        </Button>
      </Container>
    </form>
  );
}
