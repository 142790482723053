import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
// import { getUserRegistrationSteps } from '../../../utilities/store';
import style from './registrationProcessInit.module.css';
import useRegistrationProcessInitialization from './useRegistrationProcessInit';

export default function RegistrationProcessInit(): ReactElement {
  const { registrationId, loading, error } = useRegistrationProcessInitialization();
  if (registrationId) {
    return <Redirect to={`/resident/registration/${registrationId}/information`} />;
  }

  if (loading) {
    return <div className={style.wrapper}>Loading ...</div>;
  }

  if (error) {
    return <div className={style.wrapper}>{error}</div>;
  }

  return (
    <div className={style.wrapper}>
      We encountered an error. Please try again later and report this error if it
      persists.
    </div>
  );
}
