export type registrationInfo = {
  userId: string;
  zipCode: string;
  state: string;
  city: string;
  street: string;
  unit: string;
  altPhoneNum: string;
  phoneNumber: string;
  lastName: string;
  firstName: string;
  email: string;
  mailAddSameasCommunityAdd: boolean | null;
  mailingZipCode: string;
  mailingState: string;
  mailingCity: string;
  mailingStreet: string;
  mailingUnit: string;
  ownerPin: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPhoneNumber: string;
  emergencyEmail: string;
  communityAddr: any[];
  propertyId: string;
  [key: string]: any;
};

export function newRegistrationInfo(data?: any): registrationInfo {
  return {
    userId: data?.userId || undefined,
    mailAddSameasCommunityAdd: data?.mailAddSameasCommunityAdd || null,
    zipCode: data?.zipCode || undefined,
    state: data?.state || undefined,
    city: data?.city || undefined,
    street: data?.street || undefined,
    unit: data?.unit || '',
    phoneNumber: data?.phoneNumber || '',
    altPhoneNum: data?.altPhoneNum || '',
    lastName: data?.lastName || '',
    firstName: data?.firstName || '',
    email: data?.email || '',
    mailingZipCode: data?.mailingZipCode || '',
    mailingState: data?.mailingState || '',
    mailingCity: data?.mailingCity || '',
    mailingStreet: data?.mailingStreet || '',
    mailingUnit: data?.mailingUnit || '',
    ownerPin: data?.ownerPin || '',
    emergencyFirstName: data?.emergencyFirstName || '',
    emergencyLastName: data?.emergencyLastName || '',
    emergencyPhoneNumber: data?.emergencyPhoneNumber || '',
    emergencyEmail: data?.emergencyEmail || '',
    communityAddr: data?.communityAddr || [],
    propertyId: data?.propertyId || '',
  };
}
