import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import PassInfo from '../../common_lib_front/types/passInfo';
import PassForm, { passFormInput } from './passForm';
import style from './passFormCollection.module.css';

type PassFormColectionProps = {
  data: Array<passFormInput>;
  setData: React.Dispatch<React.SetStateAction<passFormInput[]>>;
  allowRemove?: boolean;
  showViewBtn?: boolean;
  showSaveBtn?: boolean;
  showMyPassesLink?: boolean;
  showTitle?: boolean;
  submitHandler?: () => void;
  removeHandler?: (o: { variables: { passId: string; vehicleId: string } }) => void;
};

export const PassFormCollection = (props: PassFormColectionProps): ReactElement => {
  const {
    data,
    setData,
    allowRemove,
    removeHandler,
    showViewBtn,
    showSaveBtn,
    showTitle,
    submitHandler,
    showMyPassesLink,
  } = props;

  const update = useCallback(
    (idx: number, obj: Record<string, unknown>) => {
      setData(prev => {
        const res = [...prev];
        const last = res[idx];
        if (!last) return prev;
        res[idx] = {
          ...last,
          ...obj,
          vehicle: {
            ...last.vehicle,
            ...(typeof obj.vehicle === 'object' ? obj.vehicle : {}),
          },
        };

        return res;
      });
    },
    [setData],
  );

  const remove = useCallback(
    (idx: number) => {
      setData(prev => {
        const res = [...prev];
        res.splice(idx, 1);
        return res;
      });
    },
    [setData],
  );

  const { t } = useTranslation();

  return (
    <div className={style.infoBox}>
      {showTitle ? (
        <>
          <h3 className={`${style.title} textColorSuperDark `}>{t('Pass Details')}</h3>
          <p className={`${style.subTitle} textColorSuperDark `}>
            {t('Enter pass details below for each pass being purchased')}
          </p>
          <p className={`${style.subTitle} ${style.textBold} textRed `}></p>
        </>
      ) : null}
      {data.map((elem: PassInfo, idx: number) => (
        // eslint-disable-next-line react/jsx-key
        <div className={style.collectionBox} key={idx + 'pass-form'}>
          <PassForm
            key={elem.passId ? elem.passId : idx}
            idx={idx}
            data={elem}
            // title={formTitle ? formTitle(elem, idx + 1) : `${t(`${idx + 1}. ${elem.passType}`)}`}
            removeHandler={() => {
              if (elem.passId && removeHandler) {
                removeHandler({
                  variables: {
                    passId: elem.passId,
                    vehicleId: elem.vehicle?.vehicleId || '',
                  },
                });
              }
              remove(idx);
            }}
            updateHandler={obj => update(idx, obj)}
            allowRemove={allowRemove}
            showViewBtn={showViewBtn}
          />
        </div>
      ))}
      <div className={style.btn}>
        {showSaveBtn ? (
          <GenericButton
            clickHandler={submitHandler}
            color="blue"
            size="large"
            title={t('Save Changes')}
          />
        ) : null}
      </div>
      <div className={style.btn}>
        {showMyPassesLink ? (
          <Link to="/resident/my-passes">
            <GenericButton outline="large" title={t('My Passes')} />
          </Link>
        ) : null}
      </div>
    </div>
  );
};
PassFormCollection.defaultProps = {
  allowRemove: true,
  showViewBtn: false,
  showSaveBtn: true,
  showTitle: false,
  submitHandler: undefined,
  removeHandler: undefined,
  showMyPassesLink: false,
};
export default PassFormCollection;
