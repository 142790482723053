import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import PassInfo, { newPassInfo } from '../common_lib_front/types/passInfo';
import RentalInfo from '../common_lib_front/types/rentalInfo';
import VehicleInfo, { newVehicleInfo } from '../common_lib_front/types/vehicleInfo';
import { backendClient } from '../common_lib_front/utilities/BackendAPI';

const GET_ALL_PASSES = gql`
  query GetPassesByRegistration($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        number
        passType
        startDate
        endDate
        status
        shared
        url
        registrationId
        paid
        passInfoId
        communityRental {
          address
        }
      }
    }
  }
`;

export type fetchedPass = {
  passId: string;
  number: string;
  passType: string;
  startDate: string;
  endDate: string;
  status: string;
  shared: boolean;
  url: string;
  registrationId: string;
  communityRental: RentalInfo;
};

type GET_ALL_PASSES_VARS = Record<string, unknown>;

type GET_ALL_PASSES_RES = {
  getPassesByRegistration: backendResponse<fetchedPass[]>;
};

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        year
        type
        color
        licensePlate
        fleetNumber
        isRental
        primaryDriverName
      }
    }
  }
`;

type GET_VEHICLE_VARS = {
  passId: string;
};

type GET_VEHICLE_RES = {
  getVehicle: backendResponse<{
    vehicleId: string;
    make: string;
    vehicleModel: string;
    type: string;
    color: string;
    licensePlate: string;
    fleetNumber: string;
    isRental: boolean;
    primaryDriverName: string;
  }>;
};

type vars = {
  registrationId: string;
};

type useGuestPassesType = {
  allPasses: Array<PassInfo>;
  activeTab: string;
  setActiveTab: (val: string) => void;
};
export default function useGuestPasses(variables: Partial<vars>): useGuestPassesType {
  const [allPasses, setAllPasses] = useState<Array<PassInfo>>([]);
  const [passes, setPasses] = useState<Array<PassInfo>>([]);
  const [activeTab, setActiveTab] = useState<string>('Current');

  const { data: passData, refetch } = useQuery<GET_ALL_PASSES_RES, GET_ALL_PASSES_VARS>(
    GET_ALL_PASSES,
    {
      fetchPolicy: 'network-only',
      variables: {
        registrationId: variables.registrationId,
        // passType: activeTab === 'amenity' ? 'wristband' : null,
      },
      onCompleted: async d => {
        if (!d.getPassesByRegistration.success) {
          return;
        }
        if (d.getPassesByRegistration.data) {
          const res = d.getPassesByRegistration.data
            .filter(p => !['single', 'Single'].includes(p.passType))
            .map(p => newPassInfo(p as Partial<PassInfo>));

          await Promise.all(
            res.map((element, idx: number) =>
              backendClient
                .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
                  fetchPolicy: 'network-only',
                  query: GET_VEHICLE,
                  variables: { passId: element.passId },
                })
                .then(v => {
                  res[idx].vehicle = newVehicleInfo(
                    v.data.getVehicle.data as Partial<VehicleInfo>,
                  );
                })
                .catch(e => {
                  console.log(e);
                }),
            ),
          );

          setPasses(res);
        }
      },
    },
  );

  useEffect(() => {
    // refetch();
    if (passes && passes.length > 0) {
      if (activeTab === 'amenity') {
        const passesForAmenity = passes.filter(ele => ele.passType === 'wristband');
        setAllPasses(passesForAmenity || []);
      } else {
        const passesNotForAmenity = passes.filter(ele => ele.passType !== 'wristband');
        setAllPasses(passesNotForAmenity || []);
      }
    }
  }, [activeTab, passes]);

  return {
    allPasses,
    activeTab,
    setActiveTab,
  };
}
