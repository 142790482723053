import { ReactElement } from 'react';
import MyRentalsGrid from '../../../components/myRentalsGrid/myRentalsGrid';
import style from './myRentals.module.css';

export default function MyRentals(): ReactElement {
  // const { rentals } = useMyRentals();
  return (
    <div className={style.container}>
      <div className={`${style.box} white`}>
        <div className={`${style.box__subHeader} mainColor `}>
          <div className={`${style.box__circle} white `} />
          <h1 className={`${style.box__title} textColorWhite `}>My Rentals</h1>
        </div>
        <div className={style.grid_box}>
          <MyRentalsGrid />
        </div>
        {/* <div className={style.flexBox}>
          <div className={style.radiusBox}>
            <table width="100%" cellSpacing="0" className={style.table}>
              <thead>
                <tr className={`${style.tableHeader} mainColor `}>
                  <th
                    className={`${style.tableHeader__item} textColorWhite  ${style.firstTh}`}
                  >
                    Property Address
                  </th>
                  <th className={`${style.tableHeader__item} textColorWhite `}>City</th>
                  <th className={`${style.tableHeader__item} textColorWhite `}>State</th>
                  <th className={`${style.tableHeader__item} textColorWhite `}>
                    Zip Code
                  </th>
                  <th
                    className={`${style.tableHeader__item} textColorWhite ${style.firstTh}`}
                  >
                    Rental Management Information
                  </th>
                  <th className={`${style.tableHeader__item} textColorWhite `}>
                    Registration Status
                  </th>
                  <th className={`${style.tableHeader__item} textColorWhite `}>
                    Occupancy Level
                  </th>
                  <th className={`${style.tableHeader__item} textColorWhite `}> </th>
                </tr>
              </thead>
              <tbody>
                {rentals.map((val: rentalUnitInfo) => (
                  <tr className={style.dataLine} key={val.rentalUnitId}>
                    <td>{val.address}</td>
                    <td>{val.city}</td>
                    <td>{val.state}</td>
                    <td>{val.zipCode}</td>
                    <td>
                      <DropDownUserInfo
                        id={`${val.rentalUnitId}`}
                        buttonText="Rental Management Information"
                        title="Rental Management Information:"
                        options={[
                          {
                            title: 'Company Name or Name if Self Managed:',
                            userInfo: val.companyName,
                          },
                          {
                            title: 'Primary Emergency Contact Name:',
                            userInfo: val.primaryEmergencyContactName,
                          },
                          {
                            title: 'Primary Emergency Phone Number:',
                            userInfo: val.primaryEmergencyPhone,
                          },
                          {
                            title: 'Secondary Emergency Phone Number:',
                            userInfo: val.secondaryEmergencyPhone,
                          },
                          {
                            title: 'Primary Emergency Contact Email Address:',
                            userInfo: val.email,
                          },
                        ]}
                      />
                    </td>
                    <td className={style.statusTd}>
                      <span
                        className={`${style.passStatus} 
                      ${val.passStatus === 'Pending Review' ? style.grey : ''}
                      ${val.passStatus === 'Active' ? style.green : ''}
                      ${val.passStatus === 'Rejected' ? style.pink : ''}
                      ${val.passStatus === 'Refunded' ? style.orange : ''}
                      ${val.passStatus === 'Expired' ? style.red : ''}`}
                      >
                        {val.passStatus === 'inactive'
                          ? 'Pending Review'
                          : val.passStatus}
                      </span>
                    </td>
                    <td>{val.occupancy}</td>
                    <td className={`${style.btnEdit}`}>
                      <Link
                        to={{
                          pathname: '/resident/my-rentals/edit-rental',
                          state: val,
                        }}
                      >
                        <GenericButton title="Edit" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}
