import * as Sentry from '@sentry/react';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Redirect,
  RedirectProps,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { CommunityContext } from './common_lib_front/communityConfigs/communityContextProvider';
import { ContactFormPopup } from './common_lib_front/components/contactForm/contactFormPopup';
import useSegmentIdentify from './common_lib_front/hooks/useSegmentIdentify';
import * as CommonPages from './common_lib_front/pages/index';
import store, { isLogedIn as checkIsLoggedIn } from './common_lib_front/utilities/store';
import useUserGuiding from './common_lib_front/utilities/useUserGuiding';
import EditProfileTemplate from './components/editProfileTemplate/editProfileTemplate';
import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import './css';
import useGetUserInfo from './hooks/useGetUserInfo';
import * as Pages from './pages/index';
import useChatbot from './utils/useChatbot';

const flatten = (routes: ReactElement): Array<ReactElement> => routes.props.children;

const RedirectWithLog = (props: RedirectProps): React.ReactElement => {
  Sentry.captureMessage('No route match redirect', {
    level: 'error',
    extra: {
      isLoggedIn: checkIsLoggedIn(),
    },
  });
  return <Redirect {...props} />;
};

export default function App(): ReactElement {
  const { featuresConfig, communityId } = useContext(CommunityContext);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(checkIsLoggedIn());
  const { userInfo } = useGetUserInfo();
  useEffect(
    () => store.subscribe(() => setIsLoggedIn(checkIsLoggedIn())),
    [setIsLoggedIn],
  );
  useChatbot('resident');
  useSegmentIdentify(isLoggedIn, userInfo);
  useUserGuiding('resident');

  function getHostRegistrationRoutes(
    featureConfigData: typeof featuresConfig,
  ): Array<ReactElement> | null {
    if (!featureConfigData?.host?.enabled) return null;
    const hostRegistrationRoutes = [
      <Route
        path="/resident/host-registration/:registrationId/host-information"
        key="host-information"
      >
        <Pages.RegistrationHostInfo />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/rental-units"
        key="host-registration-rental-units"
      >
        <Pages.RegistrationRentalUnits />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/education"
        key="host-registration-education"
      >
        <Pages.RegistrationEducation />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/documents"
        key="host-registration-documents"
      >
        <Pages.RegistrationDocuments />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/secure-checkout"
        key="host-registration-secure-checkout"
      >
        <Pages.RegistrationCheckout />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/generating-passes"
        key="host-registration-generating-passes"
      >
        <Pages.RegistrationGeneratingPasses />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/receipt/download"
        key="host-registration-download"
      >
        <Pages.RegistrationDownloadableReceipt />
      </Route>,
      <Route
        path="/resident/host-registration/:registrationId/receipt"
        key="host-registration-receipt"
      >
        <Pages.RegistrationReceipt />
      </Route>,
      <Route path="/resident/host-registration" key="host-registration">
        <Pages.RegistrationInitialization />
      </Route>,
      <Route exact path="/resident/my-rentals" key="my-rentals">
        <Pages.MyRegistrations />
      </Route>,
      <Route
        exact
        path="/resident/my-rentals/view-rental/:rentalUnitId"
        key="my-rentals-view"
      >
        <Pages.viewRental />
      </Route>,
      <Route
        exact
        path="/resident/my-rentals/:registrationId"
        key="my-rentals-registration"
      >
        <Pages.EditRental />
      </Route>,
    ];
    return hostRegistrationRoutes;
  }

  function getRentalRegistrationRoutes(
    featureConfigData: typeof featuresConfig,
  ): Array<ReactElement> | null {
    if (!featureConfigData?.host?.enabled) return null;
    // TODO change these routes to begin with /host
    const RentalRegistrationRoutes = [
      <Route
        path="/resident/rental-registration/:registrationId/rental-units"
        key="rental-registration"
      >
        <Pages.RentalInformation />
      </Route>,
      <Route
        path="/resident/rental-registration/:registrationId/secure-checkout"
        key="rental-registration-secure-checkout"
      >
        <Pages.RentalSecureCheckout />
      </Route>,
      <Route
        path="/resident/rental-registration/:registrationId/receipt"
        key="rental-registration-receipt"
      >
        <Pages.RentalReceipt />
      </Route>,
      <Route path="/resident/rental-registration" key="rental-registration">
        <Pages.RentalRegistrationInitialization />
      </Route>,
      <Route exact path="/resident/my-rentals/view-rental" key="my-rentals-view">
        <Pages.viewRental />
      </Route>,
      <Route
        exact
        path="/resident/my-rentals/:registrationId"
        key="my-rentals-registration"
      >
        <Pages.EditRental />
      </Route>,
    ];
    return RentalRegistrationRoutes;
  }

  function getMyPassesRoutes(
    featureConfigData: typeof featuresConfig,
  ): Array<ReactElement> | null {
    const routes: any[] = [];
    if (
      featureConfigData?.resident?.residentPasses?.enabled ||
      featureConfigData?.resident?.familyAndFriendsPasses?.enabled ||
      featureConfigData?.resident?.inviteVendor?.enabled
    ) {
      routes.push([
        <Route path="/resident/pass-details" key="pass-details">
          <Pages.PassDetails />
        </Route>,
        // <Route exact path="/resident/get-passes" key="get-passes">
        //   <Pages.GetPasses />
        // </Route>,
        <Route path="/resident/my-passes" key="my-passes">
          <Pages.MyPasses />
        </Route>,
        <Route path="/resident/refund-pass/:passId" key="refund-pass">
          <Pages.RefundPass />
        </Route>,
      ]);
    }
    if (featureConfigData?.resident?.familyAndFriendsPasses?.enabled) {
      routes.push([
        <Route
          exact
          path="/resident/get-passes/family-and-friends-pass"
          key="family-and-friends-pass"
        >
          <Pages.FamilyAndFriendsPass />
        </Route>,
      ]);
    }
    if (featureConfigData?.resident?.inviteVendor?.enabled) {
      routes.push([
        <Route exact path="/resident/vendor-pass" key="vendor-pass">
          <Pages.vendorPass />
        </Route>,
      ]);
    }
    if (featureConfigData?.legacySingleUsePassEnabled) {
      routes.push([
        <Route path="/resident/share-pass" key="share-pass">
          <Pages.SharePass />
        </Route>,
      ]);
    }
    // if (config.myPassesFeature) {
    //   passRoutes.unshift(
    //     <Route path="/resident/my-passes" key="my-passes">
    //       <Pages.MyPasses />
    //     </Route>,
    //   );
    // }
    return routes.length ? routes : null;
  }

  function getSharePassRoutes(
    featureConfigData: typeof featuresConfig,
  ): Array<ReactElement> | null {
    if (!featureConfigData?.gateCode) return null;
    const sharePassRoutes = [
      <Route path="/resident/owner-code" key="resident-owner-code">
        <Pages.OwnerCode />
      </Route>,
    ];
    return sharePassRoutes;
  }

  function getRegistrationProcess(
    featureConfigData: typeof featuresConfig,
  ): Array<ReactElement> | null {
    if (!featureConfigData?.resident?.enabled) return null;
    const registrationProcessRoutes = [
      <Route
        path="/resident/registration/:registrationId/information"
        key="resident-information"
      >
        <Pages.RegistrationInformation />
      </Route>,
      ...(featureConfigData?.resident?.registration?.educationStepEnabled
        ? [
          /* eslint-disable indent */
          <Route
            path="/resident/registration/:registrationId/education"
            key="education"
          >
            <Pages.RegistrationCommunityOrientation />
          </Route>,
        ]
        : []),
      ...(featureConfigData?.resident?.registration?.documentStepEnabled
        ? [
          <Route
            path="/resident/registration/:registrationId/documents"
            key="documents"
          >
            <Pages.RegistrationProcessDocuments />
          </Route>,
        ]
        : []),
      /* eslint-enable indent */
      <Route
        path="/resident/registration/:registrationId/secure-checkout"
        key="resident-secore-checkout"
      >
        <Pages.RegistrationSecureCheckout />
      </Route>,
      <Route
        path="/resident/registration/:registrationId/thank-you"
        key="resident-thank-you"
      >
        <Pages.RegistrationThankYouScreen />
      </Route>,
      <Route path="/resident/registration" key="registration-process">
        <Pages.RegistrationProcessInit />
      </Route>,
    ];

    return registrationProcessRoutes;
  }

  return (
    <div className="container">
      <Router basename={`/${communityId}`}>
        <Header />
        <Switch>
          <Route exact path="/create-account">
            <Pages.CreateAcount />
          </Route>
          <Route exact path="/create-account/options">
            <Pages.Options />
          </Route>
          <Route exact path="/login">
            <CommonPages.Login
              role="resident" // eslint-disable-line
              successHref="/resident"
            />
          </Route>
          <Route exact path="/forgot-password">
            <CommonPages.ForgotPasswordEmail />
          </Route>
          <Route exact path="/forgot-password/verification-code">
            <CommonPages.ForgotPasswordVerification />
          </Route>
          <Route path="/forgot-password/reset-password/:identifier">
            <CommonPages.ForgotPasswordReset />
          </Route>
          <Route path="/help">
            <CommonPages.HelpPage
              role="resident" // eslint-disable-line
            />
          </Route>
          <Route path="/guest/privacy-policy">
            <CommonPages.PrivacyPolicy />
          </Route>
          <Route path="/guest/terms-conditions">
            <CommonPages.TermsAndConditions />
          </Route>
          {isLoggedIn ? ( // check login
            flatten(
              <>
                <Route exact path="/resident/get-passes" key="get-passes">
                  <Pages.GetPasses />
                </Route>
                <Route path="/resident/edit-profile/my-profile">
                  <Pages.MyProfile />
                </Route>
                <Route path="/resident/edit-profile/reset-password">
                  <Pages.ResetPassword />
                </Route>
                <Route path="/resident/edit-profile/friends-and-family">
                  <Pages.FamilyAndfriends />
                </Route>
                <Route path="/resident/edit-profile/my-documents">
                  <Pages.MyDocuments />
                </Route>
                <Route path="/resident/edit-profile/payment-methods">
                  <Pages.PaymentMethods />
                </Route>
                <Route path="/resident/edit-profile/add-my-document">
                  <Pages.AddDocument />
                </Route>
                <Route path="/resident/edit-profile/delete-my-document/:docId">
                  <Pages.DeleteDocument />
                </Route>
                <Route path="/resident/edit-profile/add-friends-and-family">
                  {/* <Pages.AddFamilyAndfriends /> */}
                  <ContactFormPopup />
                </Route>
                <Route path="/resident/delete-member/:memberId">
                  <Pages.DeleteMember />
                </Route>
                <Route path="/resident/edit-family-member/:memberId">
                  {/* <Pages.EditMember /> */}
                  <ContactFormPopup />
                </Route>
                <Route path="/resident/edit-profile/my-vehicles">
                  <EditProfileTemplate active="myVehicles">
                    <CommonPages.VehicleList
                      addEditLink="/resident/edit-profile/add-edit-vehicle"
                      excludeFields={['fleetNumber']}
                    />
                  </EditProfileTemplate>
                </Route>
                <Route
                  path={[
                    '/resident/edit-profile/add-edit-vehicle/:vehicleId',
                    '/resident/edit-profile/add-edit-vehicle',
                  ]}
                >
                  <CommonPages.VehicleDataFormPopup
                    excludeFields={
                      communityId === 'wildheron'
                        ? [
                          'fleetNumber',
                          'year',
                          'insuranceFile',
                          'registrationFile',
                          'licensePlateState',
                        ]
                        : ['fleetNumber']
                    }
                  />
                </Route>
                <Route path="/resident/edit-profile">
                  <Redirect to="/resident/edit-profile/my-profile" />
                </Route>
                <Route path="/resident/share-pass/:passId">
                  <Pages.DeletePass />
                </Route>
                ,
                {
                  // rental-registration
                  getRentalRegistrationRoutes(featuresConfig)
                }
                {
                  // My Passes
                  getMyPassesRoutes(featuresConfig)
                }
                {
                  // Share Pass
                  getSharePassRoutes(featuresConfig)
                }
                {
                  // Host registration urls
                  getHostRegistrationRoutes(featuresConfig)
                }
                {
                  // resident registration process
                  getRegistrationProcess(featuresConfig)
                }
                {/* {
                  // resident pass routes
                  getResidentPassRoutes(config)
                } */}
                <Route
                  exact
                  path="/resident/get-passes/resident-pass/:registrationId/generate-passes"
                >
                  <Pages.GeneratePasses />
                </Route>
                <Route
                  exact
                  path="/resident/get-passes/resident-pass/:registrationId/secure-checkout"
                >
                  <Pages.SecureCheckout />
                </Route>
                <Route
                  exact
                  path="/resident/get-passes/resident-pass/:registrationId/vehicle/"
                >
                  <Pages.RegisterVehicle />
                </Route>
                <Route
                  exact
                  path="/resident/get-passes/resident-pass/:registrationId/community-orientation"
                >
                  <Pages.CommunityOrientation />
                </Route>
                <Route
                  exact
                  path="/resident/get-passes/resident-pass/:registrationId/pass-selection"
                >
                  <Pages.PassSelection />
                </Route>
                <Route
                  exact
                  path="/resident/get-passes/resident-pass/:registrationId/receipt"
                >
                  <Pages.OrderSummary />
                </Route>
                <Route exact path="/resident/get-passes/resident-pass">
                  <Pages.CreateRegistration />
                </Route>
                {/* resident routes */}
                <Route exact path="/resident">
                  <Pages.ResidentHome />
                </Route>
                <Route exact path="/host-portal">
                  <Pages.HostHome />
                </Route>
                <Route path="/invite-guest">
                  <Pages.InviteGuest />
                </Route>
                <Route exact path="/">
                  <RedirectWithLog to="/resident" />
                </Route>
              </>,
            )
          ) : (
            // default route if not logged in
            <Route path="/">
              <RedirectWithLog to="/login" />
            </Route>
          )}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}
