import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import {
  inviteGuestDataType,
  inviteGuestDataReducer,
  useInviteGuestReducer,
} from '../../../components/inviteGuestForm/useInviteGuestState';
import {
  GET_PASS_INFOS,
  GET_PASS_INFOS_RES,
  GET_PASS_INFOS_VARS,
  IGPassInfo,
} from './inviteGuestRequests';

export type InviteGuestDispatchType = React.Dispatch<
  Parameters<typeof inviteGuestDataReducer>['1']
>;

type useInviteGuestType = {
  data: inviteGuestDataType;
  editData: InviteGuestDispatchType;
  passInfoData: IGPassInfo[];
  passInfoError: string;
};

// === main hook
export default function useInviteGuest(): useInviteGuestType {
  const [data, editData] = useInviteGuestReducer();
  const { communityId } = useContext(CommunityContext);
  // const [pageNumber, setPageNumber] = useState<number>(0);

  const { data: passInfoData, error: passInfoError } = useQuery<
    GET_PASS_INFOS_RES,
    GET_PASS_INFOS_VARS
  >(GET_PASS_INFOS, {
    variables: {
      communityId,
    },
  });

  useEffect(() => {
    const firstGuestPassInfoId = passInfoData?.getPassInfosByCommunity?.data?.find(
      passInfo => passInfo.portal === 'guest',
    )?.passInfoId;
    if (!firstGuestPassInfoId) return;
    data.forEach((recipient, recipientIdx) => {
      recipient.passes.forEach((rental, rentalIdx) => {
        if (!rental.passInfoId) {
          editData({
            type: 'editRental',
            payload: {
              recipientIdx,
              rentalIdx,
              passData: {
                passInfoId: firstGuestPassInfoId,
              },
            },
          });
        }
      });
    });
  }, [passInfoData, data]);

  // const { data: myRentalData } = useQuery<GET_HOST_RENTALS_RES, GET_HOST_RENTALS_VARS>(
  //   GET_HOST_RENTALS,
  //   {
  //     fetchPolicy: 'network-only',
  //     variables: {
  //       hostInfoId: null,
  //       approvedOnly: true,
  //     },
  //   },
  // );

  return {
    data,
    editData,
    passInfoData:
      passInfoData?.getPassInfosByCommunity.data?.filter(pid => pid.portal === 'guest') ||
      [],
    passInfoError:
      passInfoData?.getPassInfosByCommunity.error ||
      (passInfoError ? 'Could not get pass configurations. Please try again later.' : ''),
  };
}
