import React, { ReactElement } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import style from './actionsBtn.module.css';

type ActionsBtnProps = {
  children: ReactElement;
};
export default function ActionsBtn(props: ActionsBtnProps): ReactElement {
  // const [open, setOpen] = useState<boolean>(false);
  const {
    open,
    setOpen,
    containerId: parentId,
  } = useContainerBlur('user-profile-btn', {
    autoCloseInnerButton: true,
  });
  const { children } = props;

  let btn;
  if (open === true) {
    btn = style.btnOpen;
  } else {
    btn = style.btnClose;
  }

  return (
    <div className={style.btnBox} id={parentId}>
      <div className={btn}>
        <GenericButton title="Actions" clickHandler={() => setOpen(!open)} />
      </div>
      {open && children}
    </div>
  );
}
