import { useReducer } from 'react';
import { InvitePassInfo, RecipientInfo } from './inviteGuestForm';

export type inviteGuestDataType = RecipientInfo[];

// === reducer used in hook
export function inviteGuestDataReducer(
  state: inviteGuestDataType,
  action: {
    type:
      | 'editRecipient'
      | 'editRental'
      | 'addRecipient'
      | 'addRental'
      | 'deleteRental'
      | 'deleteRecipient'
      | 'clear'
      | 'clearWillNotPay';
    payload: {
      recipientIdx?: number;
      rentalIdx?: number;
      recipientData?: Partial<RecipientInfo>;
      passData?: Partial<InvitePassInfo>;
    };
  },
): inviteGuestDataType {
  const { recipientIdx, rentalIdx, recipientData, passData } = action.payload;
  let res: inviteGuestDataType = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'editRecipient':
      if (recipientData && recipientIdx !== undefined) {
        const tmp = res[recipientIdx] || undefined;
        if (!tmp) break;
        tmp.companyId = recipientData.companyId ?? state[recipientIdx].companyId;
        tmp.companyName =
          typeof recipientData.companyName === 'string'
            ? recipientData.companyName
            : state[recipientIdx].companyName;
        tmp.firstName =
          typeof recipientData.firstName === 'string'
            ? recipientData.firstName
            : state[recipientIdx].firstName;
        tmp.lastName =
          typeof recipientData.lastName === 'string'
            ? recipientData.lastName
            : state[recipientIdx].lastName;
        tmp.email =
          typeof recipientData.email === 'string'
            ? recipientData.email
            : state[recipientIdx].email;
        tmp.phoneNumber =
          typeof recipientData.phoneNumber === 'string'
            ? recipientData.phoneNumber
            : state[recipientIdx].phoneNumber;
        tmp.willPayForPass =
          typeof recipientData.willPayForPass === 'boolean'
            ? recipientData.willPayForPass
            : state[recipientIdx].willPayForPass;
        tmp.registrationId =
          typeof recipientData.registrationId === 'string'
            ? recipientData.registrationId
            : state[recipientIdx].registrationId;
        tmp.arrivalDate =
          typeof recipientData.arrivalDate === 'string'
            ? recipientData.arrivalDate
            : state[recipientIdx].arrivalDate;
        tmp.departureDate =
          typeof recipientData.departureDate === 'string'
            ? recipientData.departureDate
            : state[recipientIdx].departureDate;
        tmp.address =
          typeof recipientData.address === 'string'
            ? recipientData.address
            : state[recipientIdx].address;
      } else {
        throw Error(`${action.type} required information not provided`);
      }
      break;
    case 'editRental':
      if (recipientIdx !== undefined && rentalIdx !== undefined && passData) {
        res[recipientIdx].passes[rentalIdx].startDate =
          typeof passData.startDate === 'string'
            ? passData.startDate
            : state[recipientIdx].passes[rentalIdx].startDate;
        res[recipientIdx].passes[rentalIdx].endDate =
          typeof passData.endDate === 'string'
            ? passData.endDate
            : state[recipientIdx].passes[rentalIdx].endDate;
        res[recipientIdx].passes[rentalIdx].passInfoId =
          typeof passData.passInfoId === 'string'
            ? passData.passInfoId
            : state[recipientIdx].passes[rentalIdx].passInfoId;
        res[recipientIdx].passes[rentalIdx].passId =
          typeof passData.passId === 'string'
            ? passData.passId
            : state[recipientIdx].passes[rentalIdx].passId;
      } else {
        throw Error(`${action.type} required information not provided`);
      }
      break;
    case 'addRecipient':
      const passNumber =
        recipientData?.numberOfPasses && parseInt(recipientData?.numberOfPasses)
          ? parseInt(recipientData?.numberOfPasses)
          : 1;

      const passDetails = {
        passInfoId: passData?.passInfoId || '',
        startDate: passData?.startDate || recipientData?.arrivalDate || '',
        endDate: passData?.endDate || recipientData?.departureDate || '',
      };

      res.push({
        companyId: recipientData?.companyId || '',
        companyName: recipientData?.companyName || '',
        firstName: recipientData?.firstName || '',
        lastName: recipientData?.lastName || '',
        email: recipientData?.email || '',
        phoneNumber: recipientData?.phoneNumber || '',
        willPayForPass: recipientData?.willPayForPass || false,
        arrivalDate: recipientData?.arrivalDate || '',
        departureDate: recipientData?.departureDate || '',
        address: recipientData?.address || '',
        passes: Array(passNumber).fill(passDetails),
      });
      break;
    case 'addRental':
      if (recipientIdx !== undefined) {
        res[recipientIdx].passes.push({
          startDate: passData?.startDate || '',
          endDate: passData?.endDate || '',
          passInfoId: passData?.passInfoId || '',
        });
      } else {
        throw Error(`${action.type} required information not provided`);
      }
      break;
    case 'deleteRecipient':
      if (recipientIdx !== undefined) {
        res.splice(recipientIdx, 1);
      } else {
        throw Error(`${action.type} required information not provided`);
      }
      break;
    case 'deleteRental':
      if (recipientIdx !== undefined && rentalIdx !== undefined) {
        res[recipientIdx].passes.splice(rentalIdx, 1);
      } else {
        throw Error(`${action.type} required information not provided`);
      }
      break;
    case 'clear':
      res = [];
      break;
    case 'clearWillNotPay':
      res = res.filter(ri => ri.willPayForPass);
      break;
    default:
      break;
  }
  return res;
}

export const useInviteGuestReducer = () => useReducer(inviteGuestDataReducer, []);
