import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import PassShareInfo, {
  newPassShareInfo,
} from '../../../common_lib_front/types/passShareInfo';
import UserInfo from '../../../common_lib_front/types/userInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const CREATE_SINGLE_USE_PASS = gql`
  mutation CreateSingleUsePass {
    registerPass(
      status: "Ready"
      passType: "single"
      registrationId: "resident-single-use-pass"
    ) {
      success
      error
      data {
        passId
      }
    }
  }
`;

const CREATE_SHARE_PASS = gql`
  mutation CreateSharePass(
    $phone: String
    $email: String
    $companyName: String
    $lastName: String!
    $firstName: String!
    $passId: String!
  ) {
    createSharePass(
      phone: $phone
      email: $email
      companyName: $companyName
      lastName: $lastName
      firstName: $firstName
      passId: $passId
    ) {
      success
      error
    }
  }
`;

const SEND_PASS = gql`
  query SendPass(
    $passId: String!
    $phone: String
    $email: String
    $firstName: String!
    $lastName: String!
  ) {
    sendPass(
      passId: $passId
      phone: $phone
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      error
    }
  }
`;

const GET_SHARED_PASSES = gql`
  query GetShareHistory {
    sharePassHistory {
      success
      error
      data {
        passId
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

const DELETE_PASS = gql`
  mutation DeletePass($passId: String!) {
    deletePass(passId: $passId) {
      success
      error
    }
  }
`;

type SharePass = {
  alert: string;
  alertColor: 'red' | 'green';
  shareHistory: Array<PassShareInfo>;
  doShare: (data: Array<UserInfo>) => void;
  doReShare: (data: PassShareInfo) => void;
  doDelete: (d: { variables: { passId: string } }) => void;
};

export default function useSharePass(): SharePass {
  const [shareHistory, setShareHistory] = useState<Array<PassShareInfo>>([]);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const hiddenAlert = () => {
    setTimeout(() => {
      setAlert('');
    }, 4000);
  };

  // helper function to set alert message and color
  const handleAlert = (msg: string, color: 'red' | 'green' = 'red') => {
    setAlert(msg);
    setAlertColor(color);
    hiddenAlert();
  };
  // hook to fetch pass share history
  const { refetch } = useQuery(GET_SHARED_PASSES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: () => {
      console.log('fetch error');
    },
    onCompleted: d => {
      if (d.sharePassHistory.success) {
        setShareHistory(
          d.sharePassHistory.data.map((elem: PassShareInfo) => newPassShareInfo(elem)),
        );
      }
    },
  });
  // helper function to refetch share pass history and set the results in state
  const refetchShareHistory = () => {
    refetch()
      .then(res => {
        console.log('refetch results', res);
        if (res.data?.sharePassHistory?.success) {
          setShareHistory(res.data.sharePassHistory.data);
        }
      })
      .catch(() => {
        console.log('Could not refetch');
      });
  };
  // hook to delete pass
  const [doDelete] = useMutation(DELETE_PASS, {
    onCompleted: async () => {
      refetchShareHistory();
    },
  });
  // hook to send pass
  const [sendPass] = useLazyQuery(SEND_PASS, {
    fetchPolicy: 'no-cache',
    onError: (e: ApolloError) => {
      handleAlert(e.message, 'red');
      refetchShareHistory();
    },
    onCompleted: d => {
      if (d.sendPass.success) {
        handleAlert('Pass resent successfully', 'green');
      } else {
        handleAlert(d.sendPass.error, 'red');
      }
      refetchShareHistory();
    },
  });
  // helper function to resend a shared pass
  const doReShare = (data: PassShareInfo): void => {
    sendPass({
      variables: {
        firstName: data.firstName,
        lastName: data.lastName,
        passId: data.passId,
        email: data.email || null,
        phone: data.phone || null,
      },
    });
  };
  // helper function to create a single use pass and share it
  const doShare = (data: Array<UserInfo>): void => {
    // check for completeness
    if (!data.every(u => u.firstName && u.lastName && (u.phoneNumber || u.email))) {
      handleAlert(
        'Please fill out the information for the intended recipient with at least one method of contact',
      );
      return;
    }
    // create single use pass
    data.forEach((user: UserInfo) => {
      backendClient
        .mutate({
          mutation: CREATE_SINGLE_USE_PASS,
          variables: {},
        })
        .then(d => {
          // console.log(d);
          if (d.data.registerPass.success) {
            // share single use pass
            backendClient
              .mutate({
                mutation: CREATE_SHARE_PASS,
                variables: {
                  passId: d.data.registerPass.data[0].passId,
                  phone: user.phoneNumber || null,
                  email: user.email || null,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  companyName: user.companyName,
                },
              })
              .then(d2 => {
                if (d2.data.createSharePass.success) {
                  handleAlert('Pass shared successfully', 'green');
                  refetchShareHistory();
                } else {
                  handleAlert(d2.data.createSharePass.error);
                }
              })
              .catch((e: ApolloError) => handleAlert(e.message));
          } else {
            handleAlert(d.data.registerPass.error);
          }
        })
        .catch((e: ApolloError) => handleAlert(e.message));
    });
  };

  return {
    alert,
    alertColor,
    shareHistory,
    doReShare,
    doShare,
    doDelete,
  };
}
