import {
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { SetStateAction } from 'react';
import { ImageUploadWithPreview } from '../imageUploadWithPreview/imageUploadWithPreview';
import PhoneInputWrapper from '../phoneInputWrapper/phoneInputWrapper';
import { useInputConfig } from './communityConfigToRemove';
import { contactType, relationships } from './contactType';

export type CIProps = {
  data: contactType;
  setData: React.Dispatch<SetStateAction<contactType>>;
};

export function ContactInputs({ data, setData }: CIProps): React.ReactElement {
  const [inputCfg, configSwitch] = useInputConfig();

  console.log('Input config:', inputCfg);

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="space-between"
      style={{
        marginTop: '30px',
        paddingLeft: '40px',
        paddingRight: '40px',
      }}
    >
      <Grid
        xs={12}
        md={inputCfg?.inputs?.file?.hidden ? 12 : 6}
        container
        item
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        {Boolean(configSwitch?.cfgLabels && configSwitch.cfgLabels.length > 1) && (
          <Grid
            xs={12}
            // spacing={2}
            // item
            // container
            style={{
              paddingLeft: '15px',
              marginBottom: '10px',
            }}
          >
            {inputCfg?.formSelectLabel && (
              <FormLabel id="form-type-select">{inputCfg?.formSelectLabel}</FormLabel>
            )}
            <RadioGroup
              aria-labelledby="form-type-select"
              value={configSwitch?.cfgIdx}
              style={{
                paddingLeft: '15px',
              }}
              onChange={e => configSwitch?.setCfgIdx(e.target.value)}
            >
              {configSwitch?.cfgLabels?.map(cfgL => (
                <FormControlLabel
                  key={cfgL}
                  label={configSwitch?.inputCfgs[cfgL]?.description || ''}
                  control={<Radio />}
                  value={cfgL}
                />
              ))}
            </RadioGroup>
          </Grid>
        )}
        {!inputCfg?.inputs?.firstName?.hidden && (
          <Grid item xs={12} md={6}>
            <TextField
              label={inputCfg?.inputs?.firstName?.label || 'First Name'}
              required={inputCfg?.inputs?.firstName?.required ?? true}
              hidden={inputCfg?.inputs?.firstName?.hidden}
              name="firstName"
              value={data.firstName}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  firstName: e.target.value,
                }))
              }
            />
          </Grid>
        )}
        {!inputCfg?.inputs?.lastName?.hidden && (
          <Grid item xs={12} md={6}>
            <TextField
              label={inputCfg?.inputs?.lastName?.label || 'Last Name'}
              required={inputCfg?.inputs?.lastName?.required ?? true}
              hidden={inputCfg?.inputs?.lastName?.hidden}
              name="lastName"
              value={data.lastName}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  lastName: e.target.value,
                }))
              }
            />
          </Grid>
        )}
        {!inputCfg?.inputs?.relationship?.hidden && (
          <Grid item xs={12} md={6}>
            <InputLabel id="relationship-label">Relationship</InputLabel>
            <NativeSelect
              defaultValue={data.relationship}
              onChange={e => {
                setData(prev => ({
                  ...prev,
                  relationship: e.target.value,
                }));
              }}
            >
              {relationships.map((relationship) => <option value={relationship} key={relationship}>{relationship}</option>)}
            </NativeSelect>
          </Grid>
        )}
        {!inputCfg?.inputs?.email?.hidden && (
          <Grid item xs={12}>
            <TextField
              label={inputCfg?.inputs?.email?.label || 'Email'}
              required={inputCfg?.inputs?.email?.required ?? true}
              hidden={inputCfg?.inputs?.email?.hidden}
              name="email"
              type="email"
              value={data.email}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
            />
          </Grid>
        )}
        {!inputCfg?.inputs?.phoneNumber?.hidden && (
          <Grid item xs={12}>
            <PhoneInputWrapper
              material
              inputTitle={`Phone Number${
                inputCfg?.inputs?.phoneNumber?.required ? ' *' : ''
              }`}
              value={data.phoneNumber}
              changeHandler={val =>
                setData(prev => ({
                  ...prev,
                  phoneNumber: val,
                }))
              }
            />
          </Grid>
        )}
        {!inputCfg?.inputs?.idNum?.hidden && (
          <Grid item xs={12}>
            <TextField
              label={inputCfg?.inputs?.idNum?.label || 'ID Number'}
              required={inputCfg?.inputs?.idNum?.required}
              hidden={inputCfg?.inputs?.idNum?.hidden}
              name="idNum"
              value={data.idNum}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  idNum: e.target.value,
                }))
              }
            />
          </Grid>
        )}
        {!inputCfg?.inputs?.idState?.hidden && (
          <Grid item xs={12}>
            <TextField
              label={inputCfg?.inputs?.idState?.label || 'ID State'}
              required={inputCfg?.inputs?.idState?.required}
              name="idState"
              value={data.idState}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  idState: e.target.value,
                }))
              }
            />
          </Grid>
        )}
        {!inputCfg?.inputs?.idExp?.hidden && (
          <Grid item xs={12}>
            <TextField
              label={inputCfg?.inputs?.idExp?.label || 'ID Exp'}
              required={inputCfg?.inputs?.idExp?.required}
              name="idExp"
              type="date"
              value={data.idExp}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  idExp: e.target.value,
                }))
              }
            />
          </Grid>
        )}
      </Grid>
      {!inputCfg?.inputs?.file?.hidden && (
        <Grid item xs={12} md={6}>
          <ImageUploadWithPreview
            value={data.file}
            name={data.fileName}
            buttonText={`${inputCfg?.inputs?.file?.label || 'Select File'}${
              inputCfg?.inputs?.file?.required ? ' *' : ''
            }`}
            required={inputCfg?.inputs?.file?.required}
            hidden={inputCfg?.inputs?.file?.hidden}
            onChange={file =>
              setData(prev => ({
                ...prev,
                file: file.file,
                fileName: file.name,
                fileIsNew: true,
              }))
            }
          />
        </Grid>
      )}
    </Grid>
  );
}
