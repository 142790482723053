import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './hostPassSummary.module.css';

interface Props {
  registrationId: string;
  allowEdit?: boolean;
}

export default function HostPassSummary(props: Props): ReactElement {
  const { registrationId, allowEdit } = props;
  const history = useHistory();

  return (
    <div className={style.infoBox}>
      <div className={style.summaryBox}>
        <div className={style.box}>
          {[1, 1, 1].map((elem, idx) => (
            <div key={idx}>
              <div className={style.titleBox}>
                <h6 className={`${style.title} textColorDark `}>
                  {idx === undefined ? '' : ` ${idx + 1}.`}
                  Transportation Pass
                </h6>
                <div>
                  {allowEdit ? (
                    <div className={style.editBtn}>
                      <GenericButton
                        shape="circle"
                        icon={className => <CloseImg className={`${className}`} />}
                        title=""
                        clickHandler={() => {
                          history.replace(
                            `/resident/host-registration/${registrationId}/rental-units`,
                          );
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={style.container}>
                <div className={style.tableBox}>
                  <table className={style.table}>
                    <thead>
                      <tr className={style.trBox}>
                        <th className={`${style.tHead} textColorDark `}>Start Date</th>
                        <th className={`${style.tHead} textColorDark `}>Valid Through</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={style.trBox}>
                        <td className={`${style.tHeadSpace} textColorDark `}>
                          Tue, Feb 16, 2021
                        </td>
                        <td className={`${style.tHeadSpace} textColorDark `}>
                          Tue, Feb 16, 2022
                        </td>
                      </tr>
                    </tbody>
                    <div className={style.titleBox}>
                      <h6 className={`${style.title} textColorDark `}>
                        Rental Unit Information
                      </h6>
                    </div>
                    <thead>
                      <tr className={style.trBox}>
                        <th className={`${style.tHead} textColorDark `}>
                          Street Address
                        </th>
                        <th className={`${style.tHead} textColorDark `}>City</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={style.trBox}>
                        <td className={`${style.tHead} textColorDark `}>123 ex way</td>
                        <td className={`${style.tHead} textColorDark `}>Logan</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr className={style.trBox}>
                        <th className={`${style.tHead} textColorDark `}>State</th>
                        <th className={`${style.tHead} textColorDark `}>Zip Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={style.trBox}>
                        <td className={`${style.tHead} textColorDark `}>Utah</td>
                        <td className={`${style.tHead} textColorDark `}>84321</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <table className={style.tableBelow}>
                  <tr>
                    <th>Subtotal</th>
                    <td>$150</td>
                  </tr>
                  <tr>
                    <th>Platform Fee</th>
                    <td>$15</td>
                  </tr>
                  <tr>
                    <th>Credit Card Fee</th>
                    <td>2.99%</td>
                  </tr>
                </table>
              </div>
            </div>
          ))}
          <div className={style.totalBox}>
            <h1 className={`${style.total}`}>
              <h4 className={`${style.textLeft} textColorDark `}>Total</h4>{' '}
              <h4 className={`${style.textRight} textColorDark `}>$150</h4>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
HostPassSummary.defaultProps = {
  allowEdit: false,
};
