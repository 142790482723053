import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import store from '../../common_lib_front/utilities/store';
import style from './inviteGuestNav.module.css';

type InviteGuestNavProps = {
  activeTab?: 'invite' | 'list' | 'fast-pass' | 'fast-pass-list';
  children?: ReactElement | Array<ReactElement>;
};
export default function InviteGuestNav(props: InviteGuestNavProps): ReactElement {
  const { activeTab, children } = props;
  const [showFastPassFeatures, setShowFastPassFeatures] = useState<boolean>(
    !!store.getState().user?.fastPassUser,
  );
  useEffect(
    () =>
      store.subscribe(() => {
        setShowFastPassFeatures(!!store.getState().user?.fastPassUser);
      }),
    [setShowFastPassFeatures],
  );

  return (
    <div className={`${style.innerBox} white `}>
      <div className={style.tabBox}>
        <div className={style.tab}>
          <Link to="/invite-guest">
            <div className={activeTab === 'invite' ? style.active : style.inactive}>
              Invite a Guest
            </div>
          </Link>
          <div className={activeTab === 'invite' ? style.tabLineActive : style.tabLine} />
        </div>
        <div className={style.tab}>
          <Link to="/invite-guest/guest-list">
            <div className={activeTab === 'list' ? style.active : style.inactive}>
              Guest List
            </div>
          </Link>
          <div className={activeTab === 'list' ? style.tabLineActive : style.tabLine} />
        </div>
        {showFastPassFeatures && (
          <>
            <div className={style.tab}>
              <Link to="/invite-guest/fast-pass">
                <div
                  className={activeTab === 'fast-pass' ? style.active : style.inactive}
                >
                  Fast Pass
                </div>
              </Link>
              <div
                className={
                  activeTab === 'fast-pass' ? style.tabLineActive : style.tabLine
                }
              />
            </div>
            <div className={style.tab}>
              <Link to="/invite-guest/fast-pass-list">
                <div
                  className={
                    activeTab === 'fast-pass-list' ? style.active : style.inactive
                  }
                >
                  Fast Pass List
                </div>
              </Link>
              <div
                className={
                  activeTab === 'fast-pass-list' ? style.tabLineActive : style.tabLine
                }
              />
            </div>
          </>
        )}
      </div>
      {children}
    </div>
  );
}
InviteGuestNav.defaultProps = {
  activeTab: undefined,
  children: undefined,
};
