import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { paymentInfo } from '../../../common_lib_front/types/paymentInfo';
import store from '../../../common_lib_front/utilities/store';

const GET_USER_PAYMENT_METHODS = gql`
  mutation getPaymentMethod($userId: String!) {
    getPaymentMethod(userId: $userId) {
      success
      data {
        id
        type
        card {
          last4
          brand
        }
        us_bank_account {
          bank_name
          last4
        }
      }
      error
    }
  }
`;

const DELETE_USER_PAYMENT_METHODS = gql`
  mutation deletePaymentMethod(
    $userId: String!
    $paymentMethodId: String!
    $paymentMethodType: String!
  ) {
    deletePaymentMethod(
      userId: $userId
      paymentMethodId: $paymentMethodId
      paymentMethodType: $paymentMethodType
    ) {
      success
    }
  }
`;

const GENERATE_RENTAL_UNIT_SLUG = gql`
  mutation generateRentalUnitSlug(
    $rentalUnitId: String!
    $type: String!
    $value: Boolean!
  ) {
    generateRentalUnitSlug(rentalUnitId: $rentalUnitId, type: $type, value: $value) {
      success
    }
  }
`;
type useMyRentalsRes = {
  history: any;
  paymentInfo: paymentInfo | undefined;
  deletePayment: (paymentMethodId: string, paymentMethodType: string) => void;
  generateSlug: (rentalUnitId: string, type: string, value: boolean) => void;
  fetchPaymentMethods: () => void;
};
export default function usePayments(): useMyRentalsRes {
  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = useState<paymentInfo | undefined>();

  const [doFetch] = useMutation(GET_USER_PAYMENT_METHODS, {
    onError: e => {
      console.log(e.message);
      setPaymentInfo(undefined);
    },
    onCompleted: d => {
      if (d?.getPaymentMethod?.success) {
        setPaymentInfo(d.getPaymentMethod.data);
      } else {
        console.log('error');
        setPaymentInfo(undefined);
      }
    },
  });
  const fetchPaymentMethods = async () => {
    doFetch({
      variables: {
        userId: store.getState().user?.userId || '',
      },
    });
  };
  const [doDelete] = useMutation(DELETE_USER_PAYMENT_METHODS, {
    onError: e => {
      console.log(e.message);
    },
    onCompleted: d => {
      if (d.getPaymentMethod.success) {
        setPaymentInfo(d.getPaymentMethod.data);
      } else {
        console.log('error');
      }
    },
  });
  const deletePayment = async (paymentMethodId: string, paymentMethodType: string) => {
    await doDelete({
      variables: {
        userId: store.getState().user?.userId || '',
        paymentMethodId,
        paymentMethodType,
      },
    });
  };
  const [doGenerate] = useMutation(GENERATE_RENTAL_UNIT_SLUG, {
    onError: e => {
      console.log(e.message);
    },
    onCompleted: d => {
      if (d.getPaymentMethod.success) {
        setPaymentInfo(d.getPaymentMethod.data);
      } else {
        console.log('error');
      }
    },
  });
  const generateSlug = async (rentalUnitId: string, type: string, value: boolean) => {
    await doGenerate({
      variables: {
        rentalUnitId,
        type,
        value,
      },
    });
  };

  useEffect(() => {
    if (store.getState().user?.userId) fetchPaymentMethods();
  }, []);

  return {
    history,
    paymentInfo,
    deletePayment,
    generateSlug,
    fetchPaymentMethods,
  };
}
