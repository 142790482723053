import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  newRegistrationInfo,
  registrationInfo,
} from '../../common_lib_front/types/registrationInformation';
import style from './residentInformationReview.module.css';

type ResidentInformationReviewProps = {
  data: registrationInfo;
  editLink?: string;
  registrationId: string;
};

const GET_INFO = gql`
  query GetCheckoutInfo {
    getResidentProfile {
      success
      error
      data {
        userId
        firstName
        lastName
        email
        phoneNumber
        altPhoneNum
        mailingStreet
        mailingUnit
        mailingCity
        mailingState
        mailingZipCode
        emergencyFirstName
        emergencyLastName
        emergencyPhoneNumber
        emergencyEmail
        ownerPin
      }
    }
    getPropertiesByUser {
      success
      error
      data {
        zipCode
        state
        city
        street
        unit
      }
    }
  }
`;

export const ResidentInformationReview = (
  props: ResidentInformationReviewProps,
): ReactElement => {
  const [editMode, setEditMode] = useState(false);
  const [residentData, setResidentData] = useState<registrationInfo>(
    newRegistrationInfo(),
  );

  const {
    data: allData,
    loading: allLoading,
    error: allError,
  } = useQuery(GET_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getResidentProfile.success) {
        setResidentData(d.getResidentProfile.data);
      }
    },
  });
  const { t } = useTranslation();

  const titles = [
    { title: 'NAME', key: 'name' },
    { title: 'EMAIL ADDRESS', key: 'email' },
    { title: 'PHONE # (MOBILE)', key: 'phoneNumber' },
    { title: 'PHONE # (HOME)', key: 'altPhoneNum' },
    { title: 'STREET', key: 'street' },
    { title: 'APT / UNIT', key: 'unit' },
    { title: 'CITY', key: 'city' },
    { title: 'STATE', key: 'state' },
    { title: 'ZIP CODE', key: 'zipCode' },
  ];

  const getValue = (key: string): string | undefined => {
    if (key === 'name') {
      return `${residentData?.firstName} ${residentData?.lastName}`;
    }
    if (['street', 'city', 'unit', 'zipCode', 'state'].indexOf(key) > -1) {
      if (allData && allData.getPropertiesByUser && allData.getPropertiesByUser.success) {
        return allData.getPropertiesByUser.data[
          allData.getPropertiesByUser?.data?.length - 1
        ]?.[key];
      }
    }
    return residentData?.[key];
  };

  return (
    <div className={style.infoFormBox}>
      <h3 className={`${style.reviewTitle} textColorSuperDark `}>
        {t('Review Information')}
      </h3>
      <p className={`${style.subTitle} textColorSuperDark `}>
        {t('Please verify all information below is correct')}
      </p>
      <div className={`${style.header} superDarkGrey `}>
        <h5 className={`${style.title} textColorWhite `}>{t('Resident Information')}</h5>
        {/* <div className={style.btnEdit}>
          <GenericButton
            color="transparent"
            title={t('Edit')}
            clickHandler={() => {
              if (editLink) {
                history.push(editLink);
              } else {
                setEditMode(!editMode);
              }
            }}
          />
        </div> */}
      </div>
      <div>
        {titles.map(e => (
          // eslint-disable-next-line react/jsx-key
          <div className={style.companyInfo__row}>
            <div className={style.companyInfo__label}>{t(e.title)}</div>
            <div>{getValue(e.key)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
ResidentInformationReview.defaultProps = {
  editLink: '',
};

export default ResidentInformationReview;
