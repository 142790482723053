import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ADDRESS_VALIDATION } from '../../../common_lib_front/hooks/useAddressValidation';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { rentalUnitInfo } from '../../../common_lib_front/types/rentalUnitInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
const GET_RENTALS = gql`
  query Query($registrationId: String!) {
    getRentalByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        rentalUnitId
        address
        city
        state
        zipCode
        companyName
        primaryEmergencyContactName
        primaryEmergencyPhone
        secondaryEmergencyPhone
        propertyManagerName
        propertyManagerPhone
        email
      }
    }
  }
`;

const EDIT_RENTAL = gql`
  mutation editOrInsertRentalUnits(
    $registrationId: String!
    $rentalList: RentalUnitDataInput!
  ) {
    editOrInsertRentalUnits(registrationId: $registrationId, rentalList: $rentalList) {
      success
      error
      data {
        rentalUnitId
        address
        city
        state
        zipCode
        companyName
        primaryEmergencyContactName
        primaryEmergencyPhone
        secondaryEmergencyPhone
        email
      }
    }
  }
`;

type EDIT_RENTAL_VARS = {
  registrationId: string;
  rentalList: { data: Omit<rentalUnitInfo, 'passStatus' | 'price'>[] };
};

type EDIT_RENTAL_RES = {
  editOrInsertRentalUnits: backendResponse<{
    rentalUnitId: string;
    price: number;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    companyName: string;
    primaryEmergencyContactName: string;
    primaryEmergencyPhone: string;
    secondaryEmergencyPhone: string;
    email: string;
  }>;
};

const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber($registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: 1
      registrationId: $registrationId
      registrationType: "rental"
    ) {
      success
      error
    }
  }
`;

const DELETE_RENTAL = gql`
  mutation Mutation($rentalUnitId: String!) {
    deleteRental(rentalUnitId: $rentalUnitId) {
      success
      error
    }
  }
`;

type useRentalInformationRes = {
  registrationId: string;
  rentals: Array<rentalUnitInfo>;
  setRentals: (val: Array<rentalUnitInfo>) => void;
  submitHandler: () => Promise<any>;
  setCopyIdx: (idx: number) => void;
  doCopy: (idx: number) => void;
  fetchAddressValidation: (val: string) => Promise<Array<string>> | undefined;
  searchSelectorHandler: (idx: number, val: string) => void;
  error: string;
  doDeleteRental: (id: string) => void;
  copyIdx: number | null;
};

export default function useRentalInformation(): useRentalInformationRes {
  const history = useHistory();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [rentals, setRentals] = useState<Array<rentalUnitInfo>>([]);
  const [error, setError] = useState<string>('');

  const [selectData, setSelectData] = useState<Array<any>>([]);

  const [copyIdx, setCopyIdx] = useState<number | null>(null);
  const doCopy = useCallback(
    (idx: number) => {
      if (copyIdx === null) return;
      const res = [...rentals];
      [
        'companyName',
        'primaryEmergencyContactName',
        'primaryEmergencyPhone',
        'secondaryEmergencyPhone',
        'email',
        'propertyManagerName',
        'propertyManagerPhone',
      ].forEach((k: string) => {
        res[idx][k as keyof rentalUnitInfo] = res[copyIdx][k as keyof rentalUnitInfo];
      });
      setRentals(res);
    },
    [copyIdx, rentals],
  );

  useEffect(() => {
    const removeTypeName = [...rentals];
    if (removeTypeName.some(rental => '__typename' in rental)) {
      removeTypeName.forEach(rental => {
        if ('__typename' in rental) delete rental.__typename;
      });
      setRentals(removeTypeName);
    }
  }, [rentals]);

  // const {
  //   data: rentalsData, loading: rentalsLoading, error: rentalsError,
  // } =
  useQuery(GET_RENTALS, {
    fetchPolicy: 'network-only',
    variables: { registrationId },
    onCompleted: d => {
      if (d.getRentalByRegistration.success) {
        const noTypename = d.getRentalByRegistration.data.map((e: any) => {
          const { ...data } = e;
          return data;
        });
        setRentals(noTypename);
      }
    },
  });
  const [doEdit] = useMutation<EDIT_RENTAL_RES, EDIT_RENTAL_VARS>(EDIT_RENTAL);
  const [doAdvanceStep] = useMutation(EDIT_STEP_NUMBER);
  const editAll = useCallback(
    async () =>
      doEdit({
        variables: {
          registrationId,
          rentalList: {
            data: rentals.map(val => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { passStatus, price, occupancy, startDate, endDate, ...allowed } =
                val;
              return allowed;
            }),
          },
        },
      }),
    [doEdit, registrationId, rentals],
  );
  const submitHandler = useCallback(async () => {
    // error checking
    const requiredFields = ['address', 'primaryEmergencyPhone'];
    for (let i = 0; i < rentals.length; i += 1) {
      const rental = rentals[i];
      for (let j = 0; j < requiredFields.length; j += 1) {
        const val = rental[requiredFields[j]];
        if (!val || val?.length < 3) {
          setError('Please completely fill out all information for each rental');
          return;
        }
      }
    }
    // backend interaction
    Promise.all([editAll(), doAdvanceStep({ variables: { registrationId } })])
      .then(res => {
        if (res[0].data?.editOrInsertRentalUnits.error) {
          setError(res[0].data?.editOrInsertRentalUnits.error);
        } else {
          history.replace(
            `/resident/rental-registration/${registrationId}/secure-checkout`,
          );
        }
      })
      .catch(() => {
        setError('Something went wrong. Data may not be saved');
      });
  }, [doAdvanceStep, editAll, history, registrationId, rentals]);

  const fetchAddressValidation = useCallback((val: string) => {
    if (val.length > 1) {
      return backendClient
        .query({
          query: ADDRESS_VALIDATION,
          variables: { address: val },
        })
        .then(d => {
          setSelectData(d.data.addressValidation.data);
          return d.data.addressValidation.data.map((a: { address: string }) => a.address);
        });
    }
    return undefined;
  }, []);

  const searchSelectorHandler = useCallback(
    (idx: number, val: string) => {
      const data = selectData.find(a => a.address === val);
      const res = JSON.parse(JSON.stringify(rentals));
      res[idx].state = data.state;
      res[idx].city = data.city;
      res[idx].zipCode = data.zip;
      setRentals(res);
    },
    [rentals, selectData],
  );

  const [deleteRental] = useMutation(DELETE_RENTAL);
  const doDeleteRental = useCallback(
    (id: string) =>
      deleteRental({
        variables: { rentalUnitId: id },
      }),
    [deleteRental],
  );

  return {
    registrationId,
    rentals,
    setRentals,
    submitHandler,
    setCopyIdx,
    doCopy,
    fetchAddressValidation,
    searchSelectorHandler,
    error,
    doDeleteRental,
    copyIdx,
  };
}
