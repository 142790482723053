import React, { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import UserInfo, { newUserInfo } from '../../common_lib_front/types/userInfo';
import collectionTools from '../../common_lib_front/utilities/collectionTools';
import SharePassForm from './sharePassForm';
import style from './sharePassFormCollection.module.css';

interface Props {
  max?: number;
  min?: number;
  submitHandler: (data: Array<UserInfo>) => void;
}

export const SharePassFormCollection = (props: Props): ReactElement => {
  const [data, setData] = useState<Array<UserInfo>>([]);
  const { max, min, submitHandler } = props;

  const { add, update, remove } = collectionTools({
    newElement: newUserInfo,
    data,
    setData,
  });
  useEffect(() => {
    if (min !== undefined) {
      for (let i = data.length; i < min; i += 1) {
        add();
      }
    }
  }, [add, data.length, min]);

  return (
    <div className={style.container}>
      {data.map((elem: UserInfo, idx: number) => (
        <SharePassForm
          allowRemove={min !== undefined ? data.length > min : true}
          data={elem}
          idx={idx}
          key={idx}
          remove={() => remove(idx)}
          update={(obj: any) => update(idx, obj)}
        />
      ))}
      <div className={style.btn}>
        <GenericButton
          clickHandler={() => submitHandler(data)}
          color="yellow"
          size="large"
          title="share"
        />
      </div>
      {max !== undefined && data.length < max ? (
        <div className={style.addBtn}>
          <GenericButton
            // icon="add"
            icon={(className: string) => <AddImg className={`${className}`} />}
            title=""
            clickHandler={() => {
              if (max !== undefined && data.length < max) add();
            }}
          />
          <p className={`${style.textBelow} textColorSecondary `}>Add Recipient</p>
        </div>
      ) : null}
    </div>
  );
};

SharePassFormCollection.defaultProps = {
  max: undefined,
  min: 1,
};
