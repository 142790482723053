import { useContext, useEffect, useMemo, useState } from 'react';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import { contactType } from './contactType';

export type inputCfgType = {
  description?: string;
  largeInstruction?: string;
  formSelectLabel?: string;
  inputs: {
    [key in keyof contactType]?: {
      required?: boolean;
      label?: string;
      hidden?: boolean;
    };
  };
};

export class InputCfgSwitch {
  inputCfgs: Record<string, inputCfgType>;
  cfgLabels: string[];
  cfgIdx: string;
  listeners: Set<(obj: InputCfgSwitch) => void>;

  constructor(cfgs: Record<string, inputCfgType>) {
    this.inputCfgs = cfgs;
    this.cfgLabels = Object.keys(cfgs);
    this.cfgIdx = this.cfgLabels[0] || '';
    this.listeners = new Set();
  }

  private fireListeners() {
    this.listeners.forEach(callback => callback(this));
  }

  getCfg() {
    return this.inputCfgs[this.cfgIdx];
  }

  setCfgIdx(idx: string) {
    this.cfgIdx = idx;
    this.fireListeners();
  }

  subscribe(callback: (obj: InputCfgSwitch) => void): () => void {
    this.listeners.add(callback);
    return () => this.listeners.delete(callback);
  }
}

// TODO: move to database
export const communityConfig: Record<string, InputCfgSwitch> = {
  carillonbeach: new InputCfgSwitch({
    default: {
      largeInstruction:
        "To add a family member or friend, please enter the family member or friend's name, phone number and email.",
      inputs: {
        phoneNumber: { required: true },
        idNum: { hidden: true },
        idState: { hidden: true },
        idExp: { hidden: true },
        file: { hidden: false, label: 'Upload Family/Friend Picture' },
      },
    },
  }),
  pinnacleport: new InputCfgSwitch({
    default: {
      largeInstruction:
        "To add a family member or friend, please enter the family member or friend's name, phone number and email.",
      inputs: {
        phoneNumber: { required: true },
        idNum: { hidden: true },
        idState: { hidden: true },
        idExp: { hidden: true },
        file: { hidden: false, label: 'Upload Family/Friend Picture' },
      },
    },
  }),
  wildheron: new InputCfgSwitch({
    default: {
      largeInstruction:
        "To add a family member or friend, please enter the family member or friend's name and email.",
      inputs: {
        phoneNumber: { hidden: true },
        idNum: { hidden: true },
        idState: { hidden: true },
        idExp: { hidden: true },
        file: { hidden: true },
      },
    },
  }),
  default: new InputCfgSwitch({
    driversLicense: {
      description: "Upload/Scan Driver's License",
      largeInstruction:
        "To add a family member or friend, please enter the family member or friend's name, phone number and email.",
      formSelectLabel: 'A family member / friend Driver License or picture is required',
      inputs: {
        phoneNumber: { required: true },
        idNum: { label: "Driver's License Number", required: false },
        idState: { label: "Driver's License State", required: false },
        idExp: { label: "Driver's License Expiration", required: false },
        file: { label: "Scan/Upload Driver's License" },
      },
    },
    photo: {
      description: 'Upload Family/Friend Picture',
      largeInstruction:
        "To add a family member or friend, please enter the family member or friend's name, phone number and email.",
      formSelectLabel: 'A family member / friend Driver License or picture is required',
      inputs: {
        phoneNumber: { required: true },
        idNum: { label: "Driver's License Number", hidden: true },
        idState: { label: "Driver's License State", hidden: true },
        idExp: { label: "Driver's License Expiration", hidden: true },
        file: { label: 'Upload Family/Friend Picture' },
      },
    },
  }),
};

export function useInputConfig(): [inputCfgType | undefined, InputCfgSwitch | undefined] {
  const { communityId } = useContext(CommunityContext);

  const cfg = useMemo(() => {
    return communityConfig[communityId] || communityConfig['default'];
  }, [communityId]);

  const [inptCfg, setInptCfg] = useState<inputCfgType | undefined>(cfg?.getCfg());

  useEffect(() => {
    const unsubscribe = cfg.subscribe(obj => setInptCfg(obj.getCfg()));
    return () => unsubscribe();
  }, [cfg]);

  return [inptCfg, cfg];
}
