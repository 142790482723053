import Tippy from '@tippyjs/react';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../../assets/actionIcon.svg';
import style from './popUpCell.module.css';

interface PopupCellRendererProps {
  data: {
    familyMemberId: string;
    documentUrl: string;
  };
}

/* eslint-disable react/prop-types */
const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({ data }) => {
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const history = useHistory();
  console.log('GRID data: ', data, data.familyMemberId);
  const openLinkHandler = (option: string) => {
    hide();
    if (option === 'edit') {
      // window.location.href = `../edit-family-member/${data.familyMemberId}`;
      history.push(`../edit-family-member/${data.familyMemberId}`);
    }
    if (option === 'delete') {
      // window.location.href = `../delete-member/${data.familyMemberId}`;
      history.push(`../delete-member/${data.familyMemberId}`);
    }
  };

  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <div
        onClick={() => openLinkHandler('edit')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('edit');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Edit</span>
      </div>
      <div
        onClick={() => openLinkHandler('delete')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('delete');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Delete</span>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
