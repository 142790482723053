import { Alert, Snackbar } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import PassSummaryCollection from '../../../../common_lib_front/components/passSummary/passSummaryCollection';
// import { errorToReadable } from '../../../../common_lib_front/utilities/errorToReadable';
import PassFormCollection from '../../../../components/residentPassForm/passFormCollection';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';
import style from './registerVehicle.module.css';
import {
  submitPassAndVehicleForms,
  useDeletePassAndVehicle,
  useRegistrationPassesAndVehicles,
} from './useRegisterVehicle';

export function RegisterVehicle(): ReactElement {
  // const { redirect, doSubmit, alert, passes, setPasses, t, submitLoading } =
  //   useRegisterVehicle();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [alert, setAlert] = useState<string>('');
  const { t } = useTranslation();
  const history = useHistory();
  const { passes, setPasses } = useRegistrationPassesAndVehicles();
  console.log('passes: ', passes);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deletePassAndVehicle] = useDeletePassAndVehicle();

  return (
    <form
      className={style.container}
      onSubmit={e => {
        e.preventDefault();
        setSubmitLoading(true);
        submitPassAndVehicleForms(passes, registrationId)
          .then(res => {
            console.log('submit res: ', res);
            history.replace(
              `/resident/get-passes/resident-pass/${registrationId}/community-orientation`,
            );
          })
          .catch(err => {
            console.error(err);
            console.trace();
            // setAlert(errorToReadable(err));
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      }}
    >
      <Snackbar
        open={Boolean(alert)}
        onClose={() => setAlert('')}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert color="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          activeNum={1}
          title={`${t('Step 1')}: ${t('Pass Information')}`}
          subtitle={t(
            'Please enter all required information below to register. Failure to do so will result in fines and possible explusion from the community.',
          )}
        />
        {/* <GenericAlert color="red" title={alert} hidden={!alert} /> */}
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <PassFormCollection
              data={passes}
              setData={setPasses}
              showSaveBtn={false}
              showTitle
              removeHandler={({ variables: ids }) =>
                deletePassAndVehicle({
                  variables: {
                    passId: ids.passId,
                    vehicleId: ids.vehicleId,
                  },
                })
              }
            />
          </div>
          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn={false}
              showVehicleInfo={false}
              showTotal
              data={passes}
            />
            <small className={style.smallText}>
              Prices in US dollars(USD), Community access Passes are nonrefundable. Total
              calculated at checkout.
            </small>
          </div>
        </div>
        <div className={style.btn}>
          {submitLoading ? (
            <div
              style={{
                marginBottom: '50px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <LoadingDiamonds />
            </div>
          ) : (
            <GenericButton
              size="large"
              color={submitLoading ? 'grey' : 'blue'}
              title={t('Continue')}
              type="submit"
              disabled={submitLoading}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default RegisterVehicle;
