import { gql, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';
import { PassStatuses } from '../../../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../../../common_lib_front/utilities/BackendAPI';

const EDIT_VEHICLE = gql`
  mutation EditVehicle(
    $vehicleId: String!
    $passInfoId: String!
    $newVehicleInfo: ActuallyInputTypeVehicleInput!
  ) {
    editVehicle(
      vehicleId: $vehicleId
      newVehicleInfo: $newVehicleInfo
      passInfoId: $passInfoId
    ) {
      success
      error
    }
  }
`;

export const EDIT_PASS_STATUS = gql`
  mutation EditPassStatus($status: String!, $passId: String!) {
    editPassStatus(status: $status, passId: $passId) {
      success
      error
    }
  }
`;

type EDIT_VEHICLE_VARS = {
  vehicleId?: string;
  newVehicleInfo: {
    make?: string;
    type?: string;
    color?: string;
    licensePlate?: string;
    year?: number;
    isPassEditPage?: boolean;
    primaryDriverName?: string;
  };
  passInfoId: string;
};

type EDIT_PASS_STATUS_VARS = {
  status: PassStatuses;
  passId: string;
};

type EDIT_PASS_STATUS_RES = {
  editPassStatus: backendResponse<undefined>;
};

type EDIT_VEHICLE_RES = {
  editVehicle: backendResponse<undefined>;
};

type GET_PASS_RES = {
  getPass: backendResponse<undefined>;
};

type GET_PASS_INPUT = {
  passId: string;
};
type editPassData = {
  primaryDriverName?: string;
  plateNumber?: string;
  color?: string;
  type?: string;
  make?: string;
  year?: number;
  licensePlate?: string;
  vehicleId?: string;
  isPassEditPage?: boolean;
  passId?: string;
  startDate?: string;
  endDate?: string;
  firstName?: string;
  lastName?: string;
};

type usePassEdit = {
  submit: (
    data: editPassData,
  ) => Promise<EDIT_VEHICLE_RES | REGISTER_VEHICLE_RES | AMENITY_PASS__RES>;
  loading: boolean;
  error?: string;
};

function mutateWrapper(data: EDIT_VEHICLE_VARS): Promise<EDIT_VEHICLE_RES> {
  return backendClient
    .mutate<EDIT_VEHICLE_RES, EDIT_VEHICLE_VARS>({
      mutation: EDIT_VEHICLE,
      variables: data,
    })
    .then((res: any) => res.data);
}

export const REGISTER_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $passInfoId: String!
    $vehicleInfo: ActuallyInputTypeVehicleInput!
  ) {
    registerPassVehicle(
      passId: $passId
      passInfoId: $passInfoId
      vehicleInfo: $vehicleInfo
    ) {
      success
      error
    }
  }
`;

const GET_PASS = gql`
  query Query($passId: String!) {
    getPass(passId: $passId) {
      error
      success
      data {
        number
        startDate
        endDate
        status
      }
    }
  }
`;

export type REGISTER_VEHICLE_VARS = {
  passId: string;
  passInfoId: string;
  vehicleInfo: Partial<editPassData>;
};

export type REGISTER_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
};

function mutateWrapperForVehiclePass(
  data: REGISTER_VEHICLE_VARS,
): Promise<REGISTER_VEHICLE_RES> {
  return backendClient
    .mutate<REGISTER_VEHICLE_RES, REGISTER_VEHICLE_VARS>({
      mutation: REGISTER_VEHICLE,
      variables: data,
    })
    .then((res: any) => res.data);
}

function mutateWrapperEditPassStatus(
  data: EDIT_PASS_STATUS_VARS,
): Promise<EDIT_PASS_STATUS_RES> {
  return backendClient
    .mutate<EDIT_PASS_STATUS_RES, EDIT_PASS_STATUS_VARS>({
      mutation: EDIT_PASS_STATUS,
      variables: data,
    })
    .then((res: any) => res.data);
}

const EDIT_AMENITY_PASS = gql`
  mutation editAmanityPass($passId: String!, $newInfo: PassInput!) {
    editAmanityPass(passId: $passId, newInfo: $newInfo) {
      success

      error
    }
  }
`;

type EDIT_EMENITY_PASS_VAR = {
  newInfo: {
    startDate: string;
    endDate: string;
    passInfoId: string;
  };
  passId: any;
};

type EDIT_VEHICLE_PASS_VAR = {
  newInfo: {
    startDate: string;
    endDate: string;
    passInfoId: string;
  };
  passId: any;
};

type AMENITY_PASS__RES = {
  editPass: backendResponse<undefined>;
  editVehicle: backendResponse<undefined>;
  registerVehicle: backendResponse<undefined>;
};

function mutateWrapperForAmenityPass(
  data: EDIT_EMENITY_PASS_VAR,
): Promise<AMENITY_PASS__RES> {
  return new Promise(async resolve => {
    await backendClient
      .mutate<AMENITY_PASS__RES, EDIT_VEHICLE_PASS_VAR>({
        mutation: EDIT_AMENITY_PASS,
        variables: {
          passId: data.passId,
          newInfo: data.newInfo,
        },
      })
      .then((res: any) => resolve(res.data));
  });
}

function getPassData(data: GET_PASS_INPUT): Promise<GET_PASS_RES> {
  return new Promise(async resolve => {
    await backendClient
      .mutate<GET_PASS_RES, GET_PASS_INPUT>({
        mutation: GET_PASS,
        variables: {
          passId: data.passId,
        },
      })
      .then((res: any) => resolve(res.data));
  });
}

export default function usePassEdit(props: {
  passInfoId: string;
  passId: string;
  state: {
    data: {
      activeTab: string;
    };
  };
}): usePassEdit {
  const { passInfoId, passId, state } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { data: passDetails } = useQuery(GET_PASS, {
    variables: {
      passId: passId,
    },
  });
  const submit: usePassEdit['submit'] = useCallback(
    async (data: editPassData) => {
      setLoading(true);
      if (state.data.activeTab !== 'amenity') {
        if (data.vehicleId) {
          return mutateWrapper({
            vehicleId: data.vehicleId,
            newVehicleInfo: {
              make: data.make || '',
              type: data.type || '',
              color: data.color || '',
              licensePlate: data.licensePlate || '',
              year: data.year || 0,
              isPassEditPage: true,
            },
            passInfoId: passInfoId,
          })
            .then(res => {
              if (!res) {
                setError('Result does not exist. Somehting went wrong');
                // throw Error('Result does not exist. Somehting went wrong');
              }
              if (res.editVehicle.error) {
                setError(res.editVehicle.error);
                throw res.editVehicle.error;
              } else {
                setError(undefined);
              }
              return res;
            })
            .catch(res => {
              setError(
                typeof res === 'string'
                  ? res
                  : 'Something went wrong. Changes could not be saved.',
              );
              // return res;
              throw res;
            })
            .finally(() => setLoading(false));
        } else {
          return mutateWrapperForVehiclePass({
            vehicleInfo: {
              make: data.make || '',
              type: data.type || '',
              color: data.color || '',
              licensePlate: data.licensePlate || '',
              year: data.year || 0,
              isPassEditPage: true,
            },
            passInfoId: passInfoId,
            passId: passId,
          })
            .then(res => {
              if (!res) {
                console.log('inside error11');
                setError('Result does not exist. Somehting went wrong');
                // throw Error('Result does not exist. Somehting went wrong');
              }
              if (res?.registerVehicle?.error) {
                console.log('inside error0');

                setError(res?.registerVehicle?.error);
                throw res?.registerVehicle?.error;
              } else {
                setError(undefined);
              }
              mutateWrapperEditPassStatus({
                passId: passId,
                status: 'inactive',
              }).then(passRes => {
                if (passRes?.editPassStatus?.error) {
                  setError(passRes?.editPassStatus?.error);
                  throw passRes?.editPassStatus?.error;
                } else {
                  setError(undefined);
                }
              });
              return res;
            })
            .catch(res => {
              setError(
                typeof res === 'string'
                  ? res
                  : 'Something went wrong. Changes could not be saved.',
              );
              throw res;
            })
            .finally(() => setLoading(false));
        }
      } else {
        return mutateWrapperForAmenityPass({
          newInfo: {
            startDate: data.startDate || '',
            endDate: data.endDate || '',
            passInfoId: passInfoId,
          },
          passId: passId,
        })
          .then(res => {
            if (!res) {
              setError('Result does not exist. Somehting went wrong');
              // throw Error('Result does not exist. Somehting went wrong');
            }
            if (res?.editPass?.error) {
              setError(res.editPass.error);
              throw res.editPass.error;
            } else {
              setError(undefined);
            }
            getPassData({ passId }).then(pdata => {
              if (pdata.getPass.data) {
                //console.log('pdata.getPass.data[0]', pdata.getPass.data[0]);
                if (pdata.getPass.data[0]['status'] == 'incomplete') {
                  mutateWrapperEditPassStatus({
                    passId: passId,
                    status: 'inactive',
                  }).then(passRes => {
                    if (passRes?.editPassStatus?.error) {
                      setError(passRes?.editPassStatus?.error);
                      throw passRes?.editPassStatus?.error;
                    } else {
                      setError(undefined);
                    }
                  });
                }
                // console.log('pdata', pdata?.getPass?.data[0].status);
              }
            });
            if (data.vehicleId) {
              return mutateWrapper({
                vehicleId: data.vehicleId,
                newVehicleInfo: {
                  primaryDriverName: data.primaryDriverName || '',
                },
                passInfoId: passInfoId,
              })
                .then(res => {
                  if (!res) {
                    setError('Result does not exist. Somehting went wrong');
                    // throw Error('Result does not exist. Somehting went wrong');
                  }
                  if (res.editVehicle.error) {
                    setError(res.editVehicle.error);
                    throw res.editVehicle.error;
                  } else {
                    setError(undefined);
                  }
                  return res;
                })
                .catch(res => {
                  setError(
                    typeof res === 'string'
                      ? res
                      : 'Something went wrong. Changes could not be saved.',
                  );
                  // return res;
                  throw res;
                })
                .finally(() => setLoading(false));
            }
            if (!data.vehicleId) {
              mutateWrapperForVehiclePass({
                vehicleInfo: {
                  primaryDriverName: data.primaryDriverName,
                },
                passInfoId: passInfoId,
                passId: passId,
              })
                .then(res => {
                  if (!res) {
                    setError('Result does not exist. Somehting went wrong');
                    // throw Error('Result does not exist. Somehting went wrong');
                  }
                  // if (res.addComplete.error) {
                  //   setError(res.editVehicle.error);
                  //   throw res.editVehicle.error;
                  // } else {
                  //   setError(undefined);
                  // }
                  return res;
                })
                .catch(res => {
                  setError(
                    typeof res === 'string'
                      ? res
                      : 'Something went wrong. Changes could not be saved.',
                  );
                  // return res;
                  throw res;
                })
                .finally(() => setLoading(false));
            }
            return res;
          })
          .catch(res => {
            // console.log(res);
            setError(
              typeof res === 'string'
                ? res
                : 'Something went wrong. Changes could not be saved.',
            );
            throw res;
          })
          .finally(() => setLoading(false));
      }
    },
    [setLoading],
  );

  return {
    loading,
    submit,
    error,
  };
}
