export type contactType = {
  firstName?: string;
  lastName?: string;
  relationship?: string;
  email?: string;
  phoneNumber?: string;
  file?: string;
  fileName?: string;
  fileIsNew?: boolean;
  idNum?: string;
  idExp?: string;
  idState?: string;
  idType?: string;
};

export const relationships = [
  'Primary Owner',
  'Co-Owner',
  'Husband',
  'Wife',
  'Son',
  'Daughter',
  'Father',
  'Mother',
  'Son-in-Law',
  'Daughter-in-Law',
];