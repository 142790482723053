import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const GET_REGISTRATION = gql`
  query GetRegiatration {
    getRegistrationData(complete: false, type: "rental") {
      success
      error
      data {
        registrationId
        stepNumber
        complete
      }
    }
  }
`;

const CREATE_REGISTRATION = gql`
  mutation CreateRegistration {
    createRegistration(type: "rental") {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

type RegistrationInitialization = {
  registrationId?: string;
  loading: boolean;
  error?: string;
};

export default function useRentalRegistrationInitialization(): RegistrationInitialization {
  const [
    doCreateRegistration,
    { data: createdData, loading: createdLoading, error: createdError },
  ] = useMutation(CREATE_REGISTRATION);
  const [registrationId, setRegistrationId] = useState<string>('');

  const {
    data: getData,
    loading: getLoading,
    error: getError,
  } = useQuery(GET_REGISTRATION, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      console.log('reg', d?.getRegistrationData);
      if (d?.getRegistrationData?.success) {
        if (d?.getRegistrationData?.data.length > 0) {
          if (
            !d?.getRegistrationData?.data[d.getRegistrationData.data.length - 1].complete
          ) {
            console.log(
              d?.getRegistrationData?.data[d.getRegistrationData.data.length - 1],
              'old one',
            );
            setRegistrationId(
              d?.getRegistrationData?.data[d.getRegistrationData.data.length - 1]
                .registrationId,
            );
          } else {
            doCreateRegistration();
          }
        } else {
          doCreateRegistration();
        }
      } else {
        doCreateRegistration();
      }
    },
    onError: (error) => {
      // console.log('error', error);
    },
  });

  useEffect(() => {
    if (
      createdData &&
      createdData.createRegistration.data &&
      createdData.createRegistration.data.length > 0
    ) {
      console.log(
        'create new one',
        createdData.createRegistration.data[0].registrationId,
      );
      setRegistrationId(createdData.createRegistration.data[0].registrationId);
    }
  }, [createdData]);

  return {
    registrationId: (() => {
      return registrationId || undefined;
    })(),
    loading: getLoading || createdLoading,
    error:
      createdError?.message ||
      createdData?.createRegistration?.error ||
      getError?.message ||
      getData?.getRegistrations?.error ||
      undefined,
  };
}
