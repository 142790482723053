import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import ScormWrapper from '../../../../common_lib_front//components/scormWrapper/scormWrapper';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';

import style from './communityOrientation.module.css';
import useCommunityOrientation from './useCommunityOrientation';

export function CommunityOrientation(): ReactElement {
  const { doCompleteHandle, redirect } = useCommunityOrientation();
  const { featuresConfig } = useContext(CommunityContext);

  const { t } = useTranslation();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          activeNum={2}
          title={`${t('Step 2')}: ${t('Community Orientation')}`}
          subtitle={t('')}
        />
        <div className={style.flexBox}>
          <div className={style.LMSWindow}>
            <ScormWrapper
              portal="resident"
              continueHandler={() => {
                doCompleteHandle();
              }}
            />
          </div>
        </div>
        <div className={style.btn}>
          {featuresConfig?.resident?.residentPasses?.educationStepSkippable ?? true ? (
            <GenericButton
              size="large"
              color="blue"
              title="Continue"
              clickHandler={() => doCompleteHandle()}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CommunityOrientation;
