import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import HostRegistrationNavTile from '../hostRegistrationNavTile/hostRegistrationNavTile';
import style from './hostRegistrationNav.module.css';

const GET_REGISTRATION = gql`
  query GetRegistration($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        complete
        stepNumber
      }
    }
  }
`;
type GET_REGISTRATION_RES = {
  getRegistration: backendResponse<
    [
      {
        complete: boolean;
        stepNumber: number;
      },
    ]
  >;
};

interface HostRegistrationNavProps {
  stepNum: number;
  title: string;
  subtitle: string | ReactElement | null;
}

export default function HostRegistrationNav(
  props: HostRegistrationNavProps,
): ReactElement {
  const { stepNum, title, subtitle } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  const [completeNum, setCompleteNum] = useState<number>(0);
  const [completeAll, setCompleteAll] = useState<boolean>(false);
  const {
    name: communityName,
    communityId,
    featuresConfig,
  } = useContext(CommunityContext);

  useQuery<GET_REGISTRATION_RES>(GET_REGISTRATION, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onError: () => {
      //
    },
    onCompleted: d => {
      // console.log(d.getRegistration.data[0]);
      if (d.getRegistration.data && d.getRegistration.data.length > 0) {
        setCompleteNum(d.getRegistration.data[0].stepNumber);
        setCompleteAll(d.getRegistration.data[0].complete);
      }
    },
  });

  useEffect(() => {
    (window as any).registrationLockStepNum = completeNum;
    return () => {
      (window as any).registrationLockStepNum = undefined;
    };
  }, [completeNum]);
  let welcome;
  let InstructionText;
  if (stepNum === 5) {
    welcome = style.welcomeHidden;
    InstructionText = style.textHidden;
  } else {
    InstructionText = style.text;
  }
  // define the Steps Array and show hide the steps based on the configuration value
  const stepList: any = [
    {
      id: 1,
      href: `/resident/host-registration/${registrationId}/host-information`,
      text: 'Host Information',
      icon: 'HostRegistrationPic',
      hidden: false,
    },
    {
      id: 2,
      href: `/resident/host-registration/${registrationId}/rental-units`,
      text: 'Rental Registration',
      icon: 'RentalUnitPic',
      hidden: !featuresConfig?.host?.initialRegistration?.rentalRegistrationStepEnabled,
    },
    {
      id: 3,
      href: `/resident/host-registration/${registrationId}/education`,
      text: 'Community Orientation',
      icon: 'RulesPic',
      hidden: !featuresConfig?.host?.initialRegistration?.educationStepEnabled,
    },
    {
      id: 4,
      href: `/resident/host-registration/${registrationId}/documents`,
      text: 'Upload Documents',
      icon: 'DocumentPic',
      hidden: !featuresConfig?.host?.initialRegistration?.documentStepEnabled,
    },
    {
      id: 5,
      href: `/resident/host-registration/${registrationId}/secure-checkout`,
      text: 'Review Information',
      icon: 'CardPic',
      hidden: false,
    },
  ];
  let stepNumber = 1;
  return (
    <nav className={style.navHeaderBox}>
      {/* <span className={style.spacer} /> */}
      <div className={style.textBox}>
        <h2 className={`${style.titleGrey} textColorSuperDark `}>
          <span className={welcome}> Welcome to </span>{' '}
          <p className={`${style.titleBlue} textColorMain `}>{communityName}</p>
        </h2>
        <p className={`${InstructionText} textColorSuperDark `}>
          {communityId === 'sipoa'
            ? 'Please follow the instructions to register your rental property. You must register and review community rules before completing your registration. Registration takes approximately 5-10 minutes to complete.'
            : 'Please follow the instructions to register your short-term rental property. You must register and review community rules before completing your registration. Registration takes approximately 5-10 minutes to complete.'}
        </p>
      </div>
      <div className={style.iconBox}>
        {stepList.map((ele: any, index: number) => {
          if (!ele.hidden) {
            const isLastStep = stepList.slice(index + 1).every((e: any) => e.hidden);
            const stepComponent = (
              <React.Fragment key={index}>
                <HostRegistrationNavTile
                  href={ele.href}
                  title={'Step ' + stepNumber}
                  text={ele.text}
                  icon={ele.icon}
                  num={stepNumber}
                  stepNum={stepNum || 0}
                  completeNum={stepNum}
                  completeAll={completeAll}
                  currentStep={stepNum}
                />
                {!isLastStep && <span className={`${style.line} specialGrey `} />}
              </React.Fragment>
            );
            stepNumber++; // Increment stepNumber here
            return stepComponent;
          }
          return null;
        })}
      </div>
      <h2 className={`${style.title} textColorSuperDark `}>{title}</h2>
      <div className={`${style.subTitle} textColorSuperDark `}>{subtitle}</div>
    </nav>
  );
}
