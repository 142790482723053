import React, { ReactElement, useEffect } from 'react';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import collectionTools from '../../common_lib_front/utilities/collectionTools';
import style from './formCollection.module.css';

interface Props<T> {
  mapper: (
    removeHandler: (i: number) => void,
    updateHandler: (i: number, obj: any) => void,
  ) => (elem: T, idx: number) => ReactElement;
  newElement: (data?: any) => T;
  data: Array<T>;
  setData: (d: Array<T>) => void;
  min?: number;
  max?: number;
}

export default function FormCollection<T>(props: Props<T>): ReactElement {
  const { mapper, newElement, data, setData, min, max } = props;

  const { add, update, remove } = collectionTools<T>({
    newElement,
    data,
    setData,
  });

  useEffect(() => {
    if (min && data.length < min) {
      add();
    } else if (max && data.length > max) {
      remove(data.length - 1);
    }
  });

  return (
    <div className={style.infoBox}>
      <h3 className={`${style.title} textColorSuperDark `}>Rental Unit Information</h3>
      <p className={`${style.subTitle} textColorSuperDark `}>
        Enter the Information of the rental units that you own below.
      </p>
      {data.map(mapper(remove, update))}
      {max && data.length >= max ? null : (
        <button className={style.btn} type="button" onClick={() => add()}>
          <div className={style.btnIcon}>
            <AddImg />
          </div>
          <div className={`${style.btnText} textColorMain `}>Add Additional Rental</div>
        </button>
      )}
    </div>
  );
}
FormCollection.defaultProps = {
  min: 1,
  max: undefined,
};
