import { gql } from '@apollo/client';
import { backendResponse } from '../../common_lib_front/types/backendResponse';

export const ADDRESS_VALIDATION = gql`
  query Query($address: String!) {
    addressValidation(address: $address) {
      success
      error
      data {
        address
        city
        state
        zip
        postalAddress
      }
    }
  }
`;
export type ADDRESS_VALIDATION_VARS = {
  address: string;
};
export type ADDRESS_VALIDATION_RES = {
  addressValidation: backendResponse<
    Array<{
      address: string;
    }>
  >;
};
