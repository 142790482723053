import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

export const GET_HOST_RENTALS = gql`
  query GetRentalsByHost($hostInfoId: String, $approvedOnly: Boolean) {
    getRentalsByHost(hostInfoId: $hostInfoId, approvedOnly: $approvedOnly) {
      success
      error
      data {
        address
      }
    }
  }
`;
export type GET_HOST_RENTALS_VARS = {
  hostInfoId: string | null;
  approvedOnly: boolean | null;
};
export type GET_HOST_RENTALS_RES = {
  getRentalsByHost: backendResponse<Array<{ address: string }>>;
};

type optionsType = QueryHookOptions<GET_HOST_RENTALS_RES, GET_HOST_RENTALS_VARS>;
export const useGetHostRentals = (options: optionsType) =>
  useQuery<GET_HOST_RENTALS_RES, GET_HOST_RENTALS_VARS>(GET_HOST_RENTALS, options);
