import { ReactElement, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import qrCode from '../../../assets/qrCode.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';
import PassInfo from '../../../common_lib_front/types/passInfo';
import { passCredOrNumber } from '../../../common_lib_front/utilities/passCredOrNumber';
import style from './accessPassDisplay.module.css';

interface LocationProps {
  state: {
    data: PassInfo;
  };
}

export function PassDetails(): ReactElement {
  const history = useHistory();
  const { data } = (history.location as LocationProps).state;
  const { passLogo, name: communityName } = useContext(CommunityContext);
  // const disableBtns = ['expored', 'incomplete'].includes(state.data.status);
  // const shareLocation = {
  //   pathname: '/guest/share-pass',
  //   state,
  // };

  return (
    <PopUp
      title={passCredOrNumber(data)}
      isOpen
      close={() => {
        history.goBack();
      }}
    >
      <div className={style.container}>
        <div className={`${style.pass} white `}>
          <div className={style.logoBox}>
            <img className={style.logo} src={passLogo} alt="Community Access Pass" />
          </div>
          <h3 className={`${style.title} textColorSuperDark `}>
            Pass Type:Community Access Pass
          </h3>
          <p className={style.subtitle}>Authorized by {communityName}</p>
          <div className={style.boxTop}>
            <img className={style.qrCode} src={qrCode} alt="" />
          </div>
          <div className={style.timeBox}>
            <div className={style.validFromBox}>
              <h5 className={style.validFrom}>Valid From:</h5>
              <p className={`${style.validFromItems} textColorSuperDark `}>
                Friday , February 19 , 2020 at 8:00 AM
              </p>
            </div>
            <div className={style.validToBox}>
              <h5 className={style.validFrom}>Valid To:</h5>
              <p className={`${style.validFromItems} textColorSuperDark `}>
                Friday , February 19 , 2021 at 8:00 AM
              </p>
            </div>
          </div>
          <div className={style.infoBox}>
            <div className={style.Row}>
              <div className={style.info}>
                <h3 className={style.name}>Name:</h3>
                <p className={`${style.nameitem} textColorSuperDark `}>Neil Armstrong</p>
              </div>
              <div className={style.invisiableBox} />
              <div className={style.info}>
                <h3 className={style.contentTitle}>License Plate:</h3>
                <p className={`${style.content} textColorSuperDark `}>HG9 01 D</p>
              </div>
            </div>
            <div className={style.Row}>
              <div className={style.info}>
                <h3 className={style.contentTitle}>Model:</h3>
                <p className={`${style.content} textColorSuperDark `}>Toyota</p>
              </div>
              <div className={style.invisiableBox} />
              <div className={style.info}>
                <h3 className={style.contentTitle}>Type:</h3>
                <p className={`${style.content} textColorSuperDark `}>Sedan</p>
              </div>
            </div>
            <div className={style.Row}>
              <div className={style.info}>
                <h3 className={style.contentTitle}>Color:</h3>
                <p className={`${style.content} textColorSuperDark `}>Grey</p>
              </div>
              <div className={style.invisiableBox} />
              <div className={style.info}>
                <h3 className={style.contentTitle}>Pass Number:</h3>
                <p className={`${style.content} textColorSuperDark `}>VCB9018273456</p>
              </div>
            </div>
          </div>
          <p className={`${style.footerTitle} textColorMedium `}>
            {' '}
            {communityName} reserves the right to exclude any person or vehicle from
            property at any time
          </p>
        </div>
        <div className={style.btn}>
          <GenericButton color="blue" size="large" title="Download Pass" />
        </div>
      </div>
    </PopUp>
  );
}
export default withRouter(PassDetails);
