import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import style from './registrationInitialization.module.css';
import useRegistrationInitialization from './useRegistrationInitialization';

export default function RegistrationInitialization(): ReactElement {
  const { registrationId, loading, error } = useRegistrationInitialization();

  if (registrationId) {
    return (
      <Redirect to={`/resident/host-registration/${registrationId}/host-information`} />
      // <div className={style.wrapper}>
      //   success
      // </div>
    );
  }
  if (loading) {
    return <div className={style.wrapper}>Loading ...</div>;
  }
  if (error) {
    return <div className={style.wrapper}>{error}</div>;
  }
  return (
    <div className={style.wrapper}>
      We encountered an error. Please try again later and report this error if it
      persists.
    </div>
  );
}
