import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import RentalRegistrationNavTile from '../rentalRegistrationNavTile/rentalRegistrationNavTile';
import style from './rentalRegistrationNav.module.css';

const GET_REGISTRATION = gql`
  query GetRegistration($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        complete
        stepNumber
      }
    }
  }
`;
type GET_REGISTRATION_RES = {
  getRegistration: backendResponse<
    [
      {
        complete: boolean;
        stepNumber: number;
      },
    ]
  >;
};

interface RentalRegistrationNavProps {
  stepNum: number;
  title: string;
  subtitle: string | ReactElement;
}

export default function RentalRegistrationNav(
  props: RentalRegistrationNavProps,
): ReactElement {
  const { stepNum, title, subtitle } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  const [completeNum, setCompleteNum] = useState<number>(0);
  const [completeAll, setCompleteAll] = useState<boolean>(false);
  const { name: communityName, communityId } = useContext(CommunityContext);

  useQuery<GET_REGISTRATION_RES>(GET_REGISTRATION, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onError: () => {
      //
    },
    onCompleted: d => {
      // console.log(d.getRegistration.data[0]);
      if (d.getRegistration.data && d.getRegistration.data.length > 0) {
        setCompleteNum(d.getRegistration.data[0].stepNumber);
        setCompleteAll(d.getRegistration.data[0].complete);
      }
    },
  });

  useEffect(() => {
    (window as any).registrationLockStepNum = completeNum;
    return () => {
      (window as any).registrationLockStepNum = undefined;
    };
  }, [completeNum]);
  let welcome;
  let InstructionText;
  if (stepNum === 3) {
    welcome = style.welcomeHidden;
    InstructionText = style.textHidden;
  } else {
    InstructionText = style.text;
  }
  return (
    <nav className={style.navHeaderBox}>
      {/* <span className={style.spacer} /> */}
      <div className={style.textBox}>
        <h2 className={`${style.titleGrey} textColorSuperDark `}>
          <span className={welcome}> Welcome to </span>{' '}
          <p className={`${style.titleBlue} textColorMain `}>{communityName}</p>
        </h2>
        <p className={`${InstructionText} textColorSuperDark `}>
          {communityId === 'sipoa'
            ? 'Please follow the instructions to register your rental property. You must register and review community rules before completing your registration. Registration takes approximately 5-10 minutes to complete.'
            : 'Please follow the instructions to register your short-term rental property. You must register and review community rules before completing your registration. Registration takes approximately 5-10 minutes to complete.'}
        </p>
      </div>
      <div className={style.iconBox}>
        <RentalRegistrationNavTile
          href={`/resident/rental-registration/${registrationId}/rental-units`}
          title="Step 1"
          text="Register Your Rental Property"
          icon="RentalUnitPic "
          num={1}
          stepNum={stepNum || 0}
          completeNum={stepNum}
          completeAll={completeAll}
          currentStep={stepNum}
        />
        <span className={`${style.line} specialGrey `} />
        <RentalRegistrationNavTile
          href={`/resident/rental-registration/${registrationId}/secure-checkout`}
          title="Step 2"
          text="Review Information"
          icon="CardPic "
          num={2}
          stepNum={stepNum || 0}
          completeNum={stepNum}
          completeAll={completeAll}
          currentStep={stepNum}
        />
      </div>
      <h2 className={`${style.title} textColorSuperDark `}>{title}</h2>
      <p className={`${style.subTitle} textColorSuperDark `}>{subtitle}</p>
    </nav>
  );
}
