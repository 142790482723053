import { gql, useQuery } from '@apollo/client';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { ReactElement, useCallback, useRef, useState } from 'react';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { passStatusMapTitles, paymentStatusMapTitles } from '../../../common_lib_front/types/passInfo';
import {
  formatDate,
  formatDateWithMonthName,
} from '../../../common_lib_front/utilities/formatDate';
import { passCredOrNumber } from '../../../common_lib_front/utilities/passCredOrNumber';
import style from './myPasses.module.css';
import PopupCellRenderer from './popUpCellRender';

const GET_ALL_MEMBERS = gql`
  query getPassesByUserWithVehicle {
    getPassesRelatedToUser {
      error
      success
      data {
        passType
        number
        externalCredentialNumber
        shared
        status
        startDate
        endDate
        paid
        passId
        name
        url
        vehicle {
          licensePlate
          primaryDriverName
        }
      }
    }
  }
`;

export default function MyPasses(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const gridRef = useRef<AgGridReact>(null);


  const passTypes = {
    resident: 'Resident',
    vendor: 'Vendor',
    single: 'One-Time Use',
    visitor: 'Visitor',
  };


  const allNodes: any[] = [];
  gridRef.current?.api?.forEachNode((row: any) => {
    if (row.displayed) allNodes.push(row);
  });

  const { loading, data } = useQuery(GET_ALL_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (d.getPassesRelatedToUser.success) {
      }
    },
  });

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
    floatingFilter: true,
  };

  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      maxWidth: 100,
    },
    {
      headerName: 'Type',
      field: 'passType',
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: 'Number',
      field: 'number',
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: 'License Plate',
      field: 'licensePlate',
      sortable: true,
      minWidth: 150,
    },
    {
      headerName: 'Start',
      field: 'startDate',
      sortable: true,
      minWidth: 180,
    },
    {
      headerName: 'Expiration',
      field: 'endDate',
      sortable: true,
      minWidth: 180,
    },
    {
      headerName: 'Payment Status',
      field: 'paid',
      sortable: true,
      minWidth: 160,
    },
    {
      headerName: 'Pass Status',
      field: 'status',
      sortable: true,
      minWidth: 130,
    },
  ]);

  const rowData = data?.getPassesRelatedToUser?.data.flatMap((item: any) => ({
    number: passCredOrNumber(item),
    name: item?.vehicle?.primaryDriverName || '',
    passType: passTypes[item.passType as keyof typeof passTypes],
    shared: item.shared ? 'Yes' : 'No',
    status: passStatusMapTitles[item.status] || '',
    startDate: item.passType === 'single' ? '--' : formatDate(new Date(item.startDate)),
    endDate: item.passType === 'single' ? '--' : formatDate(new Date(item.endDate)),
    paid: paymentStatusMapTitles[item.paid] || '',
    licensePlate: item?.vehicle?.licensePlate || '',
    passId: item?.passId,
    url: item?.url,
  }));

  const onBtnExport = useCallback(() => {
    const columnKeys = [
      'passType',
      'name',
      'number',
      'licensePlate',
      'startDate',
      'endDate',
      'paid',
      'status',
    ];
    const headers: string[] = [];
    columnDefs.slice(1).forEach(col => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    gridRef.current?.api.forEachNode(row => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach(row => {
        const { data: item } = row;
        const itemData: any[] = [];
        columnKeys.forEach((key: string) => {
          if (key === 'startDate' || key === 'endDate') {
            itemData.push(formatDateWithMonthName(new Date(item[key])));
          } else {
            itemData.push(`"${item[key]}"`);
          }
        });
        dataToExport.push(itemData.join(','));
      });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
    allNodes.length = 0;
  }, []);

  // Quick filter event handler
  // const onFilterTextChange = (e: { target: { value: string } }) => {
  //   gridRef.current?.api.setQuickFilter(e.target.value);
  // };

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={style.alert}>
          <div className={style.alertBox}>
            <GenericAlert color={alertColor} title={alert} hidden={!alert} />
          </div>
        </div>
        <div className={`${style.subHeader} mainColor`}>
          <div className={style.subHeaderLeft}>
            <div className={`${style.circle} white`} />
            <h1 className={`${style.title} textColorWhite`}>My Passes</h1>
          </div>
        </div>
        <div className={style.topLine}>
          {/* <input
            type="search"
            className={style.gridSearch}
            onChange={onFilterTextChange}
            placeholder="Search anything here .."
          /> */}
        </div>
        <div className={style.paginationBox}>
          {' '}
          <label htmlFor="page-num-inpt">
            Show
            <select
              className={style.selectBox}
              value={itemsPerPage}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setItemsPerPage(Number.parseInt(e.target.value, 10));
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            Per Page
          </label>
          <button className={style.btnExport} onClick={onBtnExport}>
            Export CSV
          </button>
        </div>
        <div className={style.tableBox}>
          <div
            className="ag-theme-alpine"
            style={{
              height: '60vh',
              // width: '600px',
            }}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              ref={gridRef}
              paginationPageSize={itemsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
