import { MutationOptions, gql } from '@apollo/client';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';

export const EDIT_HOST_INFO = gql`
  mutation EditHostInfo(
    $ownerPin: String!
    $zipCode: String!
    $state: String!
    $city: String!
    $address: String!
    $phoneNumber: String!
    $lastName: String!
    $firstName: String!
    $companyName: String!
    $registrationId: String!
  ) {
    editOrInsertHostInfo(
      ownerPin: $ownerPin
      zipCode: $zipCode
      state: $state
      city: $city
      address: $address
      phoneNumber: $phoneNumber
      lastName: $lastName
      firstName: $firstName
      companyName: $companyName
      registrationId: $registrationId
    ) {
      success
      error
    }
  }
`;
export type EDIT_HOST_INFO_VARS = {
  ownerPin: string;
  zipCode: string;
  state: string;
  city: string;
  address: string;
  phoneNumber: string;
  lastName: string;
  firstName: string;
  companyName: string;
  registrationId: string;
};

export type EDIT_HOST_INFO_RES = {
  editOrInsertHostInfo: backendResponse<undefined>;
};

export type editHostInfoOptions = Omit<
  MutationOptions<EDIT_HOST_INFO_RES, EDIT_HOST_INFO_VARS>,
  'mutation'
>;
export const editHostInfo = async (options: editHostInfoOptions) =>
  backendClient.mutate<EDIT_HOST_INFO_RES, EDIT_HOST_INFO_VARS>({
    mutation: EDIT_HOST_INFO,
    ...options,
  });
