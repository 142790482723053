import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';

import style from './addDocument.module.css';

const ADD_USER_DOCUMENT_MUTATION = gql`
  mutation AddUserDocument(
    $file: String!
    $fileName: String!
    $documentType: String!
    $userDocumentName: String!
  ) {
    addUserDocument(
      file: $file
      fileName: $fileName
      documentType: $documentType
      userDocumentName: $userDocumentName
    ) {
      success
      error
      data {
        userId
        documentId
        documentUrl
        documentType
        userDocumentName
        uploadDate
      }
    }
  }
`;
type ADD_USER_DOCUMENT_VARS = {
  file: string;
  fileName: string;
  documentType: string;
  userDocumentName: string;
};

export default function AddDoc(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const history = useHistory();
  const [doc, setDoc] = useState<string>('');
  const [docType, setDocType] = useState<string>('');
  const [userDocumentName, setUserDocumentName] = useState<string>('');
  const [selectedDocName, setSelectedDocName] = useState<string>('');

  const [doAddDocument] = useMutation<any, ADD_USER_DOCUMENT_VARS>(
    ADD_USER_DOCUMENT_MUTATION,
  );
  const myInputRef = useRef<HTMLInputElement[]>([]);
  const submitHandler = async () => {
    try {
      await doAddDocument({
        variables: {
          file: doc,
          fileName: selectedDocName,
          userDocumentName,
          documentType: docType,
        },
      });
      history.goBack();
    } catch (error: any) {
      setAlert(error?.message);
      setAlertColor('red');
    }
  };

  myInputRef.current = [];

  async function selectForUpload(event: any) {
    const file = event[0];
    if (!file) return;
    const imgDataURL: string = await new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
    setDoc(imgDataURL);
    setSelectedDocName(file.name);
  }
  return (
    <PopUp title="Add Document" close={() => history.goBack()}>
      <div className={style.mainDiv}>
        <GenericAlert color={alertColor} title={alert} hidden={!alert} />
        <div className={style.uploadBox}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>
              <InputField
                highlightOnFocus
                largeSize
                htmlFor="doc-name-inpt"
                labelTitle="Document Name"
                inputType="text"
                inputValue={userDocumentName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUserDocumentName(e.target.value);
                }}
              />
            </div>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="doc-type-inpt"
              labelTitle="Document Type"
              inputType="text"
              inputValue={docType}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDocType(e.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropzone onDrop={acceptedFiles => selectForUpload(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section style={{ width: '100%' }} className="wfp--dropzone">
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <div
                    style={{ width: '100%' }}
                    {...getRootProps({ className: 'wfp--dropzone__input' })}
                  >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <input {...getInputProps()} />
                    <div className={style.typeItem}>
                      <div className={style.lowerPart}>
                        <p className={style.dragTitle}>
                          <div>{selectedDocName || 'Drag File to Upload'}</div>
                        </p>
                        <p className={style.dragSubTitle}>or</p>
                        <button className={style.browseBtn}>Browse Files</button>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <button
            onClick={() => {
              submitHandler();
            }}
            disabled={!doc || !docType || !userDocumentName}
            className={style.addBtn}
          >
            Upload
          </button>
        </div>
      </div>
    </PopUp>
  );
}
