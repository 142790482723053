import JsPDF from 'jspdf';

export const exportPdf = async function (imageUrls: string[]) {
  let pdf: any;
  let page: any;

  for (let i = 0; i < imageUrls.length; i++) {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageUrls[i];

    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');

    if (!pdf) {
      pdf = new JsPDF('p', 'px', [image.width, image.height]);
      page = pdf;
    }

    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    ctx!.drawImage(image, 0, 0);

    const imgData = ctx!.getImageData(0, 0, image.width, image.height);

    page.addImage(imgData, 'PNG', 0, 0, image.width, image.height);

    if (i < imageUrls.length - 1) {
      page = pdf.addPage();
    }
  }

  return pdf;
};
