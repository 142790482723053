import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import RegistrationProcessNav from '../../../components/registrationProcessNav/registrationProcessNav';
import style from './registrationUploadDocs.module.css';

interface IFileList {
  userDocumentName: string;
  file: string;
  fileName: string;
  documentType: string;
  // size: number;
}

export default function RegistrationProcessDocuments(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [files, setFiles] = useState<IFileList[]>([]);
  const { registrationId } = useParams<{ registrationId: string }>();
  const history = useHistory();
  const [doc, setDoc] = useState<string>('');
  const [docType, setDocType] = useState<string>('');
  const [userDocumentName, setUserDocumentName] = useState<string>('');
  const [selectedDocName, setSelectedDocName] = useState<string>('');

  const EDIT_STEP_NUMBER = gql`
    mutation EditRegistrationStepNumber($registrationId: String!) {
      editRegistrationStepNumber(
        stepNumber: 4
        registrationId: $registrationId
        registrationType: "resident"
      ) {
        success
        error
      }
    }
  `;

  const GET_USER_DOCUMENTS_QUERY = gql`
    query GetUserDocuments {
      getUserDocuments {
        success
        error
        data {
          userId
          documentId
          documentType
          userDocumentName
          uploadDate
          documentExpiration
        }
      }
    }
  `;

  const DELETE_REGISTRATION_DOCUMENT_QUERY = gql`
    mutation deleteUserDocumentById($documentId: String!) {
      deleteUserDocumentById(documentId: $documentId) {
        success
        error
      }
    }
  `;

  const ADD_USER_DOCUMENT_MUTATION = gql`
    mutation AddUserDocument(
      $file: String!
      $fileName: String!
      $documentType: String!
      $userDocumentName: String!
    ) {
      addUserDocument(
        file: $file
        fileName: $fileName
        documentType: $documentType
        userDocumentName: $userDocumentName
      ) {
        success
        error
        data {
          userId
          documentId
          documentUrl
          documentType
          userDocumentName
          uploadDate
        }
      }
    }
  `;

  const [doNextStep] = useMutation(EDIT_STEP_NUMBER);

  const { data, loading, error, refetch } = useQuery(GET_USER_DOCUMENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: d => {
      if (d.getUserDocuments.success) {
        setFiles(d.getUserDocuments.data);
      }
      if (!d.getUserDocuments.success) {
        setAlert(d.getUserDocuments.error);
        setAlertColor('red');
      }
    },
  });

  const handleChange = () => {
    // DOCUMENT UPLOAD NOT REQUIRED
    // if (files && files.length > 0) {
    doNextStep({ variables: { registrationId } }).then(() => {
      history.replace(`/resident/registration/${registrationId}/secure-checkout`);
    });
    // } else {
    //   setAlert('Please upload at least one document');
    //   setAlertColor('red');
    // }
  };

  const [deleteDocuments] = useMutation(DELETE_REGISTRATION_DOCUMENT_QUERY, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (!d.deleteUserDocumentById.success) {
        setAlert(d.deleteUserDocumentById.error);
        setAlertColor('red');
      } else {
        refetch();
      }
    },
  });

  const handleDelete = async (documentId: string) => {
    deleteDocuments({
      variables: {
        documentId,
      },
    });
  };

  type ADD_USER_DOCUMENT_VARS = {
    file: string;
    fileName: string;
    documentType: string;
    userDocumentName: string;
  };

  const [doAddDocument] = useMutation<any, ADD_USER_DOCUMENT_VARS>(
    ADD_USER_DOCUMENT_MUTATION,
    {
      onCompleted: d => {
        if (d.addUserDocument.success) {
          refetch();
          setDocType('');
          setDoc('');
          setUserDocumentName('');
          setSelectedDocName('');
        }
      },
      onError: e => {
        setAlert(e?.message);
        setAlertColor('red');
      },
    },
  );

  const submitHandler = async () => {
    try {
      await doAddDocument({
        variables: {
          file: doc,
          fileName: selectedDocName,
          userDocumentName,
          documentType: docType,
        },
      });
    } catch (error: any) {
      setAlert(error?.message);
      setAlertColor('red');
    }
  };

  async function selectForUpload(event: any) {
    const file = event[0];
    if (!file) return;
    const imgDataURL: string = await new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
    setDoc(imgDataURL);
    setSelectedDocName(file.name);
  }
  let documentStepNumber = 3;
  const currentCommunityData = useContext(CommunityContext);
  if (
    !currentCommunityData?.featuresConfig?.resident?.registration?.educationStepEnabled
  ) {
    documentStepNumber = 2;
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationProcessNav
          stepNum={documentStepNumber}
          title={'Step ' + documentStepNumber + ': Upload Documents'}
          subtitle="Please upload the required documents below"
        />
        {alert ? <GenericAlert title={alert} color={alertColor} /> : <></>}
        <div className={style.documentArea}>
          <div className={style.uploadBox}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '10px' }}>
                <InputField
                  highlightOnFocus
                  largeSize
                  htmlFor="doc-name-inpt"
                  labelTitle="Document Name"
                  inputType="text"
                  inputValue={userDocumentName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserDocumentName(e.target.value);
                  }}
                />
              </div>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="doc-type-inpt"
                labelTitle="Document Type"
                inputType="text"
                inputValue={docType}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDocType(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Dropzone onDrop={acceptedFiles => selectForUpload(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section style={{ width: '100%' }} className="wfp--dropzone">
                    <div
                      style={{ width: '100%' }}
                      {...getRootProps({ className: 'wfp--dropzone__input' })}
                    >
                      <input {...getInputProps()} />
                      <div className={style.typeItem}>
                        <div className={style.lowerPart}>
                          <p className={style.dragTitle}>
                            <div>{selectedDocName || 'Drag File to Upload'}</div>
                          </p>
                          <p className={style.dragSubTitle}>or</p>
                          <button className={style.browseBtn}>Browse Files</button>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              submitHandler();
            }}
            disabled={!doc || !docType || !userDocumentName}
            className={style.addBtn}
          >
            Upload
          </button>
        </div>

        {loading ? (
          <div className={style.loader}>
            {' '}
            <LoadingDiamonds />{' '}
          </div>
        ) : (
          <div>
            {files.map((ele: any, index: number) => (
              <div className={style.documentInput} key={index}>
                <a href={ele?.documentUrl} target="_blank" rel="noreferrer" title="view">
                  {ele?.userDocumentName}
                </a>
                <span
                  className={style.cross_sign}
                  title="remove"
                  onClick={() => handleDelete(ele.documentId)}
                ></span>
              </div>
            ))}
          </div>
        )}

        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              size="large"
              title="Continue"
              type="button"
              color="blue"
              clickHandler={() => handleChange()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
