import { useContext, useEffect } from 'react';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';

export default function useChatbot(portalName: string | null) {
  const { communityId, zendeskWidgetSrc } = useContext(CommunityContext);
  const scriptId = 'ze-snippet';

  useEffect(() => {
    if (portalName && zendeskWidgetSrc) {
      const scriptSrc = zendeskWidgetSrc[portalName as keyof typeof zendeskWidgetSrc];
      if (scriptSrc) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = scriptSrc;
        document.body.appendChild(script);
      }
    }

    return () => {
      const elem = document.getElementById(scriptId);
      if (elem) {
        document.body.removeChild(elem);
      }
    };
  }, [communityId, zendeskWidgetSrc, portalName]);
}
