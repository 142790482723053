import React, { ReactElement, useContext, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import SearchSelector from '../../common_lib_front/components/searchSelector/searchSelector';
import { rentalUnitInfo } from '../../common_lib_front/types/rentalUnitInfo';
import style from './rentalUnitForm.module.css';

interface Props {
  data: rentalUnitInfo;
  idx: number;
  isCopied?: boolean;
  remove: () => void;
  update: (obj: any) => void;
  immutableDefault?: boolean;
  showEditButton?: boolean;
  showCopyButton?: boolean;
  copyHandler?: (idx: number) => void;
  pasteHandler?: (idx: number) => void;
  fetchAddressValidation?: (val: string) => Promise<Array<string>> | undefined;
  searchSelectorHandler?: (val: string) => void;
}

export default function RentalUnitForm(props: Props): ReactElement {
  const {
    data,
    idx,
    remove,
    update,
    immutableDefault,
    showCopyButton,
    copyHandler,
    pasteHandler,
    fetchAddressValidation,
    searchSelectorHandler,
    isCopied,
  } = props;
  const { communityId } = useContext(CommunityContext);

  const [open, setOpen] = useState<boolean>(true);
  const [editable, setEditable] = useState<boolean>(!(immutableDefault as boolean));
  // console.log('editable dataaa',props.showEditButton);
  const phoneNumberPrompt = () => {
    if (communityId === 'palmettodunes') {
      return (
        <div className={style.textAlert}>
          All Rental Managers are required to return telephone calls from PDPOA within one
          hour.
        </div>
      );
    }
    if (communityId === 'sipoa') {
      return (
        <div className={style.textAlert}>
          *Rental Managers are expected to return all emergency calls within 2 hours.
        </div>
      );
    }
    return (
      <div className={style.textAlert}>
        *Rental Managers are expected to return all emergency calls in a timely manner.
      </div>
    );
  };

  return (
    <div className={style.infoFormBox}>
      <div className={`${style.header} superDarkGrey `}>
        <div className={style.btnDown}>
          <button className={style.dropDown} type="button" onClick={() => setOpen(!open)}>
            {open ? '<' : '>'}
          </button>
        </div>
        <div className={style.title}>{`${idx + 1}. Rental Unit`}</div>
        <GenericButton
          outline="small"
          title="Edit"
          type="button"
          // hidden={!showEditButton}
          hidden
          clickHandler={() => setEditable(!editable)}
        />
        {idx === 0 && copyHandler ? null : (
          <div className={style.btnRemove}>
            <button className={style.btnRemoveText} onClick={remove} type="button">
              Remove
            </button>
          </div>
        )}
      </div>
      {open ? (
        <div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              {communityId === 'palmettodunes' && (
                <p className={style.subtitle}>
                  If your property has a unit number, register with the unit number, not
                  the street address. Example: use 123 Ocean Cove, not 25 Ocean Lane #123.
                </p>
              )}
              {fetchAddressValidation ? (
                <div className={style.searchSelector}>
                  <SearchSelector
                    disabled={props.showEditButton}
                    htmlFor={`${idx}-st-addr-inpt`}
                    title={`Property Address
                    ${communityId === 'grandcomplex' ? ' and Unit Number' : ''}`}
                    /* subtitle={(() => {
                              if (communityId === 'palmettodunes') {
                                return 'If your property has a unit number,
                                register with the unit number, not the street address.
                                 Example: use 123 Ocean Cove, not 25 Ocean Lane #123.';
                              }
                              return '';
                            })()} */
                    value={data.address}
                    changeHandler={(val: string) => {
                      update({ address: val });
                    }}
                    fetch={fetchAddressValidation}
                    selectHandler={searchSelectorHandler}
                    noMatchAlert="Please select an address from your community"
                  />
                </div>
              ) : (
                <InputField
                  closedInput
                  htmlFor={`${idx}-st-addr-inpt`}
                  labelTitle={`Property Address
                  ${communityId === 'grandcomplex' ? ' and Unit Number' : ''}`}
                  inputValue={data.address}
                  disabled={props.showEditButton}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    update({ address: e.target.value });
                  }}
                />
              )}
            </div>
          </div>
          <div className={style.inputBox}>
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`${idx}-city-inpt`}
                labelTitle="City"
                required
                disabled={props.showEditButton}
                inputValue={data.city}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ city: e.target.value });
                }}
              />
            </div>
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`${idx}-state-inpt`}
                labelTitle="State"
                inputValue={data.state}
                required
                disabled={props.showEditButton}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ state: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              <InputField
                closedInput
                htmlFor={`${idx}-zip-inpt`}
                labelTitle="Zip Code"
                inputValue={data.zipCode}
                required
                disabled={props.showEditButton}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ zipCode: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.copyTool}>
            <p className={style.copyToolText}>Rental Management Information</p>
            {showCopyButton ? (
              <div className={style.copyToolBtnBox}>
                <button
                  type="button"
                  className={style.copyBtn}
                  onClick={() => (copyHandler ? copyHandler(idx) : null)}
                >
                  Copy
                </button>
                <button
                  type="button"
                  className={style.pasteBtn}
                  onClick={() => (pasteHandler ? pasteHandler(idx) : null)}
                >
                  Paste
                </button>
              </div>
            ) : null}
          </div>
          <div className={style.alertBox}>
            <GenericAlert
              title={`#
              ${idx + 1} Rental Unit's Management Company Information has been copied`}
              hidden={!isCopied}
              color="green"
            />
          </div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              <InputField
                closedInput
                htmlFor={`${idx}-mgmt-cpny-inpt`}
                labelTitle={
                  communityId === 'sipoa'
                    ? 'Management Company Name'
                    : 'Company Name or Your Name if Self-Managed'
                }
                inputValue={data.companyName}
                required
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ companyName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              <InputField
                closedInput
                htmlFor={`${idx}-pro-man-name-inpt`}
                labelTitle="Property Manager Name"
                inputValue={data?.propertyManagerName || ''}
                required
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ propertyManagerName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              <PhoneInputWrapper
                closedInput
                inputTitle="Property Manager Phone Number"
                id={`${idx}-pro-man-phn-inpt`}
                value={data.propertyManagerPhone || ''}
                changeHandler={(e: string) => {
                  update({ propertyManagerPhone: e });
                }}
              />
            </div>
          </div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              <InputField
                closedInput
                htmlFor={`${idx}-pecn-cpny-inpt`}
                labelTitle="Primary Emergency Contact Name"
                inputValue={data.primaryEmergencyContactName}
                required
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ primaryEmergencyContactName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputBox}>
            <div className={style.inputLong}>
              <PhoneInputWrapper
                closedInput
                inputTitle="Primary Emergency Phone Number"
                id={`${idx}-primary-emergency-phone`}
                value={data.primaryEmergencyPhone}
                changeHandler={(e: string) => {
                  update({ primaryEmergencyPhone: e });
                }}
              />
            </div>
          </div>
          {phoneNumberPrompt()}
          {/* <div className={style.inputBox}>
            <div className={style.inputLong}>
              <PhoneInputWrapper
                closedInput
                inputTitle="Secondary Emergency Phone Number (Optional)"
                id={`${idx}-secondary-emergency-phone`}
                value={data.secondaryEmergencyPhone}
                changeHandler={(e: string) => {
                  update({ secondaryEmergencyPhone: e });
                }}
              />
            </div>
          </div> */}
          {/* <div className={style.inputBox}>
            <div className={style.inputLong}>
              <InputField
                closedInput
                htmlFor={`${idx}-email-inpt`}
                labelTitle="Primary Emergency Contact Email Address"
                inputValue={data.email}
                required
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ email: e.target.value });
                }}
              />
            </div>
          </div> */}
        </div>
      ) : null}
    </div>
  );
}
RentalUnitForm.defaultProps = {
  immutableDefault: false,
  showEditButton: false,
  showCopyButton: false,
  copyHandler: undefined,
  pasteHandler: undefined,
  fetchAddressValidation: undefined,
  searchSelectorHandler: undefined,
  isCopied: false,
};
