export type FamilyOrFriendPassInfo = {
  employeeId?: string;
  firstName?: string;
  address?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  isNew?: boolean;
  [key: string]: any;
};

export function newFamilyOrFriendPass(data: any = {}): FamilyOrFriendPassInfo {
  const res = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    email: '',
    isNew: false,
  };
  Object.assign(res, data);
  return res;
}

export default FamilyOrFriendPassInfo;
