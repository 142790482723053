import React, { ReactElement, useContext, useState } from 'react';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import userSharePass from '../../assets/userSharePass.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import UserInfo from '../../common_lib_front/types/userInfo';
import style from './sharePassFormCollection.module.css';

type SharePassFormProps = {
  data: UserInfo;
  update: (obj: any) => void;
  remove: () => void;
  idx: number;
  allowRemove?: boolean;
};

export const SharePassForm = (props: SharePassFormProps): ReactElement => {
  const [disablePhone, setDisablePhone] = useState(true);
  const [disableEmail, setDisableEmail] = useState(false);

  const { data, update, remove, idx, allowRemove } = props;
  const { name: communityName } = useContext(CommunityContext);

  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (key === 'phone') {
      setDisablePhone(!e.target.checked);
      if (!e.target.checked) {
        update({ phone: '' });
      }
    } else if (key === 'email') {
      setDisableEmail(!e.target.checked);
      if (!e.target.checked) {
        update({ email: '' });
      }
    }
  };
  return (
    <div className={`${style.box} white `}>
      <div className={`${style.header} mainColor `}>
        <img src={userSharePass} alt="" />
        <h5 className={`${style.title} textColorWhite `}>Recipient</h5>
        <div className={style.exit}>
          <GenericButton
            hidden={!allowRemove}
            // icon="exit-small"
            icon={className => <CloseImg className={`${className}`} />}
            title=""
            clickHandler={() => {
              remove();
            }}
          />
        </div>
      </div>
      <div className={style.infoBox}>
        <h5 className={style.text}>
          To share a pass, please select your preferred method and enter your
          recipient&#39;s name, email address and/or phone number below.
        </h5>

        {communityName === 'Grand Complex' ? (
          <h5 className={style.subText}>
            This pass will allow authorized vendors one entry into the parking garage
          </h5>
        ) : null}

        <div className={style.inputArea}>
          <div className={style.inputBox}>
            {/* <div className={style.checkBoxInvisible} /> */}
            <div className={style.inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor={`first-name-inpt-${idx}`}
                labelTitle="First Name"
                inputType="text"
                inputValue={data.firstName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ firstName: e.target.value });
                }}
              />
            </div>
            <div className={style.inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor={`last-name-inpt-${idx}`}
                labelTitle="Last Name"
                inputType="text"
                inputValue={data.lastName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ lastName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputLong}>
            <InputField
              smallSize
              highlightOnFocus
              htmlFor={`company-name-inpt-${idx}`}
              labelTitle="Company Name"
              inputType="text"
              inputValue={data.companyName}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                update({ companyName: e.target.value });
              }}
            />
          </div>
          <div className={style.inputLongBox}>
            <form>
              <input
                className={style.checkBox}
                checked={!disableEmail}
                type="checkbox"
                id="email-check-enable"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  checkHandler(e, 'email')
                }
              />
            </form>
            <div className={style.inputLong}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor={`email-inpt-${idx}`}
                labelTitle="Email Address"
                inputType="email"
                disabled={disableEmail}
                inputValue={data.email}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ email: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputLongBox}>
            <form>
              <input
                className={style.checkBox}
                checked={!disablePhone}
                type="checkbox"
                id="phone-check-enable"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  checkHandler(e, 'phone')
                }
              />
            </form>
            <div className={style.inputLong}>
              <PhoneInputWrapper
                inputTitle="Phone Number"
                smallStandardInput
                value={data.phoneNumber}
                disabled={disablePhone}
                changeHandler={(e: string) => {
                  update({ phoneNumber: e });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SharePassForm.defaultProps = {
  allowRemove: true,
};

export default SharePassForm;
