import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './customCard.module.css';

interface Props {
  img: any;
  alt?: string;
  title: string;
  buttonLabel: string;
  href: string;
  hidden?: boolean;
}

export const CustomCard = (props: Props): ReactElement => {
  const { img, title, buttonLabel, href, hidden } = props;

  if (hidden) return <></>;
  return (
    <Link to={href}>
      <div className={style.cardBox}>
        <div className={`${style.card} white `}>
          <div className={style.image}>{img}</div>
          <h5 className={`${style.card__title} textColorDark `}>{title}</h5>
          <div className={style.card__btn}>
            <div className={style.card__btnInnerBox}>
              <GenericButton
                color="transparent_White"
                size="large"
                title={buttonLabel}
                type="button"
                outline="none"
                className={style.custom_card_btn}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
CustomCard.defaultProps = {
  alt: 'card img',
  hidden: false,
};
export default CustomCard;
