import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as BackgroundComponent } from '../../assets/option_bg.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import store, {
  isLogedIn as checkIsLoggedIn,
} from '../../common_lib_front/utilities/store';
import { logout } from '../../common_lib_front/utilities/thunks';
import style from './createAccount.module.css';

type options = 'host' | 'resident';
export default function Options(): ReactElement {
  const history = useHistory();
  const [option, setOption] = useState<options>('resident');
  const isLoggedIn = useRef<boolean>(checkIsLoggedIn());
  const { featuresConfig } = useContext(CommunityContext);
  const hostEnabled = featuresConfig?.host?.enabled || false;
  const residentEnabled = featuresConfig?.resident?.enabled || false;
  useEffect(
    // return cleanup function
    () =>
      store.subscribe(() => {
        isLoggedIn.current = checkIsLoggedIn();
      }),
    [],
  );

  const submitHandler = useCallback(
    async (forceOption?: string) => {
      const _option = forceOption || option;
      if (_option === 'host') {
        history.push('/resident/host-registration');
      } else if (_option === 'resident') {
        history.push('/resident/registration');
        return;
      }
    },
    [option, history],
  );

  useEffect(() => {
    if (hostEnabled && !residentEnabled) {
      submitHandler('host');
    } else if (residentEnabled && !hostEnabled) {
      submitHandler('resident');
    }
  }, [hostEnabled, residentEnabled, submitHandler]);

  return (
    <div>
      <div className={style.container_option}>
        <div className={`${style.box} white `}>
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <div className={style.shape_bg}>
              <BackgroundComponent />
            </div>
            <div
              className={style.back_arrow}
              title="back"
              onClick={() => store.dispatch(logout)}
            >
              <Link to={'/login'}>&larr;</Link>
            </div>
            {/* <div className={style.alert}>
              <div className={style.alertBox}>
                <GenericAlert color={alertColor} title={alert} hidden={!alert} />
              </div>
            </div> */}

            {residentEnabled && (
              <div className={style.inputLong}>
                <div className={style.box__inputLong}>
                  <div className={style.box__inputField}>
                    <InputField
                      smallSize
                      highlightOnFocus
                      htmlFor="option-1"
                      labelTitle="Register as an Owner/Resident"
                      inputType="radio"
                      inputValue={'resident'}
                      checked={option === 'resident'}
                      radio={true}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setOption(e.target.value as options);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {hostEnabled && (
              <div className={style.inputLong}>
                <div className={style.box__inputLong}>
                  <div className={style.box__inputField}>
                    <InputField
                      smallSize
                      highlightOnFocus
                      htmlFor="option-2"
                      labelTitle="Register as Host/Rental Manager"
                      inputType="radio"
                      inputValue={'host'}
                      checked={option === 'host'}
                      radio={true}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setOption(e.target.value as options);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className={`${style.box__noteTitle}`}>
              <b>
                <u>Note:</u>
              </b>{' '}
              &nbsp;
              <span>
                If you are an Owner, who self-manages your Rental Property, please
                <br />
                register as an Owner/Resident first.
              </span>
            </div>

            <div className={style.box__btnLeft}>
              <div className={style.box__btnLeftInnerBox}>
                <GenericButton
                  color="blue"
                  size="medium"
                  title="Continue"
                  type="submit"
                />
              </div>
            </div>
            <div className={style.shapeBg}></div>
          </form>
        </div>
      </div>
    </div>
  );
}
