/* eslint-disable prettier/prettier */
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useState, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';
import MemberInfo from '../../../common_lib_front/types/familyOrFriendInfo';
import style from './editFamilyMember.module.css';

export const EDIT_MEMBER = gql`
  mutation editFamilyMember($familyMemberId: String!, $data: FamilyMemberInput!) {
    editFamilyMember(familyMemberId: $familyMemberId, data: $data) {
      success
      error
    }
  }
`;
export const GET_MEMBER = gql`
  query Query($familyMemberId: String) {
    getFamilyMember(familyMemberId: $familyMemberId) {
      success
      error
      data {
        firstName
        familyMemberId
        lastName
        relationship
        phoneNumber
        email
        driversLicenseNum
        driversLicenseExp
        isLicenseSelected
        userId
        memberPictureUrl
        driversLicenseImageUrl
        driversLicenseState
      }
    }
  }
`;

export default function EditFamilyMember(): ReactElement {
  const [memberInfo, setMemberInfo] = useState<MemberInfo>();
  const { memberId } = useParams<{ memberId: string }>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const history = useHistory();
  const { communityId } = useContext(CommunityContext);

  const { loading } = useQuery(GET_MEMBER, {
    variables: {
      familyMemberId:memberId,
    },
    fetchPolicy: 'cache-and-network',
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (!d.getFamilyMember.success) {
        setAlert(d.getFamilyMember.error);
        setAlertColor('red');
      } else {
        console.log('data',d.getFamilyMember.data);
        setMemberInfo({
          ...d.getFamilyMember.data[0],
          file: '',
          fileName: '',
          driverLc: d.getFamilyMember?.data[0]?.driversLicenseNum||'',
          driverLcExp: d.getFamilyMember?.data[0]?.driversLicenseExp||''
        });
        // setOriginalmemberInfo(d.getEmployee.data[0]);
      }
    },
  });
  const myEmpInputRef = useRef<HTMLInputElement[]>([]);
  console.log(memberId);
  myEmpInputRef.current = [];
  const addToEmpRefs: (el: HTMLInputElement) => void = el => {
    if (el && !myEmpInputRef.current.includes(el)) {
      myEmpInputRef.current.push(el);
    }
  };
  const [doSubmit, { loading: submitting }] = useMutation(EDIT_MEMBER, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (!d.editFamilyMember.success) {
        setAlert(d.editFamilyMember.error);
        setAlertColor('red');
      } else {
        history.goBack();
      }
    },
  });

  const submitHandler = () => {
    if (['firstName', 'lastName', 'email', 'phoneNumber'].some(k => !memberInfo?.[k])) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
    } else {
      doSubmit({
        variables: {
          familyMemberId:memberId,
          data: {
            firstName: memberInfo?.firstName,
            lastName: memberInfo?.lastName,
            email: memberInfo?.email,
            phoneNumber: memberInfo?.phoneNumber,
            driversLicenseNum: memberInfo?.driverLc,
            driversLicenseExp: memberInfo?.driverLcExp,
            isLicenseSelected: memberInfo?.isLicenseSelected,
            file: memberInfo?.file,
            fileName: memberInfo?.fileName,
            driversLicenseState:memberInfo?.driversLicenseState,
          },
        },
      });
    }
  };
  console.log('memberInfo',memberInfo);
  const fileToBase64 = function (file: any): Promise<string> {
    return new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };
  const selectForUpload = async function (event: any) {
    if (!event?.target?.files[0]) return;
    const url = URL.createObjectURL(event.target.files[0]);
    const fileString = await fileToBase64(event.target.files[0]);
    console.log('url',url);
    if (memberInfo?.isLicenseSelected) {
      setMemberInfo({
        ...memberInfo,
        file: fileString,
        driversLicenseImageUrl: url,
        fileName: event.target.files[0]?.name,
      });
    } else {
      setMemberInfo({ ...memberInfo, file: fileString, memberPictureUrl: url, fileName: event.target.files[0]?.name });
    }
  };
  const getButtonText=()=>{
    return memberInfo?.isLicenseSelected && memberInfo?.driversLicenseImageUrl?.trim() ? 'Update ' : !memberInfo?.isLicenseSelected && memberInfo?.memberPictureUrl?.trim() ? 'Update  ' : 'Add ';

  };
  console.log(memberInfo?.driversLicenseImageUrl);
  return (
    <PopUp
      title="Edit Family Member or Friend"
      close={() => history.goBack()}
    >
      <div style={{ width: '85%' }}>

        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          submitHandler();
        }}
        >
          <div className={style.box}>
            <div style={{
              display: 'flex', width: '100%', marginTop: '30px', fontWeight: 'bold',
            }}
            >
              <p>An Family member/Friend  picture or Driver License is required</p>
            </div>
            {['pinnacleport', 'carillonbeach'].indexOf(communityId) < 0 ? <div className={style.completeBox}>
              <input type="checkbox" checked={!memberInfo?.isLicenseSelected} onChange={(e) => setMemberInfo({ ...memberInfo, isLicenseSelected: !memberInfo?.isLicenseSelected })} className={style.checkbox} />
              <p className={style.completeBoxLabel}>Upload Family member or Friend picture</p>
            </div> : null}

          </div>
          <div className={style.flexBox}>
            <div className={style.boxLeft}>
              <div className={style.row}>
                <div className={style.input}>
                  <input
                    accept="image/*"
                    hidden
                    ref={addToEmpRefs}
                    type="file"
                    name="file"
                    onChange={(e) => selectForUpload(e)}
                  />
                  <button
                    onClick={(e) => { e.preventDefault(); myEmpInputRef.current[0].click(); }}
                    className={style.uploadeBtn}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      getButtonText()
                    }
                    {memberInfo?.isLicenseSelected ? "Family Member/Friend's License" : 'Family Member/Friend Picture'}
                  </button>
                </div>
                <div className={style.rowName}>
                  <div className={style.inputShort}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="f-name-inpt"
                      labelTitle="First Name"
                      // disabled={memberInfo?.isLicenseSelected}
                      inputType="text"
                      inputValue={memberInfo?.firstName || ''}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberInfo({ ...memberInfo, firstName: e.target.value });
                      }}
                    />
                  </div>
                  <div className={style.inputShort}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="l-name-inpt"
                      labelTitle="Last Name"
                      // disabled={memberInfo?.isLicenseSelected}
                      inputType="text"
                      inputValue={memberInfo?.lastName || ''}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberInfo({ ...memberInfo, lastName: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.input}>
                <PhoneInputWrapper
                  standardInput
                  inputTitle="Phone Number"
                  value={memberInfo?.phoneNumber || ''}
                  changeHandler={(val: string) => {
                    setMemberInfo({ ...memberInfo, phoneNumber: val });
                  }}
                />
              </div>
              <div className={style.input}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="email-inpt"
                  labelTitle="Email"
                  inputType="email"
                  inputValue={memberInfo?.email || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMemberInfo({ ...memberInfo, email: e.target.value });
                  }}
                />
              </div>
              {memberInfo?.isLicenseSelected
              && (
                <div className={style.rowName}>

                  <div className={style.input}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="driverLc-inpt"
                      labelTitle="Driver's License Number"
                      // disabled={memberInfo?.isLicenseSelected}
                      inputType="text"
                      inputValue={memberInfo?.driverLc || ''}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberInfo({ ...memberInfo, driverLc: e.target.value });
                      }}
                    />
                  </div>
                  <div className={style.input}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="driversLicenseState-inpt"
                      labelTitle="Driver's License State"
                      // disabled={memberInfo?.isLicenseSelected}
                      inputType="text"
                      inputValue={memberInfo?.driversLicenseState || ''}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberInfo({ ...memberInfo, driversLicenseState: e.target.value });
                      }}
                    />
                  </div>
                </div>
              )}
              {memberInfo?.isLicenseSelected
              && (
                <div className={style.input}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="driverLcExp-inpt"
                    labelTitle="DL Expiration"
                    // disabled={memberInfo?.isLicenseSelected}
                    inputType="date"
                    inputValue={memberInfo?.driverLcExp || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setMemberInfo({ ...memberInfo, driverLcExp: e.target.value });
                    }}
                  />
                </div>
              )}
              <GenericAlert
                color={alertColor}
                title={alert}
                hidden={!alert}
              />

            </div>
            <div className={style.boxRight}>
              {
              // eslint-disable-next-line no-nested-ternary
                memberInfo?.isLicenseSelected
                  ? memberInfo?.driversLicenseImageUrl?.trim()
                    ? <img width="100%" height="auto" src={memberInfo?.driversLicenseImageUrl} alt="Not available license" className={style.img} />
                    : (
                      <div className={style.noImage}>
                        <p>Select picture</p>
                      </div>
                    )
                  : memberInfo?.memberPictureUrl?.trim()
                    ? <img width="100%" height="auto" src={memberInfo?.memberPictureUrl} alt="Not available empl" className={style.img} />
                    : (
                      <div className={style.noImage}>
                        <p>Select picture</p>
                      </div>
                    )
              }
            </div>
          </div>
          <div className={style.btnBox}>
            <div className={style.btn}>
              <GenericButton
                color="blue"
                size="large"
                title="Save Changes"
                type="submit"
                disabled={loading || submitting}
              />
            </div>
          </div>
        </form>
      </div>
    </PopUp>
  );
}
