import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import userSharePass from '../../assets/userSharePass.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import SearchSelector from '../../common_lib_front/components/searchSelector/searchSelector';
import FamilyOrFriendPassInfo from '../../common_lib_front/types/familyAndFriendsPassInfo';
import {
  formatDateForInput,
  formatDateForInputUTC,
} from '../../common_lib_front/utilities/formatDate';
import style from './familyAndFriendsPassCollection.module.css';

type FamilyAndFriendsPassFormProps = {
  familyAndFriendsPassData: FamilyOrFriendPassInfo;
  update: (obj: any) => void;
  remove: () => void;
  idx: number;
  allowRemove?: boolean;
  parentClass: string;
};

const GET_ALL_MEMBERS = gql`
  query GetFamilyMembers {
    getFamilyMembers {
      error
      success
      data {
        firstName
        familyMemberId
        lastName
        relationship
        phoneNumber
        email
        userId
      }
    }
  }
`;

const GET_USER_COUMMUNITY_INFO = gql`
  query GetPropertiesByUser {
    getPropertiesByUser {
      success
      error
      data {
        state
        street
        unit
        city
        zipCode
        propertyId
      }
    }
  }
`;

export const FamilyAndFriendsPassForm = (
  props: FamilyAndFriendsPassFormProps,
): ReactElement => {
  // const [disablePhone, setDisablePhone] = useState(true);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [nameList, setNameList] = useState<string[]>([]);
  const [addressList, setAddressList] = useState<string[]>([]);
  const [enableFamilyAndFriends, setEnableFamilyAndFriends] = useState<boolean>(true);
  // const [disableEmail, setDisableEmail] = useState(false);

  const { familyAndFriendsPassData, update, remove, idx, allowRemove, parentClass } =
    props;

  const { loading, data } = useQuery(GET_ALL_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    onError: e => {
      console.log('error', e);
    },
    onCompleted: d => {
      if (d.getFamilyMembers.success) {
        let filterNameList = d.getFamilyMembers.data || [];
        if (filterNameList && filterNameList.length > 0) {
          filterNameList = filterNameList.map((ele: any) => {
            return `${ele.firstName} ${ele.lastName}`;
          });
        }
        setNameList(filterNameList || []);
      }
    },
  });

  useQuery(GET_USER_COUMMUNITY_INFO, {
    fetchPolicy: 'cache-and-network',
    onError: e => {
      console.log('error', e);
    },
    onCompleted: d => {
      if (d.getPropertiesByUser.success) {
        let filterAddressList = d.getPropertiesByUser.data || [];
        if (filterAddressList && filterAddressList.length > 0) {
          filterAddressList = filterAddressList.map((ele: any) => {
            return `${ele.street}, ${ele.city}, ${ele.state}`;
          });
        }
        setAddressList(filterAddressList || []);
        if (filterAddressList.length == 1) {
          setAddress(filterAddressList[0]);
          update({
            address: filterAddressList[0],
          });
        }
      }
    },
  });

  const validateName = useCallback(
    async (name: string) => nameList.filter(nameEle => nameEle.includes(name)),
    [nameList],
  );

  const selectNameHandler = (val: string) => {
    if (val) {
      const findName: any = data?.getFamilyMembers?.data.filter((ele: any) =>
        ele.firstName.includes(val.split(' ')[0]),
      );
      update({
        firstName: findName[0].firstName,
        lastName: findName[0].lastName,
        email: findName[0].email,
        phoneNumber: findName[0].phoneNumber,
        isFriendSelected: true,
        passType: 'visitor',
      });
      setEnableFamilyAndFriends(false);
      update({
        isNew: false,
      });
    }
  };

  const selectAddressHandler = (val: string) => {
    if (val) {
      update({
        address: val,
      });
    }
  };

  const validateAddress = useCallback(
    async (name: string) => addressList.filter(nameEle => nameEle.includes(name)),
    [addressList],
  );

  useEffect(() => {
    const splitName = name.split(' ');
    update({
      firstName: splitName[0],
      lastName:
        splitName && splitName.length > 1 ? splitName.slice(1).join(' ') : splitName[0],
    });
  }, [name]);

  const formLayout = style.formLayout;
  const invalidEmailRegex = /@(guest\.booking|guest\.airbnb|relay\.vrbo)\.com/i;

  return (
    <div className={style.formBox}>
      <div className={style.recipientTitle}>
        {/* {location.pathname.includes('fast-pass') ? 'Pass' : 'Reservation'} {idx + 1} */}
        Recipient &nbsp;{idx + 1}
      </div>
      <div className={formLayout}>
        <div className={style.instruction}>
          To share a pass, please select your preferred method and enter your
          visitor&#39;s name, email address and/or phone number below.
        </div>
        <div className={style.guestInfo}>
          <div className={style.custom_select}>
            <SearchSelector
              htmlFor={`Name-${idx}`}
              title={'Name'}
              value={name}
              highLightOnFocus
              changeHandler={val => {
                setName(val);
                setEnableFamilyAndFriends(true);
              }}
              fetch={validateName}
              selectHandler={selectNameHandler}
              hideNoMatchAlert={true}
            />
          </div>
          <InputField
            smallSize
            highlightOnFocus
            htmlFor={`email-inpt-${idx}`}
            labelTitle="Email Address"
            inputType="email"
            inputValue={familyAndFriendsPassData.email}
            required={true}
            changeHandler={e => {
              if (invalidEmailRegex.test(e.target.value)) {
                e.target.setCustomValidity(
                  'Vacation Rental Platform email addresses are not accepted. Please provide a different email address or invite by phone number only.',
                );
                e.target.reportValidity();
              } else {
                e.target.setCustomValidity('');
              }
              update({ email: e.target.value });
            }}
          />
          <PhoneInputWrapper
            inputTitle="Phone Number"
            smallStandardInput
            value={familyAndFriendsPassData.phoneNumber}
            changeHandler={(e: string) => {
              update({ phoneNumber: e });
            }}
          />
        </div>
        <div className={style.dateSelection}>
          <div className={style.firstLineContent}>
            <div className={style.addressInput}>
              <div className={style.searchInput}>
                {addressList !== undefined && addressList.length > 1 ? (
                  <div className={style.custom_select}>
                    <SearchSelector
                      htmlFor={`Address-${idx}`}
                      title={'Address'}
                      value={address}
                      highLightOnFocus
                      changeHandler={val => {
                        setAddress(val);
                      }}
                      fetch={validateAddress}
                      noMatchAlert="Please select an approved address"
                      selectHandler={selectAddressHandler}
                      hideNoMatchAlert={true}
                    />
                  </div>
                ) : (
                  <InputField
                    smallSize
                    highlightOnFocus
                    htmlFor={`address-inpt-${idx}`}
                    labelTitle="Address"
                    inputType="text"
                    disabled={true}
                    inputValue={familyAndFriendsPassData.address}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      update({ address: e.target.value });
                    }}
                  />
                )}
              </div>
            </div>

            <div className={style.dateinputShortBox}>
              <div className={style.dateInput}>
                <InputField
                  htmlFor={`recipient-${idx}-arrival`}
                  labelTitle="Arrival Date"
                  inputType="date"
                  disabled={
                    !familyAndFriendsPassData.isFriendSelected &&
                    !familyAndFriendsPassData.isNew
                  }
                  inputValue={familyAndFriendsPassData.arrivalDate}
                  min={formatDateForInput(new Date())}
                  smallSize
                  highlightOnFocus
                  changeHandler={e => {
                    update({ arrivalDate: e.target.value });
                  }}
                  max={(() => {
                    if (familyAndFriendsPassData.departureDate) {
                      return familyAndFriendsPassData.departureDate;
                    }
                  })()}
                />
              </div>
              <div className={style.dateInput}>
                <InputField
                  htmlFor={`recipient-${idx}-departure`}
                  labelTitle="Departure Date"
                  inputType="date"
                  disabled={
                    !familyAndFriendsPassData.isFriendSelected &&
                    !familyAndFriendsPassData.isNew
                  }
                  inputValue={familyAndFriendsPassData.departureDate}
                  min={(() => {
                    if (familyAndFriendsPassData.arrivalDate) {
                      return familyAndFriendsPassData.arrivalDate;
                    }
                    return formatDateForInput(new Date());
                  })()}
                  smallSize
                  highlightOnFocus
                  changeHandler={e => {
                    update({ departureDate: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.inline_label}>
            <InputField
              htmlFor={`recipient-${idx}-familyAndFriends`}
              inputType="checkbox"
              checked={familyAndFriendsPassData.isNew}
              disabled={!enableFamilyAndFriends}
              autoComplete={false}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                update({
                  isNew: e.target.checked,
                  passType:
                    familyAndFriendsPassData.isFriendSelected || e.target.checked
                      ? 'visitor'
                      : 'single',
                });
              }}
            />
            <label className={style.checkBoxLabel}>Add to Family and Friends</label>
          </div>
        </div>
        <div className={style.delBox}>
          <button
            type="button"
            onClick={() => {
              remove();
            }}
            className={style.delBtn}
            hidden={!allowRemove}
          >
            x
          </button>
        </div>
      </div>
    </div>
  );
};

FamilyAndFriendsPassForm.defaultProps = {
  allowRemove: true,
};

export default FamilyAndFriendsPassForm;
