import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import style from './rentalRegistrationInitizlization.module.css';
import useRentalRegistrationInitialization from './useRentalRegistrationInitialization';

export default function RentalRegistrationInitialization(): ReactElement {
  const { registrationId, loading, error } = useRentalRegistrationInitialization();

  if (registrationId) {
    return (
      <Redirect to={`/resident/rental-registration/${registrationId}/rental-units`} />
    );
  }
  if (loading) {
    return <div className={style.wrapper}>Loading ...</div>;
  }
  if (error) {
    return <div className={style.wrapper}>{error}</div>;
  }
  return (
    <div className={style.wrapper}>
      We encountered an error. Please try again later and report this error if it
      persists.
    </div>
  );
}
