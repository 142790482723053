import { CloudUploadOutlined } from '@mui/icons-material';
import { Button, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';

export type IUWPprops = {
  value?: string;
  name?: string;
  onChange?: (val: { file: string; name: string }) => void;
  buttonText?: string;
  required?: boolean;
  hidden?: boolean;
};

const fileToBase64 = function (file?: Blob): Promise<string> {
  if (!file) throw Error('Could not read empty file');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('File read ended with unexpected type');
      }
    };
    reader.readAsDataURL(file);
  });
};

export function ImageUploadWithPreview({
  value,
  name,
  onChange,
  buttonText,
  hidden,
}: IUWPprops): React.ReactElement {
  const [innerVal, setInnerVal] = useState<string>(value || '');
  const [innerName, setInnerName] = useState<string>(name || '');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (value && value !== innerVal) {
      setInnerVal(value);
    }
  }, [value, innerVal]);

  useEffect(() => {
    if (name && name !== innerName) {
      setInnerName(name);
    }
  }, [name, innerName]);

  if (hidden) {
    return <></>;
  }
  return (
    <Grid item container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        {innerVal && !loading ? (
          <img
            src={innerVal}
            alt="image preview"
            style={{
              minHeight: '200px',
              minWidth: '200px',
              maxWidth: '100%',
              // maxHeight: '600px',
              margin: 'auto',
            }}
          />
        ) : (
          <Skeleton
            variant="rounded"
            animation={loading && 'wave'}
            height="200px"
            width="300px"
          />
        )}
      </Grid>
      <Grid item>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadOutlined />}
          style={{
            // color: 'var(--secondaryColor)',
            // borderColor: 'var(--secondaryColor)',
            backgroundColor: 'var(--thirdColor)',
          }}
        >
          {buttonText || 'Select Image'}
          <input
            hidden
            type="file"
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
            onChange={async e => {
              setLoading(true);
              try {
                const file = e.target.files?.[0];
                const b64 = await fileToBase64(file);
                setInnerVal(b64);
                setInnerName(file?.name || '');
                onChange?.({
                  file: b64,
                  name: file?.name || '',
                });
              } catch (err) {
                console.error(err);
              } finally {
                setLoading(false);
              }
            }}
          />
        </Button>
      </Grid>
    </Grid>
  );
}
