import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as RentalUnitPic } from '../../assets/step2.svg';
import { ReactComponent as CardPic } from '../../assets/step5.svg';
import style from './rentalRegistrationNavTile.module.css';

interface RentalRegistrationNavTileProps {
  href: string;
  title: string;
  text: string;
  icon: string;
  num: number;
  stepNum: number;
  currentStep: number;
  completeNum: number;
  completeAll: boolean | null;
}

export default function RentalRegistrationNavTile(
  props: RentalRegistrationNavTileProps,
): ReactElement {
  const { href, title, text, icon, num, stepNum, completeNum, completeAll } = props;
  const active = stepNum === num;
  const locked = useMemo<boolean>(() => {
    if (process.env.REACT_APP_DEBUG === 'true' || completeAll) {
      return false;
    }
    return completeNum < num;
  }, [completeAll, completeNum, num]);
  const complete = (() => {
    if (completeAll) {
      return true;
    }
    if (!completeNum && process.env.REACT_APP_DEBUG === 'true') {
      return stepNum > num;
    }
    return completeNum > num;
  })();
  let clickedStyle = 'textColorSpecial';
  let iconDefault = style.icon;
  let clickedLine = style.lineInvisible;

  if (active) {
    clickedStyle = 'textColorThird';
    iconDefault = style.iconActive;
    clickedLine = style.line;
  } else if (complete) {
    clickedStyle = 'textColorSucess';
    iconDefault = style.iconSucess;
  }
  let imgPicker;
  if (icon === 'RentalUnitPic ') {
    imgPicker = <RentalUnitPic />;
  }
  if (icon === 'CardPic ') {
    imgPicker = <CardPic />;
  }
  return (
    <Link
      to={href}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (locked) {
          e.preventDefault();
        }
      }}
    >
      <img src={icon} alt="" />
      <div className={iconDefault}>{imgPicker}</div>
      <h2 className={`${style.title}  ${clickedStyle} `}>{title}</h2>
      <p className={`${style.text}  ${clickedStyle} `}>{text}</p>
      <div className={clickedLine} />
    </Link>
  );
}
