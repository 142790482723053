import { gql, useMutation, useQuery } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { hostInfo, newHostInfo } from '../../../common_lib_front/types/hostInfo';

const GET_HOST_INFO = gql`
  query GetHostInfoData($registrationId: String!) {
    getHostInfoData(registrationId: $registrationId) {
      success
      error
      data {
        email
        firstName
        lastName
        companyName
        hostInfoId
        phoneNumber
        address
        city
        state
        zipCode
        ownerPin
      }
    }
  }
`;
type GET_HOST_INFO_VARS = {
  registrationId: string;
};
type GET_HOST_INFO_RES = {
  getHostInfoData: backendResponse<{
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    hostInfoId: string;
    phoneNumber: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    ownerPin: string;
  }>;
};

const EDIT_HOST_INFO = gql`
  mutation EditHostInfo(
    $ownerPin: String!
    $zipCode: String!
    $state: String!
    $city: String!
    $address: String!
    $phoneNumber: String!
    $lastName: String!
    $firstName: String!
    $companyName: String!
    $registrationId: String!
  ) {
    editOrInsertHostInfo(
      ownerPin: $ownerPin
      zipCode: $zipCode
      state: $state
      city: $city
      address: $address
      phoneNumber: $phoneNumber
      lastName: $lastName
      firstName: $firstName
      companyName: $companyName
      registrationId: $registrationId
    ) {
      success
      error
    }
  }
`;
type EDIT_HOST_INFO_VARS = {
  ownerPin: string;
  zipCode: string;
  state: string;
  city: string;
  address: string;
  phoneNumber: string;
  lastName: string;
  firstName: string;
  companyName: string;
  registrationId: string;
};

type EDIT_HOST_INFO_RES = {
  editOrInsertHostInfo: backendResponse<undefined>;
};

const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber($registrationId: String!) {
    editRegistrationStepNumber(stepNumber: 2, registrationId: $registrationId) {
      success
      error
    }
  }
`;
type EDIT_STEP_NUMBER_VARS = {
  registrationId: string;
};
type EDIT_STEP_NUMBER_RES = {
  editRegistrationStepNumber: backendResponse<undefined>;
};

type useHostInformationVal = {
  registrationId: string;
  hostData: hostInfo;
  hostLoading: boolean;
  hostError?: string;
  submitHandler: () => Promise<any>;
  editHelper: (obj: any) => void;
  error?: string;
};
const CREATE_HOST_APPLICATION = gql`
  mutation CreateHostApplication($registrationId: String!) {
    createApplication(type: "host", registrationId: $registrationId) {
      success
      error
    }
  }
`;
type CREATE_HOST_APPLICATION_VARS = {
  registrationId: string;
};
type CREATE_HOST_APPLICATION_RES = {
  createApplication: backendResponse<never>;
};
const GET_HOST_APPLICATION = gql`
  query GetApplications($registrationId: String!) {
    getApplicationByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        registrationId
      }
    }
  }
`;
type GET_HOST_APPLICATION_VARS = {
  registrationId: string;
};
type GET_HOST_APPLICATION_RES = {
  getApplicationByRegistration: backendResponse<{
    registrationId?: string;
  }>;
};
/**
 * Helper function.
 * Throw a error message or object if one is provided.
 * @param message Possible error message or error object
 */
function throwIf(message?: string | Error | Error[] | GraphQLErrors): void {
  if (message) {
    throw message;
  }
}
export default function useHostInformation(): useHostInformationVal {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [error, setError] = useState<string>('');
  const [editData, setEditData] = useState<hostInfo>(newHostInfo());
  const { data: applicationData, refetch: refetchApplication } = useQuery<
    GET_HOST_APPLICATION_RES,
    GET_HOST_APPLICATION_VARS
  >(GET_HOST_APPLICATION, {
    variables: {
      registrationId,
    },
  });
  const editHelper = (obj: any) => {
    setEditData({ ...editData, ...obj });
  };

  const {
    data: hostData,
    loading: hostLoading,
    error: hostError,
  } = useQuery<GET_HOST_INFO_RES, GET_HOST_INFO_VARS>(GET_HOST_INFO, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { registrationId },
    onCompleted: d => {
      if (d.getHostInfoData.success) {
        setEditData(newHostInfo(d.getHostInfoData.data));
      }
    },
  });

  const [doEditHostInfo] = useMutation<EDIT_HOST_INFO_RES, EDIT_HOST_INFO_VARS>(
    EDIT_HOST_INFO,
  );
  const [doAdvanceStepNum] = useMutation<EDIT_STEP_NUMBER_RES, EDIT_STEP_NUMBER_VARS>(
    EDIT_STEP_NUMBER,
  );

  const [doCreateApplication] = useMutation<
    CREATE_HOST_APPLICATION_RES,
    CREATE_HOST_APPLICATION_VARS
  >(CREATE_HOST_APPLICATION, {
    fetchPolicy: 'network-only',
    onError: e => {
      console.log(e.message);
      refetchApplication();
    },
    onCompleted: () => {
      refetchApplication();
    },
  });
  const submitHandler = async () => {
    try {
      const editHostRes = await doEditHostInfo({
        variables: {
          registrationId,
          ...newHostInfo({
            ...hostData?.getHostInfoData?.data,
            ...editData,
          }),
        },
      });
      throwIf(editHostRes.data?.editOrInsertHostInfo.error);
      // casting to any because of some strange build error that only seems to happen in bitbucket pipelines
      throwIf(editHostRes.errors as any);
      // create application if one does not already exist
      if (!applicationData?.getApplicationByRegistration.data?.registrationId) {
        const createApplicationRes = await doCreateApplication({
          variables: {
            registrationId: registrationId,
          },
        });
        throwIf(createApplicationRes.data?.createApplication.error);
        throwIf(createApplicationRes.errors as any);
      }
      const advanceStepRes = await doAdvanceStepNum({
        variables: {
          registrationId,
        },
      });
      throwIf(advanceStepRes.data?.editRegistrationStepNumber.error);
      throwIf(advanceStepRes.errors as any);
    } catch (err) {
      console.error(err);
      let processedErr = 'Something went wrong. Your changes may not be saved.';
      if (typeof err === 'string') {
        processedErr = err;
      }
      setError(processedErr);
      throw processedErr;
    }
  };

  return {
    registrationId,
    hostData: editData,
    hostLoading,
    hostError: hostError?.message || hostData?.getHostInfoData?.error || undefined,
    submitHandler,
    editHelper,
    error,
  };
}
