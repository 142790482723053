import { gql, useQuery } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ReactElement, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { formatDateWithMonthName } from '../../../common_lib_front/utilities/formatDate';
import EditProfileTemplate from '../../../components/editProfileTemplate/editProfileTemplate';

import style from './myDocument.module.css';
import PopupCellRenderer from './popUpCellRender';

const GET_USER_DOCUMENTS_QUERY = gql`
  query GetUserDocuments {
    getUserDocuments {
      success
      error
      data {
        userId
        documentId
        documentType
        userDocumentName
        uploadDate
        documentExpiration
      }
    }
  }
`;

export default function FamilyAndFriends(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const gridRef = useRef<AgGridReact>(null);

  const allNodes: any[] = [];
  gridRef.current?.api?.forEachNode((row: any) => {
    if (row.displayed) allNodes.push(row);
  });

  const { loading, data } = useQuery(GET_USER_DOCUMENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
  });
  console.log(data);
  const DocDate = ({ value }: { value: string }) => (
    <div>{new Date(value).toLocaleDateString()}</div>
  );

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
  };

  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      maxWidth: 100,
    },
    {
      headerName: 'Document Name',
      field: 'userDocumentName',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 180,
    },
    {
      headerName: 'Document Type',
      field: 'documentType',
      filter: 'agMultiColumnFilter',
      sortable: true,
      minWidth: 180,
    },
    {
      headerName: 'Upload Date',
      field: 'uploadDate',
      filter: 'agMultiColumnFilter',
      sortable: true,
      cellRendererFramework: DocDate,
      minWidth: 180,
    },
  ]);

  const rowData = data?.getUserDocuments?.data.flatMap((item: any) => ({
    userDocumentName: item.userDocumentName,
    documentType: item.documentType,
    documentUrl: item.documentUrl,
    documentId: item.documentId,
    uploadDate: item.uploadDate,
  }));

  const onBtnExport = useCallback(() => {
    const columnKeys = ['userDocumentName', 'documentType', 'uploadDate'];
    const headers: string[] = [];
    columnDefs.slice(1).forEach(col => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    gridRef.current?.api.forEachNode(row => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach(row => {
        const { data: item } = row;
        const itemData: any[] = [];
        columnKeys.forEach((key: string) => {
          if (key === 'uploadDate') {
            itemData.push(formatDateWithMonthName(new Date(item[key])));
          } else {
            itemData.push(`"${item[key]}"`);
          }
        });
        dataToExport.push(itemData.join(','));
      });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);
  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <EditProfileTemplate active="myDocuments">
      <div className={style.alert}>
        <div className={style.alertBox}>
          <GenericAlert color={alertColor} title={alert} hidden={!alert} />
        </div>
      </div>
      <div className={`${style.subHeader} mainColor`}>
        <div className={style.subHeaderLeft}>
          <div className={`${style.circle} white`} />
          <h1 className={`${style.title} textColorWhite`}>My Documents</h1>
        </div>

        <div className={style.subHeaderRight}>
          <Link
            to={{
              pathname: '/resident/edit-profile/add-my-document',
            }}
          >
            <GenericButton
              color="yellow"
              size="large"
              className={style.addBtn}
              title="Add Document +"
              type="button"
            />
          </Link>
        </div>
      </div>
      <div className={style.topLine}>
        <input
          type="search"
          className={style.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />
        <button className={style.btnExport} onClick={onBtnExport}>
          Export CSV
        </button>
      </div>
      <div className={style.paginationBox}>
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={style.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>
      <div className={style.tableBox}>
        <div
          className="ag-theme-alpine"
          style={{
            height: '60vh',
            // width: '600px',
          }}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            ref={gridRef}
            paginationPageSize={itemsPerPage}
          />
        </div>
      </div>
    </EditProfileTemplate>
  );
}
