import React, { ReactElement, useRef, useState } from 'react';
import GuestDataGrid from '../guestDataGrid/guestDataGrid';
import InviteGuestNav from '../inviteGuestNav/inviteGuestNav';
import ExportPopup from './exportPopup';
import style from './guestList.module.css';

export default function GuestList(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={style.ListBox} ref={wrapperRef}>
      {open && <ExportPopup setOpen={setOpen} />}
      <InviteGuestNav activeTab="list" />
      {/* <GuestListSearch /> */}
      {/*<GuestTable title="My Guests" />*/}
      <GuestDataGrid title="My Guests" fastPassesOnly={false} onBtnExport={() => {setOpen(true);}} />
      {/* <GuestTable title="Guests who have accessed the account" data={t2List} /> */}
    </div>
  );
}
