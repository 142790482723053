import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const REINVITE_GUEST = gql`
  mutation ReinviteGuest($registrationId: String!, $email: String, $phoneNumber: String) {
    reinviteGuest(
      registrationId: $registrationId
      email: $email
      phoneNumber: $phoneNumber
    ) {
      error
      success
    }
  }
`;
export type REINVITE_GUEST_VARS = {
  registrationId: string;
  email?: string;
  phoneNumber?: string;
};

type REINVITE_GUEST_RES = {
  reinviteGuest: backendResponse<undefined>;
};

export function reinviteGuest(variables: REINVITE_GUEST_VARS) {
  return backendClient.mutate<REINVITE_GUEST_RES, REINVITE_GUEST_VARS>({
    mutation: REINVITE_GUEST,
    variables,
  });
}

export function reinviteAllGuests(variables: REINVITE_GUEST_VARS[]) {
  return Promise.all(variables.map(vs => reinviteGuest(vs)));
}
