import html2canvas from 'html2canvas';
import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';
import { rentalUnitInfo } from '../../../common_lib_front/types/rentalUnitInfo';
import RentalPass from '../../../components/rentalPass/rentalPass';
import style from './receiptDownload.module.css';

export default function ReceiptDownload(): ReactElement {
  const history = useHistory();
  const location = useLocation<{ rentalData: Array<rentalUnitInfo> }>();
  const passes =
    location.state?.rentalData ||
    JSON.parse(sessionStorage.getItem('checkoutPassesList') || '{}');

  const doDownload = () => {
    const container = document.getElementById('receipt-download-container');
    if (container) {
      html2canvas(container, {
        allowTaint: true,
        useCORS: true,
      }).then(canvas => {
        const link = document.createElement('a');
        link.download = 'receipt.png';
        canvas.toBlob(blob => {
          if (blob) {
            link.href = URL.createObjectURL(blob);
            link.click();
          }
        });
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PopUp title="Download Registration" isOpen close={() => history.goBack()}>
      <div className={style.box}>
        {/* print container */}
        <div id="receipt-download-container">
          {/* receipt */}
          <div className={style.flexBox}>
            {/* passes */}
            <div className={style.passCollectionBox}>
              {passes.map((elem: rentalUnitInfo) => (
                <RentalPass key={elem.rentalUnitId} rental={elem} />
              ))}
            </div>
          </div>
        </div>
        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              size="large"
              color="blue"
              title="Download Registration"
              clickHandler={doDownload}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
}
