import React, { ReactElement, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../../../common_lib_front/components/popUp/popUp';
import useGetPassImage from '../../../../../common_lib_front/hooks/useGetPassImage';
import useGetMyGuests from '../../../../../hooks/useGetMyguests';
import style from './guestPass.module.css';

// type GuestPassProps = {
//   data: InvitedGuestInfoType[];
// };
export default function GuestPass(): ReactElement {
  const history = useHistory();
  const { registrationId, passId } = useParams<{
    registrationId: string;
    passId: string;
  }>();
  const { data } = useGetMyGuests({
    registrationIds: [registrationId],
    pageNumber: 0,
    pageSize: 0,
  });
  const passInfo = useMemo(() => {
    if (!data) return null;
    // eslint-disable-next-line no-restricted-syntax
    for (const guest of data) {
      // eslint-disable-next-line no-restricted-syntax
      for (const pass of guest.passes || []) {
        if (pass.passId === passId) {
          return pass;
        }
      }
    }
    return null;
  }, [data, passId]);
  const { data: imgData } = useGetPassImage(passId, !!passInfo?.url);
  const passUrl = passInfo?.url || imgData?.url || '';

  return (
    <PopUp close={() => history.goBack()} title="View/Print">
      <div className={style.wrapper}>
        <img
          className={style.image}
          src={passUrl === imgData?.url ? `${passUrl}?${Date.now()}` : passUrl}
          alt="Guest Pass"
        />
        <span className={style.spacer} />
        <div className={style.btnBox}>
          {/* <button>Download</button> */}
          <a href={passUrl} target="_blank" rel="noreferrer">
            <GenericButton title="Download" color="blue" size="medium" />
          </a>
        </div>
      </div>
    </PopUp>
  );
}
